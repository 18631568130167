import * as React from 'react'

import ReactTooltip from "react-tooltip";

export default function ProfileBadges({ badges }) {

  console.log(badges)
  // Hovered Badge Message
  const [hoveredBadge, setHoveredBadge] = React.useState(null)

  const messages = {
    ["new_candidate"]: (
      <div>
        <p>
          Candidates that have recently signed up to the platform and are looking for their first job.
        </p>
      </div>
    ),
    ["complete_profile"]: (
      <div>
        <p>
          Candidates that have completed their profile and are ready to apply to your job.
        </p>
      </div>
    ),
    ["active_user"]: (
      <div>
        <p>
          Candidates that have recently applied to a job and are actively looking for work.
        </p>
      </div>
    ),
    ["temp_veteran"]: (
      <div>
        <p>
          Candidates that have more temp experience on the platform.
        </p>
      </div>
    ),
    ["new_temp"]: (
      <div>
        <p>
          Candidates that have recently signed up to the platform and are looking for their first temp job.
        </p>
      </div>
    ),
    ["high_rating"]: (
      <div>
        <p>
          Candidates that have a high rating on the platform.
        </p>
      </div>
    ),
    ["popular_temp"]: (
      <div>
        <p>
          Candidates that have been hired for many temp jobs on the platform.
        </p>
      </div>
    ),
    ["top_rated_temp"]: (
      <div>
        <p>
          Candidates that have a high temp rating on the platform.
        </p>
      </div>
    ),
  }

  const icons = {
    ["new_candidate"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/New Canditate.png",
    ["complete_profile"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/Profile Completed.png",
    ["active_user"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/Active User.png",
    ["temp_veteran"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/Veteran Temp.png",
    ["new_temp"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/New Temp.png",
    ["high_rating"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/Top Rated Temp.png",
    ["popular_temp"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/Popular Temp.png",
    ["top_rated_temp"]: "https://directdentalstaffing.sfo3.cdn.digitaloceanspaces.com/profile-badges/Top Rated Temp.png",
  }

  return (
    <div className="profile-badges mb-2">
      <div className="profile-badges__badge">
        <ReactTooltip>
          <div>{messages[hoveredBadge]}</div>
        </ReactTooltip>

        {/* Filter out badge.earned != true */}
        {badges.filter((badge) => badge.earned).map((badge) => {
          const image = <img width={40} className="pe-2" src={icons[badge.badge]} alt={badge.badge} data-tip="New" onMouseEnter={() => setHoveredBadge(badge.badge)} />
          return image
        })}
      </div>
    </div>
  )
}