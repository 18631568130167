import React from "react"
import DraftTextArea from "./DraftTextArea"
import TextField from "./TextField"
import { checkPropTypes } from "prop-types"

const ProfileEducation = (props) => {
  const [state, setState] = React.useState({ education: props.education })
  const [, forceUpdate] = React.useState(0)
  React.useEffect(() => {
    console.log("Updated", state.education)
  }, [state])

  const addEducation = () => {
    setState({
      education: [
        ...state.education,
        { schoolname: "", qualification: "", desc: "", date: "" },
      ],
    })
  }

  const moveUpFun = (i) => {
    const education = move(state.education, i, i - 1)
    setState({ education })
  }

  const moveDownFun = (i) => {
    if (i === state.education.length - 1) {
      return false
    }
    const education = move(state.education, i, i + 1)
    setState({ education })
  }

  const deleteItem = (index) => {
    const temp_education = state.education
    const educationSpliced = temp_education.splice(index, 1)
    setState({ education: temp_education })
  }

  const updateItem = (key, value, index) => {
    const educations = state.education
    const item = educations[index]
    item[key] = value
    educations[index] = item
    setState({ education: educations })
  }

  return (
    <div className="form-inside">
      {state.education.map((exp, index) => (
        // { schoolname: '', qualification: '', desc: '', date: '' }
        <Education
          index={`${index}-${exp.schoolname}-${exp.qualification}-${exp.date}`}
          {...exp}
          deleteItemFun={() => deleteItem(index)}
          moveUpFun={() => moveUpFun(index)}
          moveDownFun={() => moveDownFun(index)}
          updateSchool={(e) => updateItem("schoolname", e, index)}
          updateQual={(e) => updateItem("qualification", e, index)}
          updateDate={(e) => updateItem("date", e, index)}
          updateDesc={(e) => updateItem("desc", e, index)}
        />
      ))}
      <a
        onClick={() => addEducation()}
        className="btn contact-done gray add-Education add-box"
      >
        <i className="fa fa-plus-circle"></i> Add Education
      </a>
    </div>
  )
}

const Education = ({
  schoolname,
  qualification,
  date,
  desc,
  index,
  deleteItemFun,
  moveUpFun,
  moveDownFun,
  updateDate,
  updateDesc,
  updateQual,
  updateSchool,
}) => {
  return (
    <div className="form boxed education-box experience-background px-3 py-3 my-3">
      <div className="offset-lg-10">
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => moveDownFun()}
          style={{ marginRight: 33 }}
        >
          <i className="fa fa-arrow-down"></i>
        </a>
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => moveUpFun()}
          style={{ marginRight: 66 }}
        >
          <i className="fa fa-arrow-up"></i>
        </a>
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => deleteItemFun()}
        >
          <i className="fa fa-close"></i>
        </a>
      </div>
      <label>School Name</label>
      <TextField
        name="profile[education[]school][schoolname]"
        id="profile_education_school_schoolname"
        value={schoolname}
        updateFunc={(e) => updateSchool(e)}
      />
      <label>Qualification</label>
      <TextField
        name="profile[education[]school][qualification]"
        id="profile_education_job_school_qualification"
        value={qualification}
        updateFunc={(e) => updateQual(e)}
      />
      <label>Date</label>
      <TextField
        name="profile[education[]school][date]"
        id="profile_education_school_date"
        value={date}
        updateFunc={(e) => updateDate(e)}
      />
      <label>Description</label>
      <DraftTextArea
        key={`${index}-desc`}
        containerclassName={""}
        name="profile[education[]school][desc]"
        id="profile_education_school_desc"
        rich={true}
        value={desc}
        updateFunc={(e) => updateDesc(e)}
      />
    </div>
  )
}

function move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length
    while (k-- + 1) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export default ProfileEducation
