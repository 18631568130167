import "../Common-poly"
import "whatwg-fetch"

import React from "react"
// import ProfileSkills from './ProfileSkills';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

export default function ProfileFilters(props) {
  const {
    form_elements,
    job_types,
    location,
    miles,
    onChange,
    onChangeSort,
    onToggleFavorites,
    onChangeLocation,
    onChangeCheckbox,
    skill_categories,
    skills,
  } = props

  return (
    <div className="card shadow-sm mb-4 browsing-card-design">
      <div className="card-header browsing-cards-head ">
        <i className="fas fa-search"></i> Search Filters
      </div>
      <div className="px-3">
        <div className="widget my-4">
          <ul className="checkboxes browsing-remove-bullet">
            <li>
              <input
                id="check-favorites"
                type="checkbox"
                name="favorites"
                value="1"
                onChange={onToggleFavorites}
                checked={form_elements.favorites}
              />
              <label className="px-2" for="check-favorites">
                Show Favorites Only
              </label>
            </li>
          </ul>
        </div>
        <div className="form-group widget my-4">
          <h4>Position</h4>
          <select
            className="form-select"
            name="job_types_group_eq"
            id="search_job_type_title_cont"
            onChange={onChange}
            value={form_elements.job_types_group_eq}
          >
            <option value="">Please select</option>
            {job_types.map(jt => (
              <option value={`${jt.group}-${jt.title}`}>{jt.title}</option>
            ))}
          </select>
        </div>
        <div className="form-group widget my-4">
          <h4>Location</h4>
          <input
            type="text"
            className="form-control"
            name="location"
            id="location"
            value={location}
            placeholder="Zip Code"
            onChange={e => onChangeLocation(e)}
          />
          <div className="form-group">
            <select
              name="miles"
              id="miles"
              style={{ width: "100%", marginTop: 10 }}
              className="miles form-control"
              onChange={e => onChangeLocation(e)}
            >
              <option value="">Miles From Location</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="35">35</option>
              <option value="40">40</option>
              <option value="45">45</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div className="form-group widget my-4">
          <h4>Pay Rate</h4>
          <div className="row">
            <div className="col-4">
              <input
                type="text"
                className="form-control"
                placeholder="$Min"
                name="hourly_rate_gteq"
                onChange={onChange}
                value={form_elements.hourly_rate_gteq}
              />
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center">
              <h4>to</h4>
            </div>
            <div className="col-4">
              <input
                type="text"
                className="form-control"
                placeholder="$Max"
                name="hourly_rate_lteq"
                onChange={onChange}
                value={form_elements.hourly_rate_lteq}
              />
            </div>
          </div>
        </div>
        <div className="widget my-4">
          <h4>Position Type</h4>
          <ul className="checkboxes browsing-remove-bullet">
            <li>
              <input
                id="check-fulltime"
                type="checkbox"
                name="position_types_id_in"
                value="1"
                onChange={onChangeCheckbox}
                checked={form_elements.position_types_id_in.includes("1")}
              />
              <label for="check-fulltime" className="px-2">
                Full Time
              </label>
            </li>
            <li>
              <input
                id="check-parttime"
                type="checkbox"
                name="position_types_id_in"
                value="2"
                onChange={onChangeCheckbox}
                checked={form_elements.position_types_id_in.includes("2")}
              />
              <label for="check-parttime" className="px-2">
                Part Time
              </label>
            </li>
            <li>
              <input
                id="check-temp"
                type="checkbox"
                name="position_types_id_in"
                value="4"
                onChange={onChangeCheckbox}
                checked={form_elements.position_types_id_in.includes("4")}
              />
              <label for="check-temp" className="px-2">
                Temporary
              </label>
            </li>
          </ul>
        </div>
        <div class="widget last">
          <h4>Skills</h4>
          {/* // Bootstrap list type none */}
          <ul className="checkboxes list-unstyled">
            <Accordion allowZeroExpanded allowMultipleExpanded>
              {skill_categories.map(category => {
                const category_skills = skills.filter(
                  skill => skill.category === category
                )
                return (
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>{category}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {category_skills.map(skill => (
                        <Tag
                          form_elements={form_elements}
                          onChangeCheckbox={onChangeCheckbox}
                          skill={skill}
                        />
                      ))}
                    </AccordionItemPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </ul>
        </div>
      </div>
    </div>
  )
}

function Tag({ form_elements, skill, onChangeCheckbox }) {
  return (
    <li className="tags tagsearch my-2">
      <input
        id={`check-skill-${skill.id}`}
        type="checkbox"
        name="skills_id_in"
        value={`${skill.id}`}
        onChange={onChangeCheckbox}
        checked={form_elements.skills_id_in.includes(`${skill.id}`)}
      />
      <label htmlFor={`check-skill-${skill.id}`}>
        <span
          className="text-light rounded px-2 py-1 me-1 mb-1 ms-2"
          style={{ backgroundColor: skill.color }}
        >
          {skill.name}
        </span>
      </label>
    </li>
  )
}
