import * as React from 'react';
import Product from './Product';

export default function Products({ products, className, productClassName, form_authenticity_token, user_signed_in }) {
  console.log(products);
  return (
    <div className={className}>
      {products.map(product =>
        <div className={productClassName}>
          <Product
            {...product}
            form_authenticity_token={form_authenticity_token}
            user_signed_in={user_signed_in}
          />
        </div>
      )}
    </div>
  );
}