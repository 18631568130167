import * as React from "react";

interface DistanceProps {
  distance: number;
}

export default function ProfileDistance({ distance }: DistanceProps) {
  const metersToMiles = (m: number) => (m * 0.00062137).toFixed(2);

  return (
    <span>
      <i className="fa fa-car"></i> {metersToMiles(distance)} mi{" "}
    </span>
  );
}
