import * as React from "react";
import AddFavoriteCandidate from "../../Favorites/AddFavoriteCandidate";
import AddBlockCandidate from "../../Favorites/AddBlockCandidate";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  [prop: string]: any;
}

interface NameProps {
  profile: Profile;
  authenticity_token: string;
  blockedCallback: any;
  favorites?: Array<number>;
  blocked?: Array<number>;
  job_id: any;
  user_id: any;
}

function ProfileName({
  profile,
  authenticity_token,
  blockedCallback,
  favorites = [],
  blocked = [],
  job_id = undefined,
  user_id = undefined
}: NameProps) {
  const profile_favorited = favorites.includes(profile.id);
  const profile_blocked = blocked.includes(profile.id);

  return (
    <h4 className="fullname">
      {profile.first_name} {profile.last_name}
      <AddFavoriteCandidate
        profile={profile}
        favorited_init={profile_favorited}
        authenticity_token={authenticity_token}
      />
      {(job_id || user_id) && <AddBlockCandidate
        profile={profile}
        job_id={job_id}
        user_id={user_id}
        favorited_init={profile_blocked}
        authenticity_token={authenticity_token}
        blockedCallback={blockedCallback}
      />}
    </h4>
  );
}

export default ProfileName;
