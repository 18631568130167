import * as React from 'react';
import * as moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

export default function CardForm({ plan, public_key, payment_methods, customer, form_authenticity_token, is_subscription, trial }) {
  const [state, setState] = React.useState({
    stripePromise: undefined,
    payment_intent: undefined,
    subscription: undefined,
    loading: false
  });

  React.useEffect(() => {
    setState({ ...state, stripePromise: loadStripe(public_key) })
  }, []);

  const setPaymentIntent = (payment_intent) => {
    // console.log("PI", payment_intent);
    setState({ ...state, payment_intent, loading: false })
  }
  const setSubscription = (s) => {
    // console.log("SUB", s);
    setState({ ...state, subscription: s, loading: false })
  }

  const setLoading = (loading) => {
    setState({ ...state, loading })
  }

  const show_confirm = !state.loading && (state.payment_intent && state.payment_intent.status == "requires_confirmation");

  const show_success = !state.loading && (state.subscription || (state.payment_intent && state.payment_intent.status == "succeeded"));

  const show_forms = !state.payment_intent && !state.subscription;

  // var Spinner = require('react-spinkit');

  React.useEffect(() => console.log(state), [state]);

  return (
    <div className="eight columns">
      {state.loading && (
        <div className="text-center mt-60">
          {/* <Spinner name="line-scale" color="#0c6aca" /> */}
          <span>Processing</span>
        </div>
      )}
      {state.stripePromise && (
        <>
          {show_confirm && (
            <ConfirmPaymentForm
              confirmPaymentIntentResult={setPaymentIntent}
              payment_intent={state.payment_intent}
              form_authenticity_token={form_authenticity_token}
            />
          )}
          {show_success && (
            <div className="pricing-behind" style={{ marginTop: 0 }}>
              <h2>{trial ? 'Trial Started' : 'Payment Successful'} <i className="fa fa-check" style={{ color: 'green' }}></i></h2>
              {state.subscription && state.subscription.trial_end && (
                <p>Your trial will end on {moment.unix(state.subscription.trial_end).calendar()}</p>
              )}
              Thank you! {!is_subscription && ('We will get in touch with you soon.')}
              <div className="text-center">
                <a className="button" href="/finish">Continue</a>
              </div>
            </div>
          )}
          <Elements stripe={state.stripePromise}>
            {show_forms && (
              <div style={state.loading ? { display: 'none' } : {}}>
                {payment_methods.length > 0 && (
                  <>
                    <ExistingCardForm
                      plan={plan}
                      payment_methods={payment_methods}
                      handleSetPaymentIntent={setPaymentIntent}
                      handleSetSubscription={setSubscription}
                      subscription={is_subscription}
                      customer={customer}
                      form_authenticity_token={form_authenticity_token}
                      setLoading={setLoading}
                    />
                    <br />
                  </>
                )}
                <NewCardForm
                  plan={plan}
                  handleSetSubscription={setSubscription}
                  handleSetPaymentIntent={setPaymentIntent}
                  subscription={is_subscription}
                  payment_intent_state={state.payment_intent}
                  subscription_state={state.subscription}
                  form_authenticity_token={form_authenticity_token}
                  setLoading={setLoading}
                />
              </div>
            )}
          </Elements>
        </>
      )}
      {is_subscription && trial && (
        <p>* Your card will not be charged during the 7 day trial</p>
      )}
    </div>
  );
}

const ConfirmPaymentForm = ({ payment_intent, form_authenticity_token, confirmPaymentIntentResult }) => {
  const handleConfirmPayment = async (event) => {
    event.preventDefault();
    const result = await confirmPaymentIntent(
      payment_intent.id,
      form_authenticity_token
    );
    confirmPaymentIntentResult(result);
  }

  return (
    <div className="pricing-behind" style={{ marginTop: 0 }}>
      <h2>Confirm Payment</h2>
      <form onSubmit={handleConfirmPayment}>
        <div className="text-center mt-20">
          <button style={{ borderRadius: 30 }} type="submit">Confirm Payment</button>
        </div>
      </form>
    </div>
  )
}

const ExistingCardForm = ({ plan, payment_methods, customer, form_authenticity_token, handleSetPaymentIntent, subscription, handleSetSubscription, setLoading }) => {
  const [state, setState] = React.useState({
    card: payment_methods[0].id,
    payment_intent: undefined,
    customer,
  });
  // const stripe = useStripe();
  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    setState({ ...state, card: event.target.value })
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!subscription) {
      const result = await getPaymentIntent(plan, state.card, form_authenticity_token);
      handleSetPaymentIntent(result)
    } else {
      const sub = await createSubscription(plan, state.card, form_authenticity_token);
      handleSetSubscription(sub);
    }
  };

  return (
    <div className="pricing-behind" style={{ marginTop: 0 }}>
      <h2 className="comoTitle" style={{ fontSize: 22 }}>Existing Credit Cards</h2>
      <form onSubmit={handleSubmit}>
        <select name="credit_card" id="" style={{ paddingLeft: 10 }} onChange={handleChange}>
          {payment_methods.map(pm =>
            <option
              style={{ paddingLeft: 20 }}
              value={pm.id}
              selected={state.card.id == pm.id}
            >
              {pm.card.brand.toUpperCase()} {pm.card.last4} - EXP {pm.card.exp_month}/{pm.card.exp_year}
            </option>
          )}
        </select>
        <div className="text-center mt-20">
          <button style={{ borderRadius: 30 }} type="submit"><i className="fal fa-lock"></i> Place Order</button>
        </div>
      </form>
    </div>
  )
}

const NewCardForm = ({ plan, handleSetPaymentIntent, form_authenticity_token, subscription, handleSetSubscription, setLoading, subscription_state, payment_intent_state }) => {
  const [error, setError] = React.useState(null);
  const [empty, setEmpty] = React.useState(true);
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    console.log(event)
    setEmpty(event.empty);
    if (event.error) {
      setError(event.error.message);
    } else if (event.value.postalCode == "") {
      setError("Please ensure you have entered a correct expiration date, CVC and postal code. ")
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card)
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      const card_id = result.token.card.id;
      const pm = await addPaymentMethod(result.token.id, form_authenticity_token);

      if (!subscription && pm) {
        const pi = await getPaymentIntent(plan, card_id, form_authenticity_token);
        handleSetPaymentIntent(pi);
      } else {
        const sub = await createSubscription(plan, card_id, form_authenticity_token);
        handleSetSubscription(sub);
      }


    }
  };

  return (
    <form onSubmit={handleSubmit} className="pricing-behind" style={{ marginTop: 0 }}>
      <div className="form-row">
        <h2 className="comoTitle" style={{ fontSize: 22 }}>Payment Method</h2>
        <CardElement
          id="card-element"
          className="mb-20"
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
        <div className="card-errors" role="alert" style={{ color: 'red' }}>{error}</div>
        {(subscription_state !== undefined) && !subscription_state && (
          <div className="card-errors" role="alert" style={{ color: 'red' }}>There was an error and your card was declined. Please call us at 1-800-799-2644 for assistance.</div>
        )}
        {(payment_intent_state !== undefined) && !payment_intent_state && (
          <div className="card-errors" role="alert" style={{ color: 'red' }}>There was an error and your card was declined. Please call us at 1-800-799-2644 for assistance.</div>
        )}
      </div>
      <div className="text-center">
        <button style={{ borderRadius: 30 }} type="submit" disabled={error || empty}><i className="fal fa-lock"></i> Place Order</button>
      </div>
    </form>
  );
}

async function confirmPaymentIntent(payment_intent, form_authenticity_token) {
  const response = await fetch('/confirm_payment_intent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': form_authenticity_token
    },
    body: JSON.stringify({ payment_intent })
  })
    .then(response => {
      return response.json();
    })
    .catch(() => {
      return false;
    });

  return response;
}

async function addPaymentMethod(token, form_authenticity_token) {
  const response = await fetch('/add_card', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': form_authenticity_token
    },
    body: JSON.stringify({ token })
  })
    .then(response => {
      return response.json();
    })
    .catch(() => {
      return false;
    });

  return response;
}

// Generate a payment intent from backend
// (Plan ID, Card, CSRF Token) => Response
async function getPaymentIntent(id, card, form_authenticity_token) {
  const response = await fetch('/payment_intent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': form_authenticity_token
    },
    body: JSON.stringify({ id, card })
  })
    .then(response => {
      return response.json();
    })
    .catch(() => {
      return false;
    });

  return response;
}

async function createSubscription(plan, card, form_authenticity_token) {
  const response = await fetch('/create_subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': form_authenticity_token
    },
    body: JSON.stringify({ plan, card })
  })
    .then(response => {
      return response.json();
    })
    .catch(response => {
      console.log("Error occured", response);
      return false;
    });

  return response;
}

async function stripeTokenHandler(token) {
  console.log(token, token.token)
  const response = await fetch('/charge', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: token.token.id })
  });

  return response.json();
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};