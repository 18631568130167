import * as React from "react";

interface LocationProps {
  city: string;
  state: string;
  zip: string;
}

const ProfileLocation = ({ city, state, zip }: LocationProps) => {
  return (
    <span>
      <i className="fa fa-map-marker"></i> {city}, {state} {zip}
    </span>
  );
};

export default ProfileLocation;
