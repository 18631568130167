import "./Common-poly"
import React from "react"
import PropTypes from "prop-types"

import ProfileList from "./Profile/ProfileList"
import Pagination from "./search/Pagination"
import ProfileFilters from "./profiles_search/ProfileFilters"

import Popup from "reactjs-popup"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

import { dotStream } from 'ldrs'

dotStream.register()

const URI = "/profilesearch.json"

export default class Profiles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profiles: [],
      form_elements: {
        ...props.form_elements,
        position_types_id_in: [],
        skills_id_in: [],
        favorites: false,
      },
      sort: "newest",
      location: "",
      page: 1,
      miles: 25,
      loaded: false,
      favorites: props.favorites,
      blocked: props.blocked,
    }
  }

  componentDidMount() {
    this.sendQuery()
  }

  onChangeCheckbox = event => {
    const { name, value, checked } = event.target
    const {
      form_elements: { position_types_id_in, skills_id_in },
    } = this.state
    let new_position_types_id_in = position_types_id_in
    let new_skills_id_in = skills_id_in

    console.log("test")
    if (checked) {
      if (
        name === "position_types_id_in" &&
        !new_position_types_id_in.includes(value)
      ) {
        new_position_types_id_in.push(value)
      }
      if (name === "skills_id_in" && !new_skills_id_in.includes(value)) {
        new_skills_id_in.push(value)
      }
    } else {
      if (name === "position_types_id_in") {
        new_position_types_id_in = position_types_id_in.filter(v => v !== value)
      }
      if (name === "skills_id_in") {
        new_skills_id_in = skills_id_in.filter(v => v !== value)
      }
    }

    this.setState(
      state => ({
        ...state,
        form_elements: {
          ...state.form_elements,
          position_types_id_in: new_position_types_id_in,
          skills_id_in: new_skills_id_in,
        },
      }),
      () => this.sendQuery()
    )
  }

  onChangeLocation = event => {
    const { name, value } = event.target

    if (name == "miles") {
      this.setState(
        state => ({ ...state, [name]: value }),
        () => this.sendQuery()
      )
    }

    if (name == "location") {
      const re = /^[0-9\b]+$/
      const isZipCode = value.length <= 5 && (value === "" || re.test(value))
      if (isZipCode)
        this.setState(
          state => ({ ...state, [name]: value }),
          () => {
            if (value.length == 5) this.sendQuery()
          }
        )
    }
  }

  onChangeSort = event => {
    const sort = event.target.value
    this.setState(
      state => ({ sort }),
      () => this.sendQuery()
    )
  }

  setForm = event => {
    const { form_elements } = this.state
    const { name, value } = event.target
    this.setState(
      state => ({
        form_elements: { ...form_elements, [name]: value },
        page: 1,
      }),
      () => this.sendQuery()
    )
  }

  navigateTo = page => {
    this.setState(
      state => ({ ...state, page }),
      () => {
        this.sendQuery()
        window.scrollTo(0, 100)
      }
    )
  }

  onToggleFavorites = e => {
    const favorites = !this.state.form_elements.favorites
    this.setState(
      state => ({
        ...state,
        page: 1,
        current_page: 1,
        form_elements: {
          ...state.form_elements,
          favorites,
        },
      }),
      () => this.sendQuery()
    )
  }

  sendQuery = () => {
    const { page, form_elements, sort, location, miles } = this.state
    const body = JSON.stringify({
      page,
      sort,
      location,
      miles,
      search: { ...form_elements },
    })
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": this.props.authenticity_token,
    }

    fetch(URI, { method: "POST", headers, body })
      .then(res => res.json())
      .then(res => {
        const { profiles, total_pages, records, favorites } = res
        const current_page = res["current_page="]
        const prev_page = current_page === 1 ? current_page : current_page - 1
        const next_page =
          current_page === total_pages ? current_page : current_page + 1

        this.setState(state => ({
          ...state,
          profiles,
          current_page,
          total_pages,
          records,
          prev_page,
          next_page,
          loaded: true,
          favorites,
        }))
      })
  }

  render() {
    const {
      profiles,
      current_page,
      total_pages,
      records,
      prev_page,
      next_page,
      location,
      form_elements,
      miles,
      loaded,
      favorites,
      blocked,
    } = this.state

    const { job_types, skill_categories, skills, authenticity_token } =
      this.props

    const key = profiles.map(profile => profile.id).join(",")

    return (
      <div className="container browsing-font mt-5 px-4">
        {this.state.form_elements.favorites && favorites.length < 1 && (
          <h4 className="text-center mb-5" style={{ fontSize: 26 }}>
            Sorry you have no favorites saved. Please select the{" "}
            <i className="fa fa-heart" style={{ color: "grey" }}></i> on any
            candidate’s profile to save as a favorite.
          </h4>
        )}
        {isMobile && (
          <div className=" offset-3 my-4">
            <button
              type="button"
              class="btn browsing-btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Search Filters
            </button>

            <div
              class="modal fade px-0"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <ProfileFilters
                      form_elements={form_elements}
                      job_types={job_types}
                      location={location}
                      miles={miles}
                      skills={skills}
                      skill_categories={skill_categories}
                      onToggleFavorites={e => this.onToggleFavorites(e)}
                      onChangeSort={e => this.onChangeSort(e)}
                      onChangeLocation={e => this.onChangeLocation(e)}
                      onChangeCheckbox={e => this.onChangeCheckbox(e)}
                      onChange={e => this.setForm(e)}
                    />
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn browsing-btn"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div
            className="col-12 col-lg-8 card shadow-sm mb-4 px-0 browsing-card-design"
            id="job-list"
            key={key}
          >
            <div className="card-header browsing-cards-head">
              <i className="fas fa-newspaper"></i> Candidates
            </div>
            {profiles.length > 0 && (
              <ProfileList
                authenticity_token={authenticity_token}
                profiles={profiles}
                favorites={favorites}
              />
            )}
            {profiles.length < 1 && loaded && <NoResults />}
            {profiles.length < 1 && !loaded && <LoadingResults />}
          </div>

          <div className="col-12 col-lg-4 " id="job-filters">
            {!isMobile && (
              <ProfileFilters
                form_elements={form_elements}
                job_types={job_types}
                location={location}
                miles={miles}
                skills={skills}
                skill_categories={skill_categories}
                onToggleFavorites={e => this.onToggleFavorites(e)}
                onChangeSort={e => this.onChangeSort(e)}
                onChangeLocation={e => this.onChangeLocation(e)}
                onChangeCheckbox={e => this.onChangeCheckbox(e)}
                onChange={e => this.setForm(e)}
              />
            )}
          </div>
        </div>
        {profiles.length > 0 && (
          <Pagination
            current_page={current_page}
            total_pages={total_pages}
            records={records}
            prev_page={prev_page}
            next_page={next_page}
            navigateTo={this.navigateTo}
          />
        )}
      </div>
    )
  }
}

function LoadingResults() {
  return (
    <div className="padding-right p-2">
      <ul className="job-list full list-unstyled">
        <div className="field">
          <li className="text-center">
            <img
              src="https://directdental-production.s3.us-west-2.amazonaws.com/missing/missing.png"
              alt=""
              height={100}
            />
            <div className="job-list-content text-center">
              <h4 className="fullname">Loading...</h4>
              <l-dot-stream
                size="60"
                speed="2.5"
                color="black"
              ></l-dot-stream>
              <p>Please wait a moment!</p>
            </div>
          </li>
          <div className="clearfix"></div>
        </div>
      </ul>
    </div>
  )
}

function NoResults() {
  return (
    <div className="padding-right p-2">
      <ul className="job-list full list-unstyled">
        <div className="field">
          <li className="text-center">
            <img
              src="https://directdental-production.s3.us-west-2.amazonaws.com/missing/missing.png"
              alt=""
              height={100}
            />
            <div className="job-list-content">
              <h4 className="fullname">No Results Found</h4>
              <p>
                Sorry your search did not find any exact matches. We recommend
                you{" "}
                <a
                  className="ddblue"
                  href="/jobs/new"
                  style={{ display: "inline-block" }}
                >
                  Post a Job
                </a>{" "}
                to attract more candidates for your position.
              </p>
            </div>
          </li>
          <div className="clearfix"></div>
        </div>
      </ul>
    </div>
  )
}
