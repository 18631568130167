import * as React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// Profile Form Components
import GeneralInfo from "./ProfileFormComponents/GeneralInfo";
import Experience from "./ProfileFormComponents/Experience";
import Availability from "./ProfileFormComponents/Availability";
import Documents from "./ProfileFormComponents/Documents";

// Bootstrap Progress
import ProgressBar from "react-bootstrap/ProgressBar";

// Profile Form Reducer
import {
  ACTIONS,
  ProfileFormReducer,
} from "./ProfileFormComponents/ProfileFormReducer";

interface ProfileData {
  first_name: string;
  last_name: string;
  phone: string;
  phone_ext?: string;
  educations_attributes: any[];
  experiences_attributes: any[];
  years_experience: number;
  background: string;
  street_address: string;
  street_address_2?: string;
  city: string;
  state: string;
  zip: string;
  position_type_ids: number[];
  skill_ids: number[];
  job_type_ids: number[];
  hourly_rate: number;
  maximum_traveling_distance: number;
}

interface Profile extends ProfileData {
  id: number;
  avatar_url?: string;
  resume?: string;
  skills?: any[];
  job_types?: any[];
  position_types?: any[];
  educations: any[];
  experiences: any[];
}

interface DispatchAction {
  type: string;
  payload?: any;
}

// Submit Profile Data to API /profiles/:id.json
const submitProfile = (
  profile: Profile,
  dispatch: React.Dispatch<DispatchAction>,
  authenticity_token: string,
) => {
  const profileData: ProfileData = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    phone: profile.phone,
    phone_ext: profile.phone_ext,
    educations_attributes: profile.educations.map((edu) => ({
      ...edu,
      _destroy: edu._destroy || false,
    })),
    experiences_attributes: profile.experiences.map((exp) => ({
      ...exp,
      _destroy: exp._destroy || false,
    })),
    years_experience: profile.years_experience,
    background: profile.background,
    street_address: profile.street_address,
    street_address_2: profile.street_address_2,
    city: profile.city,
    state: profile.state,
    zip: profile.zip,
    position_type_ids: profile.position_type_ids,
    skill_ids: profile.skill_ids,
    job_type_ids: profile.job_type_ids,
    hourly_rate: profile.hourly_rate,
    maximum_traveling_distance: profile.maximum_traveling_distance,
  };

  const url = `/profiles/${profile.id}.json`;
  const method = "put";
  const data = { profile: profileData };

  dispatch({ type: ACTIONS.FORM_SUBMIT_START });

  fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        dispatch({ type: ACTIONS.FORM_SUBMIT_SUCCESS });
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then((response) => {
      const api_url = response.api_url.replace(".json", "");
      window.location.href = api_url;
    })
    .catch(() => {
      dispatch({ type: ACTIONS.FORM_SUBMIT_FAILURE });
    });
};

interface InitialState {
  options: {
    job_types: any[];
    hiring_types: any[];
  };
  authenticity_token: string;
  skills: any[];
  skill_categories: any[];
  processing: boolean;
  error: boolean;
  experience_selected: boolean;
  experience: boolean;
  tabIndex: number;
  profile: Profile;
  validation_errors: {
    first_name: boolean;
    last_name: boolean;
    phone: boolean;
    zip: boolean;
    positions: boolean;
    availability: boolean;
    experience_or_resume: boolean;
  };
}

function initialState(
  profile: Profile,
  job_types: any[],
  hiring_types: any[],
  skills: any[],
  skill_categories: any[],
  authenticity_token: string,
): InitialState {
  const educations = profile.educations || [];
  const experiences = profile.experiences || [];

  return {
    options: { job_types, hiring_types },
    authenticity_token,
    skills,
    skill_categories,
    processing: false,
    error: false,
    experience_selected: false,
    experience: false,
    tabIndex: 0,
    profile: {
      first_name: "",
      last_name: "",
      phone: "",
      zip: "",
      position_type_ids: profile.position_types?.map((p: any) => p.id) || [],
      skill_ids: profile.skills?.map((s: any) => s.id) || [],
      job_type_ids: profile.job_types?.map((j: any) => j.id) || [],
      ...profile,
      experiences,
      educations,
    },
    validation_errors: {
      first_name: false,
      last_name: false,
      phone: false,
      zip: false,
      positions: false,
      availability: false,
      experience_or_resume: false,
    },
  };
}

export const checkValidations = (state: InitialState) => ({
  first_name: !state.profile.first_name,
  last_name: !state.profile.last_name,
  phone: !state.profile.phone,
  zip: !state.profile.zip,
  positions: state.profile.position_type_ids.length === 0,
  availability: state.profile.job_type_ids.length === 0,
  experience_or_resume:
    state.profile.experiences.length === 0 && !state.profile.resume,
});

export const ProfileFormContext = React.createContext<
  [InitialState, React.Dispatch<DispatchAction>]
>([{} as InitialState, () => {}]);

interface ProfileFormProps {
  skills: any[];
  skill_categories: any[];
  profile: Profile;
  job_types: any[];
  hiring_types: any[];
  authenticity_token: string;
}

export default function ProfileForm({
  skills,
  skill_categories,
  profile,
  job_types,
  hiring_types,
  authenticity_token,
}: ProfileFormProps) {
  const [state, dispatch] = React.useReducer(
    ProfileFormReducer,
    initialState(
      profile,
      job_types,
      hiring_types,
      skills,
      skill_categories,
      authenticity_token,
    ),
  );

  React.useEffect(() => {
    console.log(state);
  }, [state]);

  const nextTab = () => {
    const element = document.getElementById("profileeditformcontainer");
    element?.scrollIntoView({ behavior: "smooth", block: "start" });

    setTimeout(() => {
      dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: state.tabIndex + 1 });
    }, 200);
  };

  const tabStyles = "react-tabs__tab text-center flex-grow-1";

  const submitForm = () => {
    submitProfile(state.profile, dispatch, authenticity_token);
  };

  React.useEffect(() => {
    document
      .getElementById("profileeditformcontainer")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [state.tabIndex]);

  return (
    <ProfileFormContext.Provider value={[state, dispatch]}>
      <div id="profileeditformcontainer">
        <ProfileCompleteness profile={state.profile} />
      </div>

      {state.processing && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
          Processing...
        </div>
      )}

      {state.error && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">
              There was an error submitting your profile.
            </span>
          </div>
        </div>
      )}

      {!state.processing && !state.error && (
        <Tabs
          selectedIndex={state.tabIndex}
          onSelect={(index: number) =>
            dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: index })
          }
        >
          <TabList className="d-flex justify-content-between gap-0">
            <Tab className={tabStyles}>
              <i className="fas fa-user mr-2"></i>{" "}
              {state.tabIndex === 0 && "General Info"}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-file mr-2"></i>{" "}
              {state.tabIndex === 1 && "Documents"}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-briefcase mr-2"></i>{" "}
              {state.tabIndex === 2 && "Experience"}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-calendar mr-2"></i>{" "}
              {state.tabIndex === 3 && "Availability"}
            </Tab>
          </TabList>

          <TabPanel>
            <GeneralInfo />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={nextTab}
                disabled={state.processing}
              >
                Continue
              </button>
            </div>
          </TabPanel>

          <TabPanel>
            <Documents />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={nextTab}
                disabled={state.processing}
              >
                Continue
              </button>
            </div>
          </TabPanel>

          <TabPanel>
            <Experience />
            <div className="d-flex justify-content-center mt-5">
              <button
                className="btn btn-primary"
                onClick={nextTab}
                disabled={state.processing}
              >
                Continue
              </button>
            </div>
          </TabPanel>

          <TabPanel>
            <Availability />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={submitForm}
                disabled={state.processing}
              >
                Submit
              </button>
            </div>
          </TabPanel>
        </Tabs>
      )}
    </ProfileFormContext.Provider>
  );
}

interface ProfileCompletenessProps {
  profile: Profile;
}

function ProfileCompleteness({ profile }: ProfileCompletenessProps) {
  const profileFields = check_fields(profile);
  const percentage =
    100 -
    profileFields.reduce((acc, field) => {
      if (
        Array.isArray(field.field) ? field.field.length === 0 : !field.field
      ) {
        return acc + field.value;
      }
      return acc;
    }, 0);

  return (
    <div className="col-lg-12 my-5 contact-font bg-secondary rounded p-4">
      <div className="info-banner">
        <div className="info-content">
          <h2>Profile Completeness - {percentage}%</h2>
          <ProgressBar now={percentage} />
        </div>
        <p>A more complete profile will help you get more job offers.</p>
      </div>
    </div>
  );
}

interface ProfileField {
  field: any;
  value: number;
  name: string;
  section: string;
}

function check_fields(profile: Profile): ProfileField[] {
  return [
    { field: profile.first_name, value: 5, name: "First Name", section: "1" },
    { field: profile.last_name, value: 5, name: "Last Name", section: "1" },
    { field: profile.phone, value: 5, name: "Phone", section: "1" },
    {
      field: profile.street_address,
      value: 5,
      name: "Street Address",
      section: "1",
    },
    { field: profile.city, value: 5, name: "City", section: "1" },
    { field: profile.state, value: 5, name: "State", section: "1" },
    { field: profile.zip, value: 5, name: "Zip", section: "1" },
    {
      field: profile.avatar_url,
      value: 5,
      name: "Profile Image",
      section: "1",
    },
    { field: profile.background, value: 5, name: "About Me", section: "2" },
    {
      field: profile.job_types,
      value: 10,
      name: "Position Availability",
      section: "2",
    },
    {
      field: profile.years_experience,
      value: 5,
      name: "Years Experience",
      section: "2",
    },
    {
      field: profile.resume || profile.experiences || profile.educations || [],
      value: 15,
      name: "Experience and Education",
      section: "2",
    },
    {
      field: profile.skills,
      value: 5,
      name: "Specialty Experience",
      section: "3",
    },
    { field: profile.hourly_rate, value: 5, name: "Hourly Rate", section: "4" },
    {
      field: profile.position_types,
      value: 5,
      name: "Hiring Availability",
      section: "4",
    },
  ];
}
