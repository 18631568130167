import * as React from "react"
import JobForm from "./JobFormContext"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import RichTextEditor from "react-rte"

import "draft-js/dist/Draft.css"
import RichTextareaEditor from "../../FormComponents/RichTextareaEditor"

//This selects the template to show
const initialEditorState = initialDescription => {
  if (initialDescription) {
    const contentBlock = htmlToDraft(initialDescription)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      return EditorState.createWithContent(contentState)
    }
  }
  return EditorState.createEmpty()
}

export default function Description({
  job_types,
  tabIndexFunc,
  validation,
  initialDescription = "",
  form_submit,
}) {
  const [jobState, setJobState] = React.useContext(JobForm)
  const [editorState, setEditorState] = React.useState(
    initialEditorState(jobState.description)
  )
  //This state will show or hide the template area
  const [previewTemplate, setPreviewTemplate] = React.useState(false)
  const [editorMarkup, setEditorMarkup] = React.useState()

  const onChangeEditor = editorState => {
    setEditorState(editorState)
  }

  //This function sets the text inside of the rich text editor
  const setDescriptionTemplate = html => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    const editorState = EditorState.createWithContent(contentState)

    setEditorState(editorState)
    setPreviewTemplate(!previewTemplate)
  }
  //this state makes sure error messages dont show unless someone is trying to procceed without filing out sections
  const [errorMessage, setErrorMessage] = React.useState(false)

  const headlineHandler = e => {
    setJobState({ ...jobState, job_title: e.target.value })
  }

  const submitJobForm = form_submit => {
    if (jobState.description.length > 33 && !validation) {
      form_submit()
    } else {
      console.log("Not all fields are filled out")
    }
  }

  const template = job_types.find(
    item => item.id === Number(jobState.job_type_id)
  ).description_template

  const templateEditor = RichTextEditor.createValueFromString(
    template,
    "markdown"
  )

  //This use effect copies over the rich text editor into the job state.
  React.useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)
    setEditorMarkup(markup)
    console.log(editorState, markup)
    setJobState({
      ...jobState,
      description: markup,
    })
  }, [editorState])

  return (
    <div>
      <div className="mb-4">
        <h4 htmlFor="compensation_rate" className="font-weight-bold">
          Job Headline (Optional)
        </h4>
        {jobState.description.length < 34 && errorMessage && (
          <p className="text-danger ps-0 ms-0">
            Please fill out a job description.
          </p>
        )}
        <input
          className="form-control"
          type="text "
          onChange={e => headlineHandler(e)}
          value={jobState.job_title}
        ></input>
      </div>
      <div>
        {!previewTemplate && (
          <button
            className="btn favorite-btn"
            onClick={e => setPreviewTemplate(!previewTemplate)}
          >
            Use a Template
          </button>
        )}
        {previewTemplate && !jobState.job_type_id && (
          <p className="text-danger ps-0 ms-0">
            Missing job type from general info.
          </p>
        )}
        {jobState.job_type_id && previewTemplate && (
          <div className="my-3 bg-secondary shadow p-5 rounded-3">
            <p
              dangerouslySetInnerHTML={{
                __html: templateEditor.toString("html"),
              }}
            ></p>
            <div className="text-center">
              <button
                className="btn favorite-btn mx-1"
                onClick={e => setPreviewTemplate(!previewTemplate)}
              >
                Close
              </button>
              <button
                className="btn favorite-btn mx-1"
                onClick={e =>
                  setDescriptionTemplate(templateEditor.toString("html"))
                }
              >
                Use this Template
              </button>

            </div>
          </div>
        )}
      </div>
      <div className="my-2">
        <h4 htmlFor="compensation_rate" className="font-weight-bold">
          Job Description
        </h4>
        <div className="">
          <RichTextareaEditor
            editorState={editorState}
            onChangeDescription={onChangeEditor}
          />
        </div>
        {jobState.description !== "<p></p>\n" &&
          jobState.description.length < 34 ? (
          <p className="text-danger ps-0 ms-0">
            Description must be longer than 25 characters.
          </p>
        ) : (
          ""
        )}
      </div>
      <button
        type="button"
        className="btn btn-primary pull-left"
        onClick={() => {
          tabIndexFunc(2)
        }}
      >
        Back
      </button>
      <button
        type="submit"
        className="btn btn-primary pull-right"
        onClick={() => {
          submitJobForm(form_submit), setErrorMessage(true)
        }}
      >
        Submit
      </button>
    </div>
  )
}
