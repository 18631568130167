import * as React from "react";

interface CompensationProps {
  compensation: string | number;
}

export default function ProfileCompensation({
  compensation,
}: CompensationProps) {
  return (
    <>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-md-none col-12">
        <i className="fa fa-money"></i> {compensation} / hour
      </span>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
        <i className="fa fa-money"></i> {compensation} / hour
      </span>
    </>
  );
}
