import * as React from 'react';

const ProfileHiringTypes = ({ hiring_types, update, selected }) => {
    const [state, setState] = React.useState({ hiring_type_ids: selected });

    const updateTypes = event => {
        const value = parseInt(event.target.value);
        const { hiring_type_ids } = state;

        if (hiring_type_ids.includes(value)) {
            setState({ hiring_type_ids: hiring_type_ids.filter(jt => jt !== value) })
        } else {
            setState({ hiring_type_ids: [...hiring_type_ids, value] })
        }
    }

    React.useEffect(() => {
        update('position_type_ids', state.hiring_type_ids)
    }, [state]);

    return (
        <div className="form">
            <h3>Select Your Availability</h3>
            <p>
                <ul className="taglist" style={{ listStyle: 'none' }}>
                    {hiring_types.map(ht => (
                        <li className="tags-job mb-1">
                            <label className="checkbox-container job" for={`profile_position_type_ids_${ht.id}`}>
                                <input className="d-none form-check-input upgrade-checkbox" type="checkbox" checked={state.hiring_type_ids.includes(ht.id)} value={ht.id}
                                    name="position_type_ids" id={`profile_position_type_ids_${ht.id}`} onChange={updateTypes} />
                                <span className="checkmark btn btn-primary" style={{ backgroundColor: '#0c6aca' }}>
                                    {state.hiring_type_ids.includes(ht.id) ? <i className="fas fa-check me-1"></i> : ''}
                                    {ht.name}
                                </span>
                            </label>
                        </li>
                    ))}
                </ul>
            </p>
        </div>
    );
}

export default ProfileHiringTypes;