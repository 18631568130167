import * as React from "react";

interface DistanceProps {
  distance: number;
}

export default function ProfileDistance({ distance }: DistanceProps) {
  const metersToMiles = (m: number) => (m * 0.00062137).toFixed(2);

  return (
    <>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-md-none col-12">
        <i className="fa fa-car"></i> {metersToMiles(distance)} mi{" "}
      </span>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
        <i className="fa fa-car"></i> {metersToMiles(distance)} mi{" "}
      </span>
    </>
  );
}
