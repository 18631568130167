import * as React from "react"

interface CardBoxProps {
  children: React.ReactNode
  title: string
  grow?: boolean
  iconClass?: string
  alignLastChildBottom?: boolean
}

export default function CardBox({
  children, title, grow = false, iconClass = "fas fa-search", alignLastChildBottom = false
}: CardBoxProps) {
  // Align last item to the bottom
  let cardClasses = "card shadow-sm mb-4 browsing-card-design my-5 p-0"

  if (alignLastChildBottom) cardClasses += " d-flex flex-column justify-content-between"

  // If grow is true, add the grow class "flex-fill"
  if (grow) cardClasses += " flex-fill"

  return (
    <div className={cardClasses}>
      <div className="card-header browsing-cards-head">
        <i className={iconClass} aria-hidden="true"></i> {title}
      </div>
      <div className="p-2">
        {children}
      </div>
    </div>
  )
}