import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function LocationsToggle({
  showLocations,
  handleCloseLocation,
  locations,
  buttonText = "Update Subscription",
  buttonFunction,
  limit,
  setLocationsStateFunction
}) {
  const [locationsState, setLocationsState] = React.useState(locations.map(location => ({
    ...location, active: location.active || false
  })));

  const setLocationStateActive = (locationId, active) => {
    const newLocations = locationsState.map(location => {
      if (location.id === locationId) {
        location.active = active;
      }
      return location;
    });
    setLocationsState(newLocations);
    if (setLocationsStateFunction) {
      setLocationsStateFunction(newLocations);
    }
  }

  const locationsActive = locationsState.map(location => ({
    id: location.id,
    active: location.active
  }));

  const submitButton = () => {
    if (buttonFunction) {
      buttonFunction();
    } else {
      handleCloseLocation();
    }
  }

  const limitHit = limit == locationsActive.filter(location => location.active).length;

  React.useEffect(() => console.log(locationsActive), [locationsActive]);

  return (
    <>
      {/* Update Active Locations */}
      <Modal show={showLocations} onHide={handleCloseLocation} className="ddModal mt-5">
        <Modal.Header closeButton>
          <Modal.Title>Select Up to {limit} Active Locations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {locationsState.map(location => {
            const disabled = limitHit && !location.active;
            return (
              <div className="form-check form-switch locationswitch ps-5 mb-2">
                <input
                  className="form-check-input big-checkbox"
                  type="checkbox"
                  value=""
                  id={`location-${location.id}`}
                  onChange={(e) => setLocationStateActive(location.id, e.target.checked)}
                  checked={location.active}
                  disabled={disabled}
                />
                <label className="form-check-label py-2 px-2" htmlFor={`location-${location.id}`}>
                  {location.name}
                </label>
              </div>
            )
          })}
          <p className="pt-2">Deactivating a location does not downgrade your membership. <a style={{ color: 'white' }} href="/account">Click here to manage your membership.</a></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLocation}>
            Cancel
          </Button>
          <Button variant="light" onClick={submitButton}>
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}