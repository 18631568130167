import * as React from 'react'

// Import the editor styles
import '@pqina/pintura/pintura.css';

// Import the editor default configuration
import { createDefaultImageWriter, getEditorDefaults } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';

export default function PinturaPopup({ avatar, updateProfilePhoto, setImageState }) {
  const editorRef = React.useRef(null);

  const editorConfig = getEditorDefaults({
    imageWriter: createDefaultImageWriter({
      mimeType: 'image/jpeg',
      quality: 90,
    }),
    utils: [
      'crop',
      'filter',
      'finetune',
    ]
  });

  const handleEditorProcess = (imageWriterResult) => {
    console.log("Writer Result", imageWriterResult)

    // Get file from dest and convert blob to base64 image/jpeg
    const file = imageWriterResult.dest
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64data = reader.result;
      setImageState(base64data)
      updateProfilePhoto(base64data)
    }
  };

  const handleButtonClick = () => {
    editorRef.current.editor.processImage().then((imageWriterResult) => {
      // Logs resulting image
      console.log(imageWriterResult);
    });
  };

  return (
    <div className="App" style={{ height: '600px' }}>
      <PinturaEditor
        ref={editorRef}
        {...editorConfig}
        src={avatar}
        imageCropAspectRatio={1}
        onProcess={handleEditorProcess}
      ></PinturaEditor>

      {/* Save Photo Button */}
      <div className="text-center mb-5 pb-5">
        <a className="btn btn-primary btn-block" onClick={handleButtonClick}><i className="far fa-crop"></i> Finish Editing</a>
      </div>
    </div>
  );
}