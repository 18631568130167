import * as React from "react"
import StepWizard from "react-step-wizard"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-datepicker/dist/react-datepicker.css"
import * as moment from "moment"

import JobForm from "./FormComponents/JobFormContext"
import GeneralInfo from "./FormComponents/GeneralInfo"
import Skills from "./FormComponents/Skills"
import Compensation from "./FormComponents/Compensation"
import Description from "./FormComponents/Description"

// Job Types are "Dental Assistant"....
// Position Types are "Full Time"....

const JobFormInitialState = (job, job_skills) => {
  // Get temp_date from job and convert to date object
  if (job.temp_date) {
    job.temp_date = moment(job.temp_date).toDate()
  }

  if (job.compensation_type == undefined || job.compensation_type == null || job.compensation_type == "") {
    job.compensation_type = "hourly"
  }

  return {
    ...job,
    skill_ids: job_skills.map(skill => skill.id),
  }
}

export default function Form({
  job,
  practices,
  skills,
  job_skills,
  job_types,
  position_types,
  form_elements,
  skill_categories,
  authenticity_token,
  cancelButton
  // compact,
  // hide,
  // temp_only,
  // form_tags,
}) {
  const title = job.id ? "Edit" : "New"
  const [jobState, setJobState] = React.useState(
    JobFormInitialState(job, job_skills)
  )
  const [practicesState, setPracticesState] = React.useState(practices)

  const [tabIndex, setTabIndex] = React.useState(0)

  const [validation, setValidation] = React.useState(true)

  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const styling = {
    flex: "1",
    textAlign: "center",
    margin: "5px",
  }

  // Set practice_state to the practice state of the job, default to "" if not set
  const selected_practice = practicesState.find(
    practice => practice.id == jobState.practice_id
  )

  const selected_practice_state = selected_practice && selected_practice.state ? selected_practice.state : ""

  const enforce_pay_range = selected_practice_state == "NY" ? true : false

  // React UseEffect for tabIndex, scroll to top of screen if changes

  React.useEffect(() => {
    // window.scrollTo(0, 200)
    // Scroll to #jobformcontainer
    const element = document.getElementById("jobformcontainer")
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }

  }, [tabIndex])

  React.useEffect(() => {
    const {
      position_type,
      job_type_id,
      practice_id,
      sms_phone,
      temp_date,
      temp_start,
      temp_end,
    } = jobState
    if (
      position_type !== null &&
      position_type !== "" &&
      job_type_id !== null &&
      job_type_id !== "" &&
      practice_id !== null &&
      practice_id !== ""
    ) {
      if (jobState.position_type !== "temp") {
        setValidation(false)
      } else {
        //these are the checks for the temp position
        if (
          sms_phone !== null &&
          sms_phone !== "" &&
          temp_date !== null &&
          temp_date !== "" &&
          temp_start !== null &&
          temp_start !== "" &&
          temp_end !== null &&
          temp_end !== ""
        ) {
          setValidation(false)
        } else {
          setValidation(true)
        }
      }
    } else {
      setValidation(true)
    }
  }, [jobState])

  React.useEffect(() => {
    console.log("JS", jobState)
  }, [jobState])

  const apiRequestData = {
    // request_profile: this.props.profile_id !== undefined,
    // profile_id: this.props.profile_id,
    job: jobState,
  }

  function submitToApi() {
    console.log(apiRequestData)
    console.log(apiRequestData.job)
    console.log(apiRequestData.job.id)
    const url = apiRequestData.job.id
      ? `/jobs/${apiRequestData.job.id}.json`
      : "/jobs"
    const method = apiRequestData.job.id ? "PUT" : "POST"

    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    }

    setLoading(true)
    try {
      fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(apiRequestData),
      }).then(response => {
        // Check if node development mode
        // if (process.env.NODE_ENV === "development") {
        if (response.ok) {
          // console.log("success", response)
        } else {
          setLoading(false)
          setError(true)
          // console.log("error", response)
        }
        // }

        // If response redirected or "PUT" request, redirect to the new location
        if (response.redirected || method === "PUT") {
          // Remove ".json" from the end of the URL
          const newUrl = response.url.replace(/\.json$/, "")
          window.location.href = newUrl
        }
      })
    } catch (err) {
      setLoading(false)
      setError(true)
      console.log("Error", err)
    }
  }

  // Hide form if error || loading == true
  // IF loading show a loading spinner
  // IF error show an error message

  return (
    <div className="d-flex justify-content-center shadow-lg rounded-3 px-3 px-md-5 pt-2 pt-md-4 pb-3 pb-md-5">
      {loading && (
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="">Processing</p>
        </div>
      )}
      {error && <>Error</>}
      {!loading && !error && (
        <Tabs
          className="col-12 sign-in-font mt-3"
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <h2 className="mb-4">{title} Job</h2>
          <TabList className="text-left text-md-center d-md-flex justify-content-between manageTabList border-0">
            {/* ClassName = d-none if tab is selected */}
            <Tab style={styling}
              className={tabIndex !== 0 ? "react-tabs__tab d-none d-md-inline" : ""}
            >General Info</Tab>
            <Tab style={styling} disabled={validation ? true : false}
              className={tabIndex !== 1 ? "react-tabs__tab d-none d-md-inline" : ""}
            >
              Skills
            </Tab>
            <Tab style={styling} disabled={validation ? true : false}
              className={tabIndex !== 2 ? "react-tabs__tab d-none d-md-inline" : ""}
            >
              Compensation
            </Tab>
            <Tab style={styling} disabled={validation ? true : false}
              className={tabIndex !== 3 ? "react-tabs__tab d-none d-md-inline" : ""}
            >
              Description
            </Tab>
          </TabList>
          {/* Context Provider */}
          <JobForm.Provider value={[jobState, setJobState]}>
            <TabPanel>
              {/* Step 1 */}
              <GeneralInfo
                practices={practicesState}
                job_types={job_types}
                position_types={position_types}
                tabIndexFunc={setTabIndex}
                validation={validation}
                setValidation={setValidation}
                cancelButton={cancelButton}
              />
            </TabPanel>
            {/* Step 2 */}
            <TabPanel>
              <Skills
                skills={skills}
                skill_categories={skill_categories}
                tabIndexFunc={setTabIndex}
              />
            </TabPanel>
            {/* Step 3 */}
            <TabPanel>
              <Compensation
                tabIndexFunc={setTabIndex}
                setValidation={setValidation}
                enforcePayRange={enforce_pay_range}
              />
            </TabPanel>
            <TabPanel>
              {/* Step 4 */}
              <Description
                tabIndexFunc={setTabIndex}
                validation={validation}
                job_types={job_types}
                form_submit={submitToApi}
              />
            </TabPanel>
          </JobForm.Provider>
        </Tabs>
      )}
    </div>
  )
}
