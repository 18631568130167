import * as React from 'react'
import { ProfileFormContext } from '../../ProfileEditForm';
import { ProfileFormReducer } from '../ProfileFormReducer'
import { ACTIONS } from '../ProfileFormReducer'
import Select from 'react-select';

export default function JobTypes() {
  const [state, dispatch] = React.useContext(ProfileFormContext);
  const { job_types } = state.options
  const { profile: { job_type_ids } } = state

  const [jobTypeState, setJobTypeState] = React.useState(job_type_ids)

  // jobTypeOptions
  const jobTypeOptions = job_types.map((j) => ({
    value: j.id,
    label: j.title
  }))

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      console.log(styles, data, isDisabled, isFocused, isSelected);
      return {
        ...styles,
      }
    },
    multiValueLabel: (styles, { data }) => {
      console.log(styles, data);
      return {
        // ...styles,
        backgroundColor: '#176fc9',
        color: 'white',
        borderRadius: '5px 0 0 5px',
        paddingLeft: 8,
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 3,
      }
    },
    multiValueRemove: (styles, { data }) => {
      // Unset styles.borderRadius
      return {
        ...styles,
        backgroundColor: '#176fc9',
        color: 'white',
        borderRadius: '0 5px 5px 0',
        paddingRight: 5,
        paddingLeft: 4,
        marginLeft: -1,
      }
    },
  }

  React.useEffect(() => dispatch({
    type: ACTIONS.SET_JOB_TYPES, payload: jobTypeState
  }), [jobTypeState])

  return (
    <>
      <Select
        styles={customStyles}
        options={jobTypeOptions}
        isMulti
        value={jobTypeOptions.filter((j) => jobTypeState.includes(j.value))}
        onChange={selected => {
          setJobTypeState(selected.map((j) => j.value))
        }}
      />
    </>
  )
}