import React from 'react';

const InviteProfile = ({ profile, job, requested = [], accepted = [], declined = [], callback, authenticity_token }) => {
    const [state, setState] = React.useState({
        loading: false
    })

    const finishLoading = () => setState({ ...state, loading: false })

    const is_temp = job.position_type == 'temp';

    const requestedButtonText = is_temp
        ? 'Requested'
        : 'Invited';

    const buttonText = is_temp
        ? 'Request Temp'
        : 'Invite to Apply';

    const acceptedButtonText = is_temp
        ? 'Accepted'
        : 'Applied';

    const buttonColor = is_temp
        ? "#1c5ef7" // ? "#f1630d"
        : "#1c5ef7";

    const buttonFunctionArguments = {
        profile, job, callback, authenticity_token, finishLoading
    };

    const buttonFunction = is_temp
        ? () => requestTemp(buttonFunctionArguments)
        : () => inviteToApply(buttonFunctionArguments);

    const buttonOnClick = event => {
        setState({ ...state, loading: true })
        event.stopPropagation();
        buttonFunction();
    }

    const profileRequested = requested.includes(profile.id);

    const profileAccepted = accepted.includes(profile.id);

    const profileDeclined = declined.includes(profile.id);

    // var Spinner = require('react-spinkit');

    return (
        <React.Fragment>
            {state.loading && (
                // <Spinner style={{ float: 'right', paddingRight: 15, paddingTop: 10 }} name="three-bounce" color="#186fc9" animationSpeed={5} />
                <>Loading...</>
            )}
            {!state.loading && profileRequested && !(profileAccepted || profileDeclined) && (
                <a className="button"
                    style={{ ...style.button, background: '#fff', color: 'black', border: 'none' }}>
                    <i className="fad fa-check"></i> {requestedButtonText}
                </a>
            )}
            {!state.loading && profileAccepted && (
                <a className="button"
                    style={{ ...style.button, background: 'white', color: 'green', border: 'none' }}>
                    <i className="fad fa-file-user" style={{ color: 'green' }}></i>  <strong>{acceptedButtonText}</strong>
                </a>
            )}
            {!state.loading && profileDeclined && (
                <a className="button"
                    style={{ ...style.button, background: 'white', color: '#a00606', border: 'none' }}>
                    <i className="fad fa-times" style={{ color: '#a00606' }}></i> Declined
                </a>
            )}
            {!state.loading && !job.closed && !job.temp_filled && is_temp && !profileRequested && (
                <a className={`button white ddoutline`}
                    style={{ ...style.button }}
                    onClick={event => buttonOnClick(event)}>
                    <i className="fad fa-user-plus" style={{ color: buttonColor }}></i> {buttonText}
                </a>
            )}
            {!state.loading && !job.closed && !job.temp_filled && !is_temp && !profileRequested && !profileAccepted && (
                <a className={`button white ddoutline`}
                    style={{ ...style.button }}
                    onClick={event => buttonOnClick(event)}>
                    <span><i className="fad fa-user-plus" style={{ color: buttonColor }}></i> {buttonText}</span>
                </a>
            )}
        </React.Fragment>
    );
}

const requestTemp = ({ profile, job, callback, authenticity_token, finishLoading }) => {
    const url = `/jobs/${job.id}/request.json`;

    const options = {
        method: 'POST',
        redirect: 'manual',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticity_token
        },
        body: JSON.stringify({ temp_requests: [`${profile.id}`] })
    }

    fetch(url, options)
        .then(result => result.json())
        .then(result => {
            console.log(result);
            callback(result[0].id)
            finishLoading()
        },
            error => console.log(error)
        )
}

const inviteToApply = ({ profile, job, callback, authenticity_token, finishLoading }) => {
    const url = `/jobs/${job.id}/invite.json`;

    const options = {
        method: 'POST',
        redirect: 'manual',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticity_token
        },
        body: JSON.stringify({ invitees: [`${profile.id}`] })
    }

    fetch(url, options)
        .then(result => result.json())
        .then(result => {
            callback(result[0].profile_id)
            finishLoading()
        },
            error => console.log(error)
        )
}

const style = {
    button: {
        float: 'right',
        width: 150,
        color: 'white',
        textAlign: 'center',
        margin: 12
    }
}



export default InviteProfile;
