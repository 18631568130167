import React from "react"
import PropTypes from "prop-types"
import FullCalendar from 'fullcalendar-reactwrapper';
import JobForm from './JobForm.js'
import Form from './Jobs/Form'
import 'react-app-polyfill/ie9';
import moment from 'moment';

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            showForm: false,
            events: [],
            clickedDay: null
        });
    }

    componentDidMount() {
        this.loadOpenEvents();
        this.loadClosedEvents();
        this.loadBookedEvents();
    }

    handleDayClick = (date, jsEvent, view) => {
        this.setState({ showForm: true, clickedDay: date.format() })
    }

    cancelForm = () => {
        this.setState({ showForm: false });
    }

    loadOpenEvents = () => {
        let events = this.state.events;

        fetch("/temps/open.json")
            .then(res => res.json())
            .then(
                (result) => {
                    result.map((r) => {
                        let rec = r['temp']
                        events.push({
                            id: rec['id'],
                            title: 'Open',
                            start: rec['temp_start'],
                            end: rec['temp_end'],
                            url: rec['job_url'],
                            description: 'Open',
                            color: rec['color']
                        });
                    });
                    this.setState({ events: events });
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    loadClosedEvents = () => {
        let events = this.state.events;

        fetch("/temps/open.json?closed=1")
            .then(res => res.json())
            .then(
                (result) => {
                    result.map((r) => {
                        let rec = r['temp']
                        events.push({
                            id: rec['id'],
                            title: 'Closed',
                            start: rec['temp_start'],
                            end: rec['temp_end'],

                            url: rec['job_url'],
                            description: 'Open',
                            color: rec['color']
                        });
                    });
                    this.setState({ events: events });
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    loadBookedEvents = () => {
        let events = this.state.events;

        fetch("/temps/filled.json")
            .then(res => res.json())
            .then(
                (result) => {
                    result.map((r) => {
                        let rec = r['temp']
                        events.push({
                            id: rec['id'],
                            title: 'Filled',
                            start: rec['temp_start'],

                            url: rec['job_url'],
                            end: rec['temp_end'],
                            description: rec["practice"],
                            color: rec['color']
                        });
                    });
                    this.setState({ events: events });
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    eventRender = (event, element) => {
        if (event.title == 'Closed') {
            // element.css('background-color', 'grey');
            element.css('opacity', '1.0');
        } else if (event.title == 'Filled') {
            element.css('opacity', '1.0');
            // element.css('background-color', 'green');
        } else {
            element.css('opacity', '0.7');
        }
        element.css('background-color', event.color);
    }

    clearAndReload = () => {
        this.setState({ events: [] });
        this.loadOpenEvents();
        this.loadClosedEvents();
        this.loadBookedEvents();
    }

    cancelForm = () => {
        this.setState({ showForm: false })
    }

    render() {
        let layout;

        if (this.state.showForm == true) {
            layout = (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 my-5 p-0" id="jobformcontainer">
                                {/* <JobForm
                        clickedDay={this.state.clickedDay}
                        compact={this.props.compact}
                        hide={this.props.hide}
                        temp_only={this.props.temp_only}
                        form_tags={this.props.form_tags}
                        authenticity_token={this.props.authenticity_token}
                        skills={this.props.skills}
                        job={this.props.job}
                        cancelButton={() => this.cancelForm()}
                    /> */}
                                <Form
                                    authenticity_token={this.props.authenticity_token}
                                    compact={true}
                                    hide={false}
                                    temp_only={true}
                                    form_tags={true}
                                    practices={this.props.practices}
                                    skills={this.props.all_skills}
                                    skill_categories={this.props.skill_categories}
                                    job_types={this.props.job_types}
                                    form_elements={this.props.form_elements}
                                    position_types={this.props.position_types}
                                    job_skills={this.props.job_skills}
                                    job={{
                                        ...this.props.job,
                                        position_type: 'temp',
                                        temp_date: moment(this.state.clickedDay).format('YYYY-MM-DD')
                                    }}
                                    cancelButton={() => this.cancelForm()}
                                />

                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            layout = (
                <div className="container">
                    <FullCalendar dayClick={this.handleDayClick}
                        events={this.state.events}
                        eventRender={this.eventRender} />
                </div>
            );
        }

        return (
            <React.Fragment>
                {layout}
            </React.Fragment>
        );
    }
}

export default Schedule
