import * as  React from "react";
class HiringType extends React.Component {
    tempOnly = () => {
        return (
            <div className="fullwidth">
                <input type="hidden" name="job[position_type]" value={this.props.position_type} />
            </div>
        );
    }

    normalForm = () => {
        return (
            <div className="fullwidth">
                <h3>Hiring Type</h3>
                <select name="job[position_type]"
                    class="ddselect"
                    value={this.props.position_type}
                    onChange={(e) => this.props.handleChange(e, 'position_type')}>
                    <option value="fulltime">Full Time</option>
                    <option value="parttime">Part Time</option>
                    <option value="longtermtemp">Long Term Temp</option>
                    {this.props.essential === true && <option value="temp">Temporary</option>}
                </select>
            </div>
        );
    }

    render() {
        if (this.props.temp_only == true) {
            return this.tempOnly();
        } else {
            return this.normalForm();
        }
    }
}
export default HiringType;