import * as React from "react";
import ProfileSkills from "./ProfileSkills";

interface Profile {
  skills: Array<any>;
  position_types: Array<any>;
  [prop: string]: any;
}

interface ProfileTagProps {
  profile: Profile;
}

export default function ProfileTags({ profile }: ProfileTagProps) {
  return (
    <React.Fragment>
      {profile.skills &&
        profile.position_types &&
        (profile.skills.length > 0 || profile.position_types.length > 0) && (
          <h4 className="tags-no-padding">
            <ProfileSkills
              profile={profile}
              position_type={profile.position_types}
              skills={profile.skills}
            />
          </h4>
        )}
    </React.Fragment>
  );
}
