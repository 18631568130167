import * as React from 'react'
import { Modal } from 'react-bootstrap'

export default function JobFilled() {
  // Show Popup State
  const [modalOpen, setModalOpen] = React.useState(true)

  // Close Modal
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Temp Job Filled
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p><strong>Sorry, this position has already been filled.</strong></p>
          {/* Search Jobs Button */}
          <a href="/jobs" className="btn btn-primary btn-block m-auto">
            <i className="fas fa-search mr-2" /> Search Jobs
          </a>
        </Modal.Body>
      </Modal>
    </>
  )
}