import * as React from "react"
import "../Common-poly"
import "whatwg-fetch"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css";
export default function JobsFilters(props) {
  const {
    form_elements,
    job_types,
    location,
    miles,
    onChange,
    onChangeSort,
    onChangeLocation,
    onChangeCheckbox,
    onChangeDate,
  } = props

  return (
    <div className="card shadow mb-4 browsing-card-design">
      <div className="card-header browsing-cards-head ">
        <i className="fas fa-search"></i> Search Filters
      </div>
      <div className="px-3">
        <div className="widget my-4">
          <h4>Position</h4>
          {/* <select className="chosen-select-no-single" name="job_type_title_cont" id="search_job_type_title_cont" onChange={onChange} value={form_elements.job_type_title_cont}> */}
          <select
            className="ddselect browsing-input-forms browsing-input"
            name="job_type_group_eq"
            id="search_job_type_title_cont"
            onChange={onChange}
            value={form_elements.job_type_group_eq}
          >
            <option value="">Please select</option>
            {job_types.map((jt) => (
              <option value={`${jt.group}-${jt.title}`}>{jt.title}</option>
            ))}
          </select>
        </div>
        <div className="widget my-4">
          <h4>Location</h4>
          <input
            type="text"
            className="my-4 browsing-input-forms browsing-input"
            name="location"
            id="location"
            value={location}
            placeholder="Zip Code"
            onChange={(e) => onChangeLocation(e)}
          />
          <div>
            <select
              name="miles"
              id="miles"
              className="ddselect miles browsing-input-forms browsing-input"
              onChange={(e) => onChangeLocation(e)}
            >
              <option value="">Miles From Location</option>
              <option value="5" selected={miles === 5}>
                5 miles
              </option>
              <option value="10" selected={miles === 10}>
                10 miles
              </option>
              <option value="15" selected={miles === 15}>
                15 miles
              </option>
              <option value="20" selected={miles === 20}>
                20 miles
              </option>
              <option value="25" selected={miles === 25}>
                25 miles
              </option>
              <option value="30" selected={miles === 30}>
                30 miles
              </option>
              <option value="35" selected={miles === 35}>
                35 miles
              </option>
              <option value="40" selected={miles === 40}>
                40 miles
              </option>
              <option value="45" selected={miles === 45}>
                45 miles
              </option>
              <option value="50" selected={miles === 50}>
                50 miles
              </option>
              <option value="75" selected={miles === 75}>
                75 miles
              </option>
              <option value="100" selected={miles === 100}>
                100 miles
              </option>
            </select>
          </div>
        </div>
        <div className="widget my-4">
          <h4>Position Type</h4>
          <ul className="checkboxes browsing-remove-bullet">
            {/* <li>
                        <input id="check-any" type="checkbox" name="search[position_type_eq_any][]" value="" checked="" className="first" onChange={onChangeCheckbox} />
                        <label for="check-any">Any Position Type</label>
                    </li> */}
            <li>
              <input
                id="check-fulltime"
                type="checkbox"
                name="position_type_eq_any"
                value="fulltime"
                onChange={onChangeCheckbox}
                checked={form_elements.position_type_eq_any.includes(
                  "fulltime"
                )}
              />
              <label for="check-fulltime">Full Time</label>
            </li>
            <li>
              <input
                id="check-parttime"
                type="checkbox"
                name="position_type_eq_any"
                value="parttime"
                onChange={onChangeCheckbox}
                checked={form_elements.position_type_eq_any.includes(
                  "parttime"
                )}
              />
              <label for="check-parttime">Part Time</label>
            </li>
            <li>
              <input
                id="check-longtermtemp"
                type="checkbox"
                name="position_type_eq_any"
                value="longtermtemp"
                onChange={onChangeCheckbox}
                checked={form_elements.position_type_eq_any.includes(
                  "longtermtemp"
                )}
              />
              <label for="check-longtermtemp">Long Term Temp</label>
            </li>
            <li>
              <input
                id="check-temp"
                type="checkbox"
                name="position_type_eq_any"
                value="temp"
                onChange={onChangeCheckbox}
                checked={form_elements.position_type_eq_any.includes("temp")}
              />
              <label for="check-temp">Temporary</label>
            </li>
          </ul>
        </div>
        <div className="widget my-4">
          <h4>Temp Date</h4>
          <Datetime
            value={
              form_elements.temp_date_eq
                ? moment.utc(form_elements.temp_date_eq).format("MM/DD/YYYY")
                : ""
            }
            // value={moment.utc()}
            inputProps={{
              name: `temp_date_eq`,
              readOnly: true,
              placeholder: "Select Date",
            }}
            onChange={(date) =>
              onChangeDate({ date, fieldName: "temp_date_eq" })
            }
            dateFormat={true}
            timeFormat={false}
            displayMode="days"
            dateFormat="MM/DD/YYYY"
          />
        </div>
        <div className="widget my-4">
          <h4>Sort by</h4>
          <select
            className="ddselect chosen-select-no-single browsing-input-forms browsing-input"
            name="sort"
            id="search_job_type_title_cont"
            onChange={onChangeSort}
          >
            <option value="">Sort</option>
            <option value="recent">Newest</option>
            <option value="oldest">Oldest</option>
            <option value="ratehigh">Hourly Rate - Highest First</option>
            <option value="ratelow">Hourly Rate - Lowest First</option>
            <option value="temp_earliest">Temp - Soonest Date</option>
            <option value="temp_latest">Temp - Latest Date</option>
          </select>
        </div>
      </div>
    </div>
  )
}
