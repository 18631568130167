import * as React from "react"
import BlogPost, { Post } from "./BlogPost"

const feedUrl = `https://blog.directdental.com/wp-json/wp/v2/posts?_embed`
const getFeed = (setState: (state: object) => void) => {
  fetch(feedUrl)
    .then((res) => res.json())
    .then((res) => {
      setState({ posts: res })
    })
}

export default function BlogPosts() {
  const [state, setState] = React.useState({ posts: [] })

  React.useEffect(() => {
    getFeed(setState)
  }, [])

  return (
    <div className="container">
      <div className="text-center ">
        <h3 className="mb-5 spotlight-header-text">From The Blog</h3>
      </div>
      <div className="row d-flex justify-content-center">
        {state.posts.slice(0, 3).map((post: Post) => (
          <BlogPost post={post} key={post.id} />
        ))}
      </div>
    </div>
  )
}
