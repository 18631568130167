import React from 'react';
import Profile from './Profile';

export const ProfileListContext = React.createContext();

const inviteCandidates = ({ job, is_temp, authenticity_token, callback, ids }) => {
    const url = is_temp
        ? `/jobs/${job.id}/request.json`
        : `/jobs/${job.id}/invite.json`;

    const options = {
        method: 'POST',
        redirect: 'manual',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticity_token
        },
        body: is_temp
            ? JSON.stringify({ temp_requests: [ids.map(id => `${id}`)] })
            : JSON.stringify({ invitees: ids })
    }

    fetch(url, options)
        .then(result => result.json())
        .then(result => {
            // API returns id if temp, profile_id if not
            const callback_results = is_temp ?
                result.map(r => r.id) :
                result.map(r => r.profile_id)

            callback(callback_results)
        },
            error => console.log(error)
        )
}

const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const ListContext = React.createContext();

const ProfilesList = ({ profiles, results = [], authenticity_token, favorites, job = undefined, requested = [], accepted = [], declined = [], user_id = undefined, blockList = false }) => {

    const [state, setState] = React.useState({
        requested, accepted, declined, loading: false
    })

    const [context, setContext] = React.useState({ blocked: [] })

    const addToRequested = id =>
        setState({ ...state, requested: [...state.requested, id] });

    const addAllToRequested = ids =>
        setState({ ...state, requested: [...state.requested, ...ids], loading: false });

    const is_temp = job && job.position_type == 'temp';

    const ids = profiles.map(profile => profile.id);

    const requested_all = arrayEquals(state.requested.sort(), ids.sort());

    // var Spinner = require('react-spinkit');

    return (
        <div className="padding-right" key={requested}>
            <ListContext.Provider value={[context, setContext]}>
                {state.loading && (
                    <div style={{ width: '100%', textAlign: 'right', marginBottom: 10 }}>
                        {/* <Spinner style={{ padding: 15 }} name="three-bounce" color="#186fc9" animationSpeed={5} /> */}
                        Loading...
                    </div>
                )}
                {!state.loading && job && !job.closed && !job.temp_filled && requested_all && profiles.length > 0 && (
                    <div style={{ width: '100%', textAlign: 'right', marginBottom: 10 }}>
                        <a className="button white">
                            <i className="fad fa-check"></i> {is_temp ? "Requested" : "Invited"} All
                        </a>
                    </div>
                )}
                {!state.loading && job && !job.closed && !job.temp_filled && !requested_all && (
                    <div style={{ width: '100%', textAlign: 'right', marginBottom: 10 }}>
                        <a className="button"
                            onClick={() => {
                                setState({ ...state, loading: true });
                                inviteCandidates({
                                    is_temp,
                                    authenticity_token,
                                    callback: e => {
                                        addAllToRequested(e);
                                    },
                                    ids: ids.filter(item => context.blocked.indexOf(item) < 0),
                                    job
                                })
                            }
                            }>
                            <i className="fad fa-users"></i> {is_temp ? "Request" : "Invite"} All
                        </a>
                    </div>
                )}
                <ProfileListContext.Provider value={{ blockList }}>
                    <ul className="job-list full">
                        {profiles.map(profile => {
                            const result = results.find(r => r._id == profile.id);
                            const distance = result ? result.fields.distance : false;
                            return <Profile
                                profile={profile}
                                authenticity_token={authenticity_token}
                                favorites={favorites}
                                job={job}
                                requested={state.requested}
                                accepted={state.accepted}
                                declined={state.declined}
                                addToRequestedFunction={addToRequested}
                                distance={distance}
                                user_id={user_id}
                            />
                        })}
                    </ul>
                </ProfileListContext.Provider>
            </ListContext.Provider>
        </div >
    );
}

export default ProfilesList;
