import * as React from 'react';

const ProfileJobTypes = ({ job_types, update, selected }) => {
    const [state, setState] = React.useState({ job_type_ids: selected });

    const updateTypes = event => {
        const value = parseInt(event.target.value);
        const { job_type_ids } = state;

        if (job_type_ids.includes(value)) {
            setState({ job_type_ids: job_type_ids.filter(jt => jt !== value) })
        } else {
            setState({ job_type_ids: [...job_type_ids, value] })
        }
    }

    React.useEffect(() => {
        update('job_type_ids', state.job_type_ids)
    }, [state]);

    return (
        <div className="form">
            <h3>Select Your Position(s)</h3>
            <p>
                <ul className="taglist" style={{ listStyle: "none" }}>
                    {job_types.map(jt => (
                        <li className="tags-job mb-1">
                            <label className="checkbox-container job" for={`profile_job_type_ids_${jt.id}`}>
                                <input className="d-none form-check-input upgrade-checkbox" type="checkbox" checked={state.job_type_ids.includes(jt.id) ? 'checked' : ''} value={jt.id} name="job_type_ids" id={`profile_job_type_ids_${jt.id}`} onChange={updateTypes} />
                                <span className="checkmark btn btn-primary" style={{ backgroundColor: '#0c6aca' }}>
                                    {/* Checkmark FontAwesome if checked is true */}
                                    {state.job_type_ids.includes(jt.id) ? <i className="fas fa-check me-1"></i> : ''}
                                    {jt.title}
                                </span>
                            </label>
                        </li>
                    ))}
                </ul>
            </p>
        </div>
    );
}

export default ProfileJobTypes;