import * as React from "react";
import AddFavoriteCandidate from "../../Favorites/AddFavoriteCandidate";
import AddBlockCandidate from "../../Favorites/NewAddBlockCandidate";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  [prop: string]: any;
}

interface NameProps {
  profile: Profile;
  authenticity_token: string;
  blockedCallback: any;
  favorites?: Array<number>;
  blocked?: Array<number>;
  job_id: any;
  user_id: any;
  invite_component: any;
}

function ProfileName({
  profile,
  authenticity_token,
  blockedCallback,
  favorites = [],
  blocked = [],
  job_id = undefined,
  user_id = undefined,
  invite_component = undefined,
}: NameProps) {
  {/* Capitalize first letter of profile.first_name */ }
  const first_name = profile.first_name.charAt(0).toUpperCase() + profile.first_name.slice(1);

  {/* Capitalize first letter of profile.last_name */ }
  const last_name = profile.last_name.charAt(0).toUpperCase() + profile.last_name.slice(1);
  return (
    <>
      <span className="h3 col-12 mb-0 mt-2 col-12 col-xl-6">
        {first_name} {last_name}
        <small className="d-block d-md-none d-lg-none" style={{ position: 'relative', left: 0 }}>
          {invite_component && invite_component}
        </small>
      </span>
    </>
  );
}

export default ProfileName;
