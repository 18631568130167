import * as React from "react";

function removeDuplicates(arr: Array<any>, prop: string) {
  var obj = {};
  for (var i = 0, len = arr.length; i < len; i++) {
    if (!obj[arr[i][prop]]) obj[arr[i][prop]] = arr[i];
  }
  var newArr = [];
  for (var key in obj) newArr.push(obj[key]);
  return newArr;
}

interface CategoryProps {
  name: string;
  color: string;
  count: number | string;
}
export default function ProfileSkills(props) {
  const { skills, profile } = props;

  // Get Unique Categories
  let categories = [
    ...Array.from(
      skills.map((skill) => ({
        name: skill.category,
        color: skill.color,
      }))
    ),
  ];

  // Count Category Occurences
  categories = categories.map((c: CategoryProps) => ({
    ...c,
    count: skills.filter((s: any) => s.category == c.name).length,
  }));

  categories = removeDuplicates(categories, "name");

  const classNames = "text-light rounded px-2 py-1 me-1 mb-1";

  return (
    <div className="d-flex my-1 flex-wrap px-4">
      {profile.position_types.includes("Full Time") && (
        <span className={`full-time ${classNames}`}>Full Time</span>
      )}
      {profile.position_types.includes("Part Time") && (
        <span className={`part-time ${classNames}`}>Part Time</span>
      )}
      {profile.position_types.includes("Long Term Temp") && (
        <span className={`part-time ${classNames}`}>Long Term Temp</span>
      )}
      {profile.position_types.includes("Temp") && (
        <span className={`temp ${classNames}`}>Temp</span>
      )}
      {categories.map((cat: CategoryProps) => {
        if (cat.count === 1) {
          const skill = skills.filter((s) => s.category == cat.name)[0];
          return (
            <span className={`full-time ${classNames}`} style={{ backgroundColor: cat.color }}>
              {skill.name}
            </span>
          );
        }
        return (
          <span className={`full-time ${classNames}`} style={{ backgroundColor: cat.color }}>
            ({cat.count}) {cat.name.split(" ")[0]}
          </span>
        );
      })}
    </div>
  );
}
