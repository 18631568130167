export default function RecruiterServiceCartReducer(state, action) {
  switch (action.type) {
    // Add item with a new item id
    case 'ADD_ITEM':
      return {
        ...state,
        items: [
          ...state.items,
          {
            id: state.items.length + 1,
            product: action.payload.product,
            jobType: action.payload.jobType,
            quantity: 1
          }
        ]
      }

    // Remove item by item id
    case 'REMOVE_ITEM':
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id)
      }

    // Update quantity of item
    case 'UPDATE_QUANTITY':
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
        )
      }

    // Update Job Type of item
    case 'UPDATE_JOB_TYPE':
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id ? { ...item, jobType: action.payload.jobType } : item
        )
      }


    // Update Product of Item
    case 'UPDATE_PRODUCT':
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id ? { ...item, product: action.payload.product } : item
        )
      }

    default:
      return state
  }
}