import * as React from 'react';
import { BlockMapBuilder } from 'draft-js';

// Add Favorite API Function
const AddFavorite = ({ profile_id, authenticity_token, callback }) => {
    const url = `/profiles/${profile_id}/add_favorite_candidate`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticity_token
        },
    })
        .then(res => res.json())
        .then(res => {
            callback(res.status.favorited);
        })
}


// Add Favorite API Function
const RemoveFavorite = ({ profile_id, authenticity_token, callback }) => {
    const url = `/profiles/${profile_id}/remove_favorite_candidate`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticity_token
        },
    })
        .then(res => res.json())
        .then(res => {
            callback(res.status.favorited);
        })
}

const faveButtonStyle = {
    padding: '0 0 0 10px',
    marginLeft: 5,
    marginBottom: -10,
    fontSize: 15
}

const iconStyle = {
    // marginLeft: 12,
    cursor: 'pointer',
}

// Add Favorite Button
const AddFavoriteButton = ({ profile, loading, favorited, onClick }) => {
    return (
        <>
            {!favorited && (
                <a onClick={onClick} className="text-decoration-none px-3 py-1 me-1 mb-1 pull-md-right pull-none ms-0" style={{ textAlign: 'center', margin: '12px' }}><i className="fad fa-heart" style={{ ...iconStyle, color: 'grey' }} aria-hidden="true"></i></a>
            )}
            {favorited && (
                <a onClick={onClick} className="text-decoration-none px-3 py-1 me-1 mb-1 pull-md-right pull-none ms-0" style={{ textAlign: 'center', margin: '12px' }}><i style={{ ...iconStyle, color: 'red' }} className="fad fa-heart" aria-hidden="true"></i></a>
            )}
        </>
    );
}

// HOC
const AddFavoriteCandidate = ({ profile, authenticity_token, favorited_init }) => {
    const [state, setState] = React.useState({
        favorited: favorited_init,
        loading: false
    });

    const updateFavoriteState = favorited =>
        setState({
            favorited: favorited,
            loading: false
        })

    const RemoveFavoriteHandler = (e) => {
        e.stopPropagation();
        setState({ ...state, loading: true });
        RemoveFavorite({
            profile_id: profile.id,
            authenticity_token: authenticity_token,
            callback: f => updateFavoriteState(f)
        });
    }

    const AddFavoriteHandler = (e) => {
        e.stopPropagation();
        setState({ ...state, loading: true });
        AddFavorite({
            profile_id: profile.id,
            authenticity_token: authenticity_token,
            callback: f => updateFavoriteState(f)
        });
    }

    return (
        <AddFavoriteButton
            profile={profile}
            favorited={state.favorited}
            loading={state.loading}
            onClick={state.favorited ? RemoveFavoriteHandler : AddFavoriteHandler}
        />
    );
}

export default AddFavoriteCandidate;