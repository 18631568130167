import * as React from 'react'

import HelpButton from '../Interface/HelpButton'

interface Props {
  stripe_plans: Array<StripePlan>
  create_quote_path: string
  form_authenticity_token: string
  job_id: string
  publish_job_path: string
  job_path: string
  priority: boolean
  email_blast: boolean
  spotlight: boolean
}

interface StripePlan {
  id: number
  name: string
  stripe_plan: string
  features: string
  price: string
  upgrade_text: string
  upgrade: boolean
}

export default function JobUpgrade({
  stripe_plans,
  create_quote_path,
  form_authenticity_token,
  job_id,
  publish_job_path,
  job_path,
  priority,
  email_blast,
  spotlight
}: Props) {

  const current_upgrades = { priority, email_blast, spotlight }

  // Default "Candidate Auto Match" to checked
  const [selected_plans, setSelectedPlans] = React.useState<Array<StripePlan>>([
    stripe_plans.find(plan => plan.name.includes('Auto Match'))
  ])

  // Toggle selected plans
  const toggleSelectedPlan = (stripe_plan: StripePlan) => {
    const { id, upgrade } = stripe_plan

    // Return if Auto Match is selected unless it is not included in selected_plans
    if (upgrade && stripe_plan.name.includes('Auto Match') && selected_plans.find(plan => plan.name.includes('Auto Match'))) {
      return
    }

    // If all options are selected, clear out array and select the Bundle plan
    if (upgrade && selected_plans.length === stripe_plans.length - 1) {
      setSelectedPlans([stripe_plans.find(plan => plan.name.includes('Bundle'))])
      return
    }

    // Clear out array and add the new plan if the plan name includes Bundle and bundle is not already selected
    if (stripe_plan.name.includes('Bundle') && !selected_plans.find(plan => plan.name.includes('Bundle'))) {
      if (upgrade) {
        setSelectedPlans([stripe_plan])
        return
      } else {
        return
      }
    }

    // If the plan is already selected, remove it and remove Bundle if it's selected
    if (selected_plans.find(plan => plan.id === id)) {
      // If the Bundle plan is selected, remove it
      if (upgrade && selected_plans.find(plan => plan.name.includes('Bundle'))) {
        setSelectedPlans(selected_plans.filter(plan => plan.name.includes('Bundle') === false))
      }
      // Remove the plan
      setSelectedPlans(selected_plans.filter(plan => plan.id !== id))
    } else {
      // Otherwise, add it
      if (upgrade || !stripe_plan.name.includes('Bundle')) {
        setSelectedPlans([...selected_plans, stripe_plan])
      }
    }
  }

  // UseEffect: If all upgrade options are selected, clear out array and select the Bundle plan, but leave the non-upgrade plans selected
  React.useEffect(() => {
    // Get all upgrade plans and check if they are all selected
    const upgradePlans = stripe_plans.filter(plan => plan.upgrade && !plan.name.includes('Bundle'));
    const allUpgradePlansSelected = upgradePlans.every(upgradePlan =>
      selected_plans.some(selectedPlan => selectedPlan.id === upgradePlan.id)
    );

    // If all upgrade plans are selected, select the Bundle plan and make sure to keep any non-upgrade plans
    if (allUpgradePlansSelected) {
      setSelectedPlans([
        ...selected_plans.filter(plan => !plan.upgrade),
        stripe_plans.find(plan => plan.name.includes('Bundle'))
      ]);
    }
  }, [selected_plans, stripe_plans]);

  // Total Price
  const total_price = selected_plans.reduce((total, plan) => {
    return total + parseFloat(plan.price)
  }, 0)

  // Your upgrade price is $
  const upgrade_string = selected_plans.length > 0
    ? `Your add-on price total is $${total_price}`
    : ''

  // Bundle Selected?
  const bundle_selected = selected_plans.find(plan => plan.name.includes('Bundle'))

  // stripe_plans where upgrade is true
  const upgrade_plans = stripe_plans.filter(plan => plan.upgrade)

  // stripe_plans where upgrade is false
  const non_upgrade_plans = stripe_plans.filter(plan => !plan.upgrade)

  const containerRef = React.useRef(null);

  const [isSticky, setIsSticky] = React.useState(false);

  const checkStickiness = () => {
    // Check to see if containerRef is in view
    const container = containerRef.current;
    const containerTop = container.getBoundingClientRect().top;
    const containerBottom = container.getBoundingClientRect().bottom;
    const containerHeight = container.getBoundingClientRect().height;
    const windowHeight = window.innerHeight;

    // If the container is in view, set isSticky to true
    if (containerTop >= 0 && containerBottom <= windowHeight) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', checkStickiness);
    return () => {
      window.removeEventListener('scroll', checkStickiness);
    };
  }, []);

  return (
    <div className="pb-5">
      {/* Highlight the word New with yellow */}
      <div className="text-center">
        <span className="text-danger text-bold mb-3"><i>New for Essential Members!</i></span>
      </div>
      <h2 className="text-center pb-1">Upgrade your job posting</h2>
      <p className="text-center">Check out these optional add-ons to help your job reach more candidates!</p>
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          <div className="shadow rounded-3 py-5">
            {upgrade_plans.map((stripe_plan, index) => (
              <StripePlan
                key={stripe_plan.id}
                stripe_plan={stripe_plan}
                onClick={() => toggleSelectedPlan(stripe_plan)}
                selected_plans={selected_plans}
                bundle_selected={bundle_selected}
              />
            ))}
          </div>
          <h2 className="text-center mt-5">Recruiter Services</h2>
          <p className="text-center">More ways to hire quickly and easily!</p>
          <div className="shadow rounded-3 py-5">
            {non_upgrade_plans.map((stripe_plan, index) => (
              <StripePlan
                key={stripe_plan.id}
                stripe_plan={stripe_plan}
                onClick={() => toggleSelectedPlan(stripe_plan)}
                selected_plans={selected_plans}
                bundle_selected={bundle_selected}
              />
            ))}
          </div>
        </div>
        {total_price > 0 && (
          <div className={`sticky-bottom bg-white p-3 text-center mt-3 bt-1 ${!isSticky ? 'shadowed' : ''}`}>
            <span className="h3 pb-2">{upgrade_string}</span>

            {/* Form Tag to create_quote_path */}
            <form action={create_quote_path} method="post">
              <input type="hidden" name="authenticity_token" value={form_authenticity_token} />

              {/* Job ID Hidden Field */}
              <input type="hidden" name="job_id" value={job_id} />

              {/* Hidden Field return_path */}
              <input type="hidden" name="return_path" value={publish_job_path} />

              {/* Hidden Field Purchase Name */}
              <input type="hidden" name="purchase_name" value="Purchase - Job Upgrade" />

              {/* Hidden Field Purchase Amount */}
              <input type="hidden" name="purchase_amount" value={total_price} />

              {/* Hidden Field for Prices */}
              {/* Reject ones with empty stripe_plan */}
              {selected_plans.filter((plan) => plan.stripe_plan !== '').map((plan) => (
                <input type="hidden" name="prices[]" value={plan.stripe_plan} />
              ))}

              {/* If the total_price is 0, show a "Publish Job" button, otherwise a "Checkout and Publish" button */}
              {total_price > 0 && (
                <button className="btn btn-primary btn-lg btn-block mt-2">Checkout and Publish</button>
              )}
            </form>
          </div>)}

        {total_price === 0 && (
          <div className={`sticky-bottom bg-white p-3 text-center mt-3 bt-1 ${!isSticky ? 'shadowed' : ''}`}>

            {/* <div className="col-12 text-center py-3"> */}
            <button onClick={() => {
              publish_job_path && window.location.replace(publish_job_path)
            }}
              className="btn btn-primary btn-lg btn-block">
              No Thanks, Publish Job Without Add-Ons
            </button>
          </div>
        )}
      </div>
      <div ref={containerRef}></div>
    </div >
  )
}

interface StripePlanProps {
  stripe_plan: StripePlan
  onClick: () => void
  selected_plans: Array<StripePlan>
  bundle_selected?: StripePlan
}

function StripePlan({
  stripe_plan,
  onClick,
  selected_plans,
  bundle_selected
}: StripePlanProps) {
  // const features = stripe_plan.features.split('|').join(', ')
  const { upgrade_text } = stripe_plan

  // Get text between [ and ] in upgrade_text
  const upgrade_text_match = upgrade_text.match(/\[(.*?)\]/)

  // Upgrade text - remove text between [ and ]
  const upgrade_text_without_match = upgrade_text.replace(/\[(.*?)\]/, '')

  // Run onClick only if !bundle_selected and stripe_plan.name does not include Bundle
  // If the plan is not selected, select the plan
  const onClickWrapper = () => {
    if (!bundle_selected) {
      onClick()
    }
    // If the plan name includes Bundle, select the Bundle plan
    if (stripe_plan.name.includes('Bundle')) {
      onClick()
    }

    // If the plan is not an upgrade, select the plan
    if (!stripe_plan.upgrade) {
      onClick()
    }

  }

  // Opacity of 80% if bundle is selected
  const opacity = bundle_selected && !stripe_plan.name.includes('Bundle') && stripe_plan.upgrade
    ? '50%'
    : '100%'

  // Price is 0
  const price_is_zero = stripe_plan.price == "0"

  return (
    <div className="col-12" style={{ opacity }}>
      <div className="text-start px-5 justify-content-between rounded-3"
        style={{ cursor: 'pointer' }}
        onClick={onClickWrapper}>
        {/* Checkbox */}
        <div className="d-flex flex-row">
          {!bundle_selected && <input
            className="form-check-input upgrade-checkbox col-1"
            type="checkbox"
            value=""
            id={`upgrade-${stripe_plan.id}`}
            checked={selected_plans.includes(stripe_plan)}
            style={{ width: '25px', height: '25px', borderRadius: '5px' }}
          />}
          {bundle_selected && <input
            className="form-check-input upgrade-checkbox col-1"
            type="checkbox"
            value=""
            id={`upgrade-${stripe_plan.id}`}
            checked={stripe_plan.upgrade ? true : selected_plans.includes(stripe_plan)}
            style={{ width: '25px', height: '25px', borderRadius: '5px' }}
          />}
          <h3 className="pt-1 px-2 h5">
            <strong>{stripe_plan.name} - {(stripe_plan.price != "0") ? `$${stripe_plan.price}` : `Included`}</strong>
            <small className="d-block" style={{ fontSize: '0.7em' }}>
              {upgrade_text_without_match}
              <span className="text-danger text-decoration-underline d-block">{upgrade_text_match && upgrade_text_match[1]}</span>
            </small>
          </h3>
          <HelpButton message={stripe_plan.name} style="" />
        </div>

      </div>
    </div >
  )
}