import * as React from 'react'

export default function RecruiterServiceCartProducts({
  products,
  job_types,
  jobType,
  setJobType,
  addItem
}) {
  return (
    <div className="d-flex flex-column gap-2 justify-content-between py-4 px-3">
      {/* JobType Dropdown */}
      <select
        className="form-control form-select flex-1"
        value={jobType.id}
        onChange={event => {
          const jobTypeId = parseInt(event.target.value)
          const jobType = job_types.find(jobType => jobType.id === jobTypeId)
          setJobType(jobType)
        }}>
        {job_types.map(jobType => (
          <option key={jobType.id} value={jobType.id}>
            {jobType.title}
          </option>
        ))}
      </select>

      {/* Add Product Buttons */}

      {products.map(product => (
        <button onClick={() => addItem(product, jobType)} className="btn btn-primary flex-2">
          Add {product.name}
        </button>
      ))}
    </div>
  )
}