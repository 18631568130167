import * as React from "react";

interface JobTypeProps {
  job_type: string;
}

export default function ProfileJobType({ job_type }: JobTypeProps) {
  return (
    <span>
      <i className="fa fa-tooth"></i> {job_type}
    </span>
  );
}
