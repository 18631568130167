import * as React from "react"
import JobForm from "./JobFormContext"
import HelpIconWithTooltip from "../../Interface/HelpButton"

export default function Compensation({ tabIndexFunc, benefitsClass = "", setValidation, enforcePayRange = false }) {
  const [jobState, setJobState] = React.useContext(JobForm)
  // Tried to continue
  const [tried, setTried] = React.useState(false)

  const selectCompensationType = e => {
    setJobState({ ...jobState, compensation_type: e.target.value })
  }

  const benefitHandler = e => {
    const benefitMapping = {
      "bonuses": "benefit_bonuses",
      "medical": "benefit_medical",
      "dental": "benefit_dental",
      "vision": "benefit_vision",
      "fourk": "benefit_401k",
      "pto": "benefit_pto"
    };

    const key = benefitMapping[e];
    if (key) {
      setJobState({ ...jobState, [key]: !jobState[key] });
    }
  }

  //Sets the state for exact_pay
  const exactPayHandler = e => {
    setJobState({ ...jobState, exact_pay: !jobState.exact_pay })
  }

  //sets pay in the job state
  const inputHandler = (e, type) => {
    if (type === "starting") {
      setJobState({ ...jobState, min_compensation: e.target.value })
    }

    if (type === "ending") {
      setJobState({ ...jobState, compensation: e.target.value })
    }
  }

  //listens to exact_pay and will change compensation to null if exaxt pay is checked
  React.useEffect(() => {
    if (jobState.exact_pay) {
      setJobState({ ...jobState, compensation: null })
    }
  }, [jobState.exact_pay])


  // If enforcePayRange is true, then we need to make sure that the pay range is valid and not blank
  // If enforcePayRange is false, then we don't care if the values are blank
  const min_compensation_is_valid = enforcePayRange ?
    Number(jobState.min_compensation) > 0 && jobState.min_compensation !== "" && jobState.min_compensation !== null && jobState.min_compensation !== undefined && jobState.min_compensation !== "0"
    : true

  const compensation_is_valid = enforcePayRange ?
    Number(jobState.compensation) > 0 && jobState.compensation !== "" && jobState.compensation !== null && jobState.compensation !== undefined && jobState.compensation !== "0"
    : true

  // validation is true when either
  // exact pay is true and compensation is valid
  // exacy pay is false and both are valid
  const validation = jobState.exact_pay ? min_compensation_is_valid : (min_compensation_is_valid && compensation_is_valid)

  React.useEffect(() => {
    if (
      (!jobState.min_compensation || jobState.min_compensation === "") &&
      (!jobState.compensation || jobState.compensation === "")
    ) {
      setJobState({ ...jobState, doe: true })
    } else {
      setJobState({ ...jobState, doe: false })
    }

    setValidation(validation)

  }, [jobState.min_compensation, jobState.compensation])

  // One time run UseEffect
  React.useEffect(() => {
    setValidation(validation)
  }, [])

  return (
    <>
      <div className="form-group py-2">
        <h4 htmlFor="compensation_type" className="font-weight-bold">
          Compensation Type
        </h4>
        <select
          className="form-select"
          id="compensation_type"
          onChange={selectCompensationType}
          value={jobState.compensation_type}
        >
          <option value="hourly" className="text-primary">
            Hourly
          </option>
          <option value="daily" className="text-primary">
            Daily
          </option>
          <option value="monthly" className="text-primary">
            Monthly
          </option>
          <option value="annually" className="text-primary">
            Annually
          </option>
        </select>
      </div>

      <div>
        <h4 htmlFor="compensation_rate" className="font-weight-bold">
          Compensation Rate
        </h4>
        <div className="d-flex flex-row">
          <input
            style={tried && !min_compensation_is_valid ? { backgroundColor: "#f8d7da" } : {}}
            className="form-control mb-3"
            type="number"
            placeholder="$"
            onChange={e => inputHandler(e, "starting")}
            value={jobState.min_compensation}
          />
          {jobState.exact_pay ? null : <h5 className="px-3 mt-2">to</h5>}
          {jobState.exact_pay ? null : (
            <input
              // If validation is true, set background to light red
              style={tried && !compensation_is_valid ? { backgroundColor: "#f8d7da" } : {}}
              className={`form-control mb-3`}
              type="number"
              placeholder="$"
              onChange={e => inputHandler(e, "ending")}
              value={jobState.compensation}
            />
          )}
        </div>
        <div className="form-check mb-3">
          {tried && !validation || (Number(jobState.compensation) < Number(jobState.min_compensation) &&
            jobState.compensation) ? (
            <div className="col-12">
              <p className="text-danger ps-0 ms-0">
                <span style={{ float: 'left' }}>Please enter a valid compensation.</span>

                {/* Light font weight */}
                <span>
                  <HelpIconWithTooltip message="pay_transparency" style={{ float: 'left', paddingLeft: 12, fontSize: 13, paddingTop: 5 }} />
                </span>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-check mb-3">
          <div className="col-12">
            <input
              className="form-check-input "
              type="checkbox"
              id="flexCheckExact"
              onClick={e => exactPayHandler(e)}
              checked={jobState.exact_pay}
            />
            <label className="form-check-label" for="flexCheckExact">
              Switch to Exact Pay
            </label>
          </div>
        </div>
      </div >

      {
        jobState.position_type !== "temp" && <div>
          <h4 htmlFor="compensation_benefits" className="font-weight-bold">
            Benefits
          </h4>

          <div className={`d-flex mb-4 ${benefitsClass}`}>
            <div className="form-check ps-0 ms-0">
              <input
                className="form-check-input btn-check"
                type="checkbox"
                id="flexCheckBonuses"
                checked={jobState.benefit_bonuses && jobState.benefit_bonuses}
                onClick={e => benefitHandler("bonuses")}
              />
              <label
                className="form-check-label btn text-light rounded px-2 py-1 me-1 mb-1"
                for="flexCheckBonuses"
                style={
                  jobState.benefit_bonuses && jobState.benefit_bonuses
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "green", opacity: 0.65 }
                }
              >
                {jobState.benefit_bonuses && jobState.benefit_bonuses ? (
                  <i className="fa fa-check text-white" aria-hidden="true"></i>
                ) : (
                  ""
                )}{" "}
                Bonuses
              </label>
            </div>
            <div className="form-check ps-0 ms-0">
              <input
                className="form-check-input btn-check"
                type="checkbox"
                id="flexCheckMedical"
                checked={jobState.benefit_medical && jobState.benefit_medical}
                onClick={e => benefitHandler("medical")}
              />
              <label
                className="form-check-label btn text-light rounded px-2 py-1 me-1 mb-1"
                for="flexCheckMedical"
                style={
                  jobState.benefit_medical && jobState.benefit_medical
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "green", opacity: 0.65 }
                }
              >
                {jobState.benefit_medical && jobState.benefit_medical ? (
                  <i className="fa fa-check text-white" aria-hidden="true"></i>
                ) : (
                  ""
                )}{" "}
                Medical
              </label>
            </div>
            <div className="form-check ps-0 ms-0">
              <input
                className="form-check-input btn-check"
                type="checkbox"
                id="flexCheckDental"
                checked={jobState.benefit_dental && jobState.benefit_dental}
                onClick={e => benefitHandler("dental")}
              />
              <label
                className="form-check-label btn text-light rounded px-2 py-1 me-1 mb-1"
                for="flexCheckDental"
                style={
                  jobState.benefit_dental && jobState.benefit_dental
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "green", opacity: 0.65 }
                }
              >
                {jobState.benefit_dental && jobState.benefit_dental ? (
                  <i className="fa fa-check text-white" aria-hidden="true"></i>
                ) : (
                  ""
                )}{" "}
                Dental
              </label>
            </div>
            <div className="form-check ps-0 ms-0">
              <input
                className="form-check-input btn-check"
                type="checkbox"
                id="flexCheckVision"
                checked={jobState.benefit_vision && jobState.benefit_vision}
                onClick={e => benefitHandler("vision")}
              />
              <label
                className="form-check-label btn text-light rounded px-2 py-1 me-1 mb-1"
                for="flexCheckVision"
                style={
                  jobState.benefit_vision && jobState.benefit_vision
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "green", opacity: 0.65 }
                }
              >
                {jobState.benefit_vision && jobState.benefit_vision ? (
                  <i className="fa fa-check text-white" aria-hidden="true"></i>
                ) : (
                  ""
                )}{" "}
                Vision
              </label>
            </div>
            <div className="form-check ps-0 ms-0">
              <input
                className="form-check-input btn-check"
                type="checkbox"
                id="flexCheckFourk"
                checked={jobState.benefit_401k && jobState.benefit_401k}
                onClick={e => benefitHandler("fourk")}
              />
              <label
                className="form-check-label btn text-light rounded px-2 py-1 me-1 mb-1"
                for="flexCheckFourk"
                style={
                  jobState.benefit_401k && jobState.benefit_401k
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "green", opacity: 0.65 }
                }
              >
                {jobState.benefit_401k && jobState.benefit_401k ? (
                  <i className="fa fa-check text-white" aria-hidden="true"></i>
                ) : (
                  ""
                )}{" "}
                401K
              </label>
            </div>
            <div className="form-check ps-0 ms-0">
              <input
                className="form-check-input btn-check"
                type="checkbox"
                id="flexCheckPto"
                checked={jobState.benefit_pto && jobState.benefit_pto}
                onClick={e => benefitHandler("pto")}
              />
              <label
                className="form-check-label btn text-light rounded px-2 py-1 me-1 mb-1"
                for="flexCheckPto"
                style={
                  jobState.benefit_pto && jobState.benefit_pto
                    ? { backgroundColor: "green" }
                    : { backgroundColor: "green", opacity: 0.65 }
                }
              >
                {jobState.benefit_pto && jobState.benefit_pto ? (
                  <i className="fa fa-check text-white" aria-hidden="true"></i>
                ) : (
                  ""
                )}{" "}
                PTO
              </label>
            </div>
          </div>
        </div>
      }

      < button
        type="button"
        className="btn btn-primary pull-left"
        onClick={() => {
          tabIndexFunc(1)
        }
        }
      >
        Back
      </button >
      <button
        type="button"
        className="btn btn-primary pull-right"
        onClick={() => {
          setTried(true)
          if (validation) {
            tabIndexFunc(3)
          } else {
            () => { }
          }
        }}
      >
        Continue
      </button>
    </>
  )
}
