import React from 'react';
import LocationsToggle from '../Locations/LocationsToggle';

export default function UpdateSubscriptionButton({
  location_id = null,
  form_path,
  subscription,
  item_id,
  price,
  quantity,
  return_url,
  form_authenticity_token,
  locations,
  old_quantity,
}) {
  const formRef = React.useRef(null);

  const [showLocations, setShowLocations] = React.useState(false);
  const handleCloseLocation = () => setShowLocations(false);

  const [locations_state, setLocationsState] = React.useState(locations);

  // Boolean, is quantity < old_quantity
  const is_quantity_less = quantity < old_quantity;

  const apiData = {
    form_authenticity_token,
    subscription,
    item_id,
    price,
    quantity,
    return_url,
    locations: locations_state
  }

  // onSubmit Function
  const onSubmit = (e) => {
    e.preventDefault();
    if (is_quantity_less) {
      setShowLocations(true);
    } else {
      const form = formRef.current;
      form.submit();
    }
  }

  const onLocationUpdateAndSubmit = (e) => {
    const form = formRef.current;
    form.submit();
  }

  return (
    <>
      <LocationsToggle
        setLocationsStateFunction={setLocationsState}
        limit={quantity}
        showLocations={showLocations}
        handleCloseLocation={handleCloseLocation}
        locations={locations}
        buttonText="Update"
        buttonFunction={onLocationUpdateAndSubmit}
      />

      {/* Form with Ref */}
      <form action={form_path} method="post" id="update-subscription-form" ref={formRef} onSubmit={onSubmit}>
        {/* Hidden Fields for apiData */}
        <input type="hidden" name="authenticity_token" value={apiData.form_authenticity_token} />
        <input type="hidden" name="subscription" value={apiData.subscription} />
        <input type="hidden" name="item_id" value={apiData.item_id} />
        <input type="hidden" name="price" value={apiData.price} />
        <input type="hidden" name="quantity" value={apiData.quantity} />
        <input type="hidden" name="return_url" value={apiData.return_url} />
        {/* if location_id exists, hidden field named location_id */}
        {location_id && <input type="hidden" name="enable_location_id" value={location_id} />}

        {/* Array Rest API locations field */}
        {/* Active Locations */}
        {apiData.locations.map(location => {
          return (
            <>
              <input type="hidden" name="locations[][id]" value={location.id} />
              <input type="hidden" name="locations[][active]" value={location.active} />
            </>
          )
        })}

        <button type="submit" className="btn text-white cardButton mx-1">Update Membership</button>
      </form>
    </>
  );
}