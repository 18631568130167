import React from "react"

export default function Pagination(props) {
  const { current_page, total_pages, navigateTo, prev_page, next_page } = props
  const pages = []

  for (let page = 1; page <= total_pages; page++) {
    const pageClass =
      page == current_page ? "current-page page-btn-font" : "page-btn-font"
    if (page == 2 && current_page >= 5) {
      pages.push(
        <li className="mx-1 " style={{ cursor: "pointer" }}>
          <a className={pageClass} onClick={() => navigateTo(1)}>
            1
          </a>
        </li>
      )

      pages.push(
        <li className="mx-1 ">
          <a className="page-btn-font">...</a>
        </li>
      )
    }
    if (page < current_page - 3 && page != total_pages) {
      continue
    }
    if (page > current_page + 3 && page != total_pages) {
      continue
    }
    if (page == total_pages && current_page <= total_pages - 5) {
      pages.push(
        <li className="mx-1 " style={{ cursor: "pointer" }}>
          <a className="page-btn-font" onClick={() => navigateTo(next_page)}>
            ...
          </a>
        </li>
      )
    }
    if (page > current_page + 3 && page == total_pages) {
      continue
    }

    pages.push(
      <li className="mx-1 " style={{ cursor: "pointer" }} key={page}>
        <a className={pageClass} onClick={() => navigateTo(page)}>
          {page}
        </a>
      </li>
    )
  }

  return (
    <div className="container-flex row pagination-container my-3 pb-5">
      <nav className="pagination col-3 col-lg-2 offset-1 offset-lg-3">
        <ul className="pagination-style list-group list-group-horizontal">
          {pages}
        </ul>
      </nav>
      <nav className="pagination-next-prev col-6 col-lg-3 offset-1">
        <ul className="pagination-style list-group list-group-horizontal">
          <li className="mx-1 " style={{ cursor: "pointer" }} key="prev">
            {current_page !== prev_page && (
              <a
                rel="prev"
                className="prev page-btn-font"
                onClick={() => navigateTo(prev_page)}
              >
                ‹ Prev
              </a>
            )}
          </li>
          <li></li>
          <li className="mx-1 " style={{ cursor: "pointer" }} key="next">
            {current_page !== next_page && (
              <a
                rel="next"
                className="next page-btn-font"
                onClick={() => navigateTo(next_page)}
              >
                Next ›
              </a>
            )}
          </li>
        </ul>
      </nav>
    </div>
  )
}
