import * as React from 'react'
import Dropzone from "dropzone"
import { useEffect, useRef } from 'react'

import { ProfileFormContext } from '../ProfileEditForm'
import { ACTIONS } from './ProfileFormReducer'

export default function DocumentUpload() {
  const dropzoneRef = useRef(null);
  const myDropzone = useRef(null);

  // Context
  const [state, dispatch] = React.useContext(ProfileFormContext)

  // Files State
  const [files, setFiles] = React.useState([])

  // Get Files from uploaded_profile_documents.json
  const getFiles = () => {
    fetch('/uploaded_profile_documents.json')
      .then(response => response.json())
      .then(data => {
        setFiles(data)

        // emit
        console.log("Data", data);
        // Emit file added for each data
        data.forEach((file) => {
          // Mockfile
          const mockFile = {
            id: file.id,
            name: file.document_file_name,
            size: file.document_file_size,
            url: file.url,
            thumbnail: "/doc.png"
          }

          myDropzone.current.emit("addedfile", mockFile);
          myDropzone.current.emit("complete", mockFile);
          myDropzone.current.emit("thumbnail", mockFile, "/doc.png");
        })
      })
  }

  console.log("XCSRF", document.querySelector('meta[name="csrf-token"]').getAttribute('content'))
  useEffect(() => {
    if (!myDropzone.current) {
      myDropzone.current = new Dropzone(dropzoneRef.current, {
        url: "/profiles/" + state.profile.id + "/upload",
      });


      // Options.iploadProfileDocuments headers
      myDropzone.current.options.headers = {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }

      // dictDefaultMessage
      myDropzone.current.options.dictDefaultMessage = "Drag and drop your documents here or click to upload"

      // addRemoveLinks
      myDropzone.current.options.addRemoveLinks = false

      // dictRemoveFileConfirmation
      myDropzone.current.options.dictRemoveFileConfirmation = "Are you sure you want to remove this file?"

      // createImageThumbnails
      myDropzone.current.options.createImageThumbnails = false

      // Preview Template with image
      myDropzone.current.options.previewTemplate = `
        <div class="dz-preview dz-file-preview bg-secondary rounded">
          <div class="dz-details row my-3">
            <div class="col-2 p-2 flex-column d-flex justify-content-center align-items-center">
              <img data-dz-thumbnail class="img-fluid"/>
            </div>
            <div class="col-8 flex-column d-flex justify-content-center">
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="dz-size" data-dz-size></div>
              </div>
              <div class="col-2 flex-column d-flex justify-content-center align-items-center">
                <div class="dz-remove">
                  <a class="btn btn-danger btn-sm" data-dz-remove>
                    <i class="fas fa-trash"></i>
                  </a>
                </div>
              </div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          </div>
          `
      // <div class="dz-success-mark"><span>✔</span></div>
      // <div class="dz-error-mark"><span>✘</span></div>
      // <div class="dz-error-message"><span data-dz-errormessage></span></div>

      getFiles();
    }

    myDropzone.current.on("addedfile", (file) => {
      console.log("Added", file)
    });

    myDropzone.current.on("removedfile", (file) => {
      console.log("Removed", file)
      // Delete Fetch
      fetch("/delete_document/" + file.id, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": state.authenticity_token
        }
      });
    });

    myDropzone.current.on("success", (file) => {
      console.log("Success", file)
    });

    myDropzone.current.on("error", (file) => {
      console.log("Error", file)
    });

    myDropzone.current.on("complete", (file) => {
      console.log("Complete", file)
    });

    myDropzone.current.on("sending", (file) => {
      console.log("Sending", file)
    });

    myDropzone.current.on("canceled", (file) => {
      console.log("Canceled", file)
    })


  }, []);

  return (
    <>
      <div id="DocumentDropzone" className="DocumentDropzone container my-3">
        <label htmlFor="DocumentDropzone" className="form-label">Certificates, Licenses & Other Documents</label>

        <div id="DocumentDropzoneContainer" ref={dropzoneRef}>
          {/* Drag to upload message */}
          <div className="text-center bg-light pt-5 px-2 pb-4 rounded border"
            onClick={() => myDropzone.current.hiddenFileInput.click()}
            style={{ cursor: "pointer" }}
          >
            <p><i className="fas fa-cloud-upload-alt"></i> Drag and drop your documents here or click to upload</p>
          </div>
        </div>
      </div>
    </>
  )
}