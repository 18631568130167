import * as React from 'react';

interface OrderSummaryProps {
  id: number;
  name: string;
  price: string;
  savings_price?: string;
  features?: string;
  trial?: boolean;
  product_name: string;
  tagline: string;
  savings: string;
  product_type?: string;
  stripe_plan: string;
  form_authenticity_token?: string;
  subscription?: boolean;
  term?: string;
  disclaimer?: string;
}

interface OSProps {
  price: string;
  description: string;
  trial?: boolean;
  subscription?: boolean;
  features: any;
}

function namely(name) {
  return <small>/ {name}{name.includes('ly') ? '' : 'ly'}</small>
}

const Questions = () =>
  <div>
    <h3><strong>Questions?</strong></h3>
    <p>Call us at 1-800-799-2644</p>
  </div>

export default function OrderSummary({
  name, price, product_name, features, subscription = false, term, disclaimer
}: OrderSummaryProps) {

  return (
    <div className="pricing-behind" style={styles.pricingBehind}>
      <h2 className="comoTitle mb-20" style={styles.como}>Order Summary</h2>
      <table className="mb-20" style={styles.table}>
        <tbody>
          <tr>
            <td style={styles.nameStyle}>
              <strong>{name}</strong><br />
              <small>
                <ul style={{ listStyle: 'disc', marginLeft: 20 }}>
                  {features.split('|').map(f => {
                    return <li style={{ paddingBottom: 5 }}>{f}</li>
                  })}
                </ul>
              </small>
              <p style={{ fontSize: '15px', fontStyle: 'italic', lineHeight: '18px' }}>
                {disclaimer}
              </p>
            </td>
            <td style={styles.pricingStyle}>
              ${price} / {term}
              {/* {subscription && namely(name)} */}
            </td>
          </tr>
        </tbody>
      </table>
      {subscription && (<p>Unlimited Access. No Contracts. Cancel anytime.</p>)}
      <Questions />
    </div>
  );
}

const styles = {
  nameStyle: { width: '70%' },
  pricingStyle: { width: '30%', textAlign: 'right' },
  table: { width: '100%' },
  como: { borderBottom: '1px solid #c9c9c9' },
  pricingBehind: { marginTop: 0 }
}