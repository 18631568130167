import * as React from "react";

interface CompensationProps {
  compensation: string | number;
}

export default function ProfileCompensation({
  compensation,
}: CompensationProps) {
  return (
    <span>
      <i className="fa fa-money"></i> {compensation} / hour
    </span>
  );
}
