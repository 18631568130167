import '../Common-poly'
import React from 'react';

const JobHome = ({ job_types }) => {
    // Convert strings: "Dental Assistant" => "dental-assistant"
    const toParam = str => str.toLowerCase().replace(/\s/g, "-").replace('/', '_');

    // State to store selection
    const [state, setState] = React.useState({
        job_type: ""
    });

    // Set new selection
    const handleChange = job_type => setState(state => ({
        ...state, job_type
    }));

    // Job Type Options for Dropdown
    const jobTypeOptions = job_types.map(jt =>
        <option key={toParam(jt[1])} value={toParam(jt[1])}>
            {jt[0]}
        </option>
    );

    return (
        <form action={`/search/${toParam(state.job_type)}`}>
            <select className="ddselect" onChange={e => handleChange(e.target.value)} defaultValue={state.job_type} style={{ borderRadius: 100, height: 50, marginBottom: 10 }}>
                <option default>Select Position</option>
                {jobTypeOptions}
            </select>
            <input type="text" style={{ backgroundColor: '#fff' }} name="location" placeholder="zip code" className="ico-02" />
            <button style={{
                float: 'right', paddingRight: 35
            }}>
                <i className="fa fa-search"></i>
            </button>
        </form>
    )
}

export default JobHome;