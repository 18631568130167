import * as React from 'react';
import { Editor, RichUtils, Modifier } from 'draft-js';
import { EditorState, ContentState, convertToRaw } from "draft-js"
import htmlToDraft from "html-to-draftjs"

import 'draft-js/dist/Draft.css';

type EditorProps = {
  editorState: any;
  onChangeDescription: any;
}

export const initialEditorState = initialDescription => {
  if (initialDescription) {
    const contentBlock = htmlToDraft(initialDescription)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      return EditorState.createWithContent(contentState)
    }
  }
  return EditorState.createEmpty()
}

class RichTextareaEditor extends React.Component<EditorProps> {
  focus: () => any;
  handleKeyCommand: any;
  mapKeyToEditorCommand: any;
  toggleBlockType: any;
  toggleInlineStyle: any;
  handlePastedText: any;

  constructor(props) {
    super(props);
    this.focus = () => this.refs.editor.focus();

    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    this.handlePastedText = this._handlePastedText.bind(this);
  }


  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this._onChangeDescription(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, this.props.editorState, 4);
      if (newEditorState !== this.props.editorState) {
        this._onChangeDescription(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this._onChangeDescription(
      RichUtils.toggleBlockType(
        this.props.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle(inlineStyle) {
    this._onChangeDescription(
      RichUtils.toggleInlineStyle(
        this.props.editorState,
        inlineStyle
      )
    );
  }

  _onChangeDescription = e => {
    const { onChangeDescription } = this.props;
    onChangeDescription(e);
  }

  _handlePastedText(text: string, html?: string) {
    // Remove all unicode characters, bullets, and similar
    const cleanText = text.replace(/[\u200B-\u200D\uFEFF]/g, '');

    // Convert all content to escaped characters
    const escapedText = cleanText.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });

    // Remove any & entities like #10625;
    // If there is a space that was following, remove it too
    const cleanEscapedText = escapedText.replace(/&#[0-9]+; /g, '');
    const cleanEscapedText2 = cleanEscapedText.replace(/&#[0-9]+;/g, '');

    // Convert back
    const unescapedText = cleanEscapedText2.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });

    const newContent = Modifier.replaceText(
      this.props.editorState.getCurrentContent(),
      this.props.editorState.getSelection(),
      unescapedText
    );

    // Combine with existing editor state
    const newEditorState = EditorState.push(
      this.props.editorState,
      newContent,
      'insert-fragment'
    );

    this.props.onChangeDescription(newEditorState);
    return 'handled';
  }

  render() {
    const { editorState } = this.props;
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div className="RichEditor-root form-control">
        <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
        <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
        <div className={className} onClick={this.focus}>
          <Editor
            stripPastedStyles={true}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            // keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this._onChangeDescription}
            placeholder="Add Description..."
            ref="editor"
            spellCheck={true}
          // handlePastedText={this.handlePastedText}
          />
        </div>
      </div>
    );
  }
}

// Other Stuff

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class StyleButton extends React.Component<{
  onToggle: any, style: any, active: any, label: any
}> {
  onToggle: (e: any) => void;

  constructor(props) {
    super(props);
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  // { label: 'H1', style: 'header-one' },
  // { label: 'H2', style: 'header-two' },
  // { label: 'Header 1', style: 'header-three' },
  { label: 'Header 1', style: 'header-four' },
  { label: 'Header 2', style: 'header-five' },
  { label: 'Header 3', style: 'header-six' },
  { label: 'Quote', style: 'blockquote' },
  { label: 'Bullet List', style: 'unordered-list-item' },
  { label: 'Numbered List', style: 'ordered-list-item' },
  // { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};


export default RichTextareaEditor