import * as React from 'react';
// import Select from "react-select"

// Context
import { ProfileFormContext } from '../ProfileEditForm';
import JobTypes from './FormComponents/JobTypes';
import { ACTIONS } from './ProfileFormReducer';

export default function Availability() {
  const [state, dispatch] = React.useContext(ProfileFormContext);

  // Set Field
  const setField = (e) => dispatch({
    type: ACTIONS.SET_FIELD,
    payload: { [`${e.target.name}`]: e.target.value }
  })

  // Add or Remove position_type_ids from state.profile.position_type_ids
  const togglePositionType = (e) => {
    const position_type_id = parseInt(e.target.value)
    const position_type_ids = state.profile.position_type_ids

    if (position_type_ids.includes(position_type_id)) {
      const index = position_type_ids.indexOf(position_type_id)
      position_type_ids.splice(index, 1)
    } else {
      position_type_ids.push(position_type_id)
    }

    setField({ target: { name: 'position_type_ids', value: position_type_ids } })
  }

  const { hiring_types } = state.options

  return (
    <div>
      <div className="py-3">
        <h4>Position Availability</h4>
        <JobTypes />
      </div>

      {/* Hiring Availability Checkboxes */}
      <div className="form-group">
        <label htmlFor="hiring_types">Hiring Availability</label>
        <div className="form-check">
          {hiring_types.map((h) => (
            <div key={h.id} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={`position_type_${h.id}`}
                name="position_type_ids"
                value={h.id}
                onChange={togglePositionType}
                checked={state.profile.position_type_ids.includes(h.id)}
              />
              <label className="form-check-label" htmlFor={`position_type_${h.id}`}>{h.name}</label>
            </div>
          ))}
        </div>
      </div>

      {/* Hourly Rate */}
      <div className="form-group">
        <label htmlFor="hourly_rate">Desired Hourly Rate</label>
        <input
          type="number"
          className="form-control"
          id="hourly_rate"
          name="hourly_rate"
          value={state.profile.hourly_rate}
          onChange={setField}
        />
      </div>

      {/* Max Traveling Distance */}
      <div className="form-group">
        <label htmlFor="maximum_traveling_distance">Maximum Traveling Distance (Miles)</label>
        <input
          type="number"
          className="form-control"
          id="maximum_traveling_distance"
          name="maximum_traveling_distance"
          value={state.profile.maximum_traveling_distance}
          onChange={setField}
        />
      </div>
    </div>
  );
}
