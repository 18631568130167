import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

const HelpIconWithTooltip = ({ style, message }) => {
  const [messages] = useState({
    ['Full Service Staffing']: (
      <div>
        <h5>What is Full Service Staffing?</h5>
        <p>This is concierge-level service so you won't lift a finger beyond giving us a call. </p>
        <p>Experience seamless hiring with DirectDental's staffing service package, providing you with a dedicated recruiter, a significant boost in job visibility, a surge of applicants, a professionally crafted job description, and a featured employer profile to attract top talent.</p>
        <ul className="ps-4">
          <li>Dedicated Recruiter</li>
          <li>Premium Job Post</li>
          <li>Highest Priority</li>
          <li>More Applicants</li>
          <li>Custom Job Description</li>
          <li>Featured Employer Profile</li>
          <li>Candidate Screening</li>
          <li>License verification</li>
          <li>Temp Staff Support</li>
        </ul>
      </div>
    ),
    ['Boosted Job']: (
      <div>
        <h5>What is a Boosted Job?</h5>
        <p>Experience seamless hiring with DirectDental's staffing service package, providing you with a dedicated recruiter, a significant boost in job visibility, a surge of applicants, a professionally crafted job description, and a featured employer profile to attract top talent.</p>
        <ul className="ps-4">
          <li>Dedicated Recruiter</li>
          <li>Premium Job Post</li>
          <li>Highest Priority</li>
          <li>More Applicants</li>
          <li>Custom Job Description</li>
          <li>Featured Employer Profile</li>
        </ul>
      </div>
    ),
    ['pay_transparency']: (
      <div>
        <h5>Pay Required</h5>
        <p>“Pay Transparency Laws” now require pay to be included on job postings.</p>
      </div>
    )
  });

  // Check if message is in messages
  const messageExists = messages.hasOwnProperty(message);

  return (
    <React.Fragment>
      {messageExists && (
        <h4 className="top-right" style={{ ...style }}>
          <i
            className="far fa-question-circle"
            data-tip
            data-for={`help-${message}`}
          />
        </h4>
      )}
      <ReactTooltip
        id={`help-${message}`}
        type="info"
        effect="solid"
        place="bottom"
        className="dashTooltip"
      >
        {messages[`${message}`]}
      </ReactTooltip>
    </React.Fragment>
  );
};

export default HelpIconWithTooltip;
