import React, { useEffect, useState } from "react";

import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import "@sendbird/uikit-react/dist/index.css";

import SBChatModal from "./SBChatModal";

import { useStore } from "@nanostores/react";
import { SBChatShow, SBChatUnread, SBChatChannel } from "./SBChatStore";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function SBChat({
  avatar_url,
  nickname,
  profile_id,
  app_id,
  activeOnMobile,
}) {
  const unread = useStore(SBChatUnread);
  const show = useStore(SBChatShow);
  const channel = useStore(SBChatChannel);
  const [isActive, setIsActive] = useState(!document.hidden);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  let initialProvider;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!isMobile) {
        setIsActive(!document.hidden);
      }
    };

    const handleBeforeUnload = () => {
      if (initialProvider && typeof initialProvider.disconnect === "function") {
        initialProvider.disconnect();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Initial unread count when component mounts
    if (isActive && !isMobile) {
      initialProvider = SBProvider({
        appId: app_id,
        userId: profile_id,
        nickname: nickname,
      });
      if (initialProvider && typeof initialProvider.connect === "function") {
        initialProvider.connect().then(() => {
          if (
            initialProvider &&
            typeof initialProvider.loadAllChannels === "function"
          ) {
            initialProvider.loadAllChannels().then((channels: any[]) => {
              let totalUnread = 0;
              channels.forEach((channel: { unreadMessageCount: number }) => {
                totalUnread += channel.unreadMessageCount;
              });
              SBChatUnread.set(totalUnread);
            });
          }
        });
      }
    }

    // Set initial unread count
    SBChatUnread.set(unread);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [app_id, profile_id, nickname, unread, isActive, isMobile]);

  const setShow = () => {
    SBChatShow.set(true);
  };

  return (
    <>
      {/* Chat Bubble Button with red dot indicator for unread messages */}
      <BrowserView>
        <a
          onClick={setShow}
          className="nav-link"
          style={{ color: "#085ec8", position: "relative", cursor: "pointer" }}
        >
          {/* Chat Bubble Icon - FontAwesome */}
          <i className="fas fa-comment-alt" style={{ fontSize: 15 }}></i>
          &nbsp;Chat
          {/* Red dot indicator - Displayed if unread messages exist */}
          {unread > 0 && (
            <span
              style={{
                position: "absolute",
                top: "-4px",
                right: "-4px",
                background: "red",
                borderRadius: "50%",
                width: "8px",
                height: "8px",
              }}
            />
          )}
        </a>
      </BrowserView>
      <MobileView>
        <a
          onClick={setShow}
          className="nav-link d-inline-block"
          style={{
            color: "#085ec8",
            position: "relative",
            width: "28px",
            height: "28px",
          }}
        >
          {/* Chat Bubble Icon - FontAwesome */}
          <i
            className="fas fa-comment-alt"
            style={{
              fontSize: 15,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></i>
          {/* Red dot indicator - Displayed if unread messages exist */}
          {unread > 0 && (
            <span
              style={{
                position: "absolute",
                top: "-4px",
                right: "-4px",
                background: "red",
                borderRadius: "50%",
                width: "8px",
                height: "8px",
              }}
            />
          )}
        </a>
      </MobileView>

      {((activeOnMobile && isMobile) ||
        (!activeOnMobile && isActive && !isMobile)) && (
        <SBProvider appId={app_id} userId={profile_id} nickname={nickname}>
          <SBChatModal
            avatar_url={avatar_url}
            nickname={nickname}
            profile_id={profile_id}
            app_id={app_id}
            initialChannelUrl={channel ? channel : null}
          />
        </SBProvider>
      )}
    </>
  );
}
