import './Common-poly'
import * as React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

const Plans = (props) => {
    const { plans, initialIndex = 1 } = props
    const onSelect = i => { }
    console.log(plans)

    return (
        <Tabs className="sub_plans" defaultIndex={initialIndex} onSelect={(index, lastIndex, event) => onSelect(index)}>
            <SavingsLabels plans={plans} />
            <TabList>
                {plans.map(plan => <Tab>{plan.name}</Tab>)}
            </TabList>
            {plans.map(plan => (<TabPanel>{Plan(plan)}</TabPanel>))}
        </Tabs>
    )
}

const SavingsLabels = ({ plans }) => (
    <div className="plan-savings-labels">
        {plans.map(plan =>
            <div className="plan-savings-label-container">
                {plan.label && (<span className="plan-savings-label">{plan.label}</span>)}
            </div>
        )}
    </div>
)


const Plan = ({ name, price, id, savings_price, features, trial, product_name, tagline, savings }) => {
    const plan_features = features.split('|')
    return (
        <div className="plan-container">
            <div className="plan color-1 dropshadow">
                <div className="plan-price">
                    <h3 className="promo-yellow">{name}</h3>
                    {savings_price !== price && (
                        <small className="billing-savings">{savings}% Savings!</small>
                    )}
                    <span className="plan-currency">$ </span>
                    <span className="value">{savings_price}</span> / mo
                    <small className="billing-term">
                        {savings_price !== price && (
                            <React.Fragment>(${price} Billed {name.includes('ly') ? name : `${name}ly`})</React.Fragment>
                        )}
                    </small>
                    <p className="promo-yellow">
                        <small>{product_name}</small>
                    </p>
                </div>
                <div className="plan-features landing">
                    <ul>
                        {tagline && (<li className="centered"><strong>{tagline}</strong></li>)}
                        {plan_features.map(ft => <li><i className="fa fa-check"></i> {ft}</li>)}
                    </ul>
                    <a className="stripe-button-el" href={`/plans/${id}`}>
                        <span>{trial ? "Start 14 Day Free Trial" : "Get Started"}</span>
                    </a>
                </div>
            </div>
        </div >
    )
}

export default Plans