import * as React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProfileJobTypes from "./ProfileJobTypes";
import ProfileHiringTypes from "./ProfileHiringTypes";
import ProfileExperience from "../FormComponents/ProfileExperience";
import axios from "axios";
import InputMask from "react-input-mask";

const initialState = {
  processing: false,
  experience_selected: false,
  experience: false,
  tabIndex: 0,
  experience_array: [],
  profile: {
    first_name: "",
    last_name: "",
    phone: "",
    zip: "",
    position_type_ids: [],
    job_type_ids: [],
    experiences_attributes: [],
    resume: "",
    sms_optin: true,
  },
  validation_errors: {
    first_name: false,
    last_name: false,
    phone: false,
    zip: false,
    positions: false,
    availability: false,
    experience_or_resume: false,
  },
};

const checkForErrors = (tabIndex, validation_errors) => {
  let errors = false;
  const {
    first_name,
    last_name,
    phone,
    zip,
    positions,
    availability,
    experience_or_resume,
  } = validation_errors;

  switch (tabIndex) {
    case 1:
      errors = first_name || last_name || phone || zip;
      break;

    case 2:
      errors = positions;
      break;

    case 3:
      errors = availability;
      break;

    case 4:
      errors = experience_or_resume;
      break;

    default:
      errors = false;
      break;
  }

  return errors;
};

const ProfileForm = ({ job_types, hiring_types, authenticity_token }) => {
  const [state, setState] = React.useState({ ...initialState });

  const selectTab = (tabIndex) => {
    let errors = false;

    const validation_fields = Object.keys(state.validation_errors);

    let _cv;

    validation_fields.forEach((f) => {
      _cv = checkValidations({
        profile: state.profile,
        field: f,
        validation_errors: state.validation_errors,
      });
    });

    const validation_errors = _cv;

    setState((state) => ({ ...state, validation_errors }));

    errors = checkForErrors(tabIndex, validation_errors);

    if (!errors)
      setState((state) => ({
        ...state,
        tabIndex,
        validation_errors: initialState.validation_errors,
      }));
  };

  const unselectExperienceAndResume = () => {
    console.log("Resetting experience and resume");
    setState((state) => ({
      ...state,
      experience: false,
      experience_selected: false,
    }));
  };

  const selectExperience = () =>
    setState((state) => ({
      ...state,
      experience: true,
      experience_selected: true,
    }));

  const selectResume = () =>
    setState((state) => ({
      ...state,
      experience: false,
      experience_selected: true,
    }));

  const removeMaskCharacters = (maskedString) => {
    return maskedString == null ? "" : maskedString.replace(/\D+/g, "");
  };

  const updateProfile = (event) => {
    const { name, value } = event.target;
    let val;
    if (name == "zip") {
      const re = /^[0-9\b]+$/;
      if ((value == "" || re.test(value)) && value.length < 6) val = value;
    } else {
      val = value;
    }

    const profile = { ...state.profile, [name]: val };
    setState((state) => ({
      ...state,
      profile,
      validation_errors: checkValidations({
        profile,
        field: name,
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const updateProfileCheckboxes = (name, value) => {
    let fieldName;
    const profile = { ...state.profile, [name]: value };

    switch (name) {
      case "job_type_ids":
        fieldName = "positions";
        break;
      case "position_type_ids":
        fieldName = "availability";
        break;

      default:
        break;
    }

    setState((state) => ({
      ...state,
      profile,
      validation_errors: checkValidations({
        profile,
        field: fieldName,
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const updateExperience = (experience) => {
    setState((state) => ({
      ...state,
      experience_array: experience,
      profile: {
        ...state.profile,
        experiences_attributes: experience.map((exp) => ({
          employer: exp.employer,
          title: exp.title,
          start_date: exp.start_date,
          end_date: exp.end_date,
          description: exp.description,
          current: exp.current,
        })),
      },
      validation_errors: checkValidations({
        profile: state.profile,
        field: "experience_or_resume",
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const handleFileChange = (file) => {
    const profile = { ...state.profile, resume: file[0] };
    setState((state) => ({
      ...state,
      profile,
      validation_errors: checkValidations({
        profile,
        field: "experience_or_resume",
        validation_errors: state.validation_errors,
      }),
    }));
  };

  const setProcessing = (processing) =>
    setState((state) => ({ ...state, processing }));

  const submitForm = () => {
    const { profile } = state;
    sendProfile({
      profile,
      setProcessing,
      authenticity_token,
      tabIndex: state.tabIndex,
      checkValidations,
      state,
      callback: () => setProcessing(true),
    });
  };

  // var Spinner = require("react-spinkit")

  if (state.processing) {
    return (
      <center>
        <h3>Processing...</h3>
      </center>
    );
  } else {
    return (
      <Tabs
        selectedIndex={state.tabIndex}
        onSelect={selectTab}
        style={{ maxWidth: 600, margin: "auto" }}
      >
        <TabList style={{ marginBottom: 30, marginTop: 30 }}>
          <Tab>General Info</Tab>
          <Tab>Positions</Tab>
          <Tab>Availability</Tab>
          <Tab>Experience</Tab>
        </TabList>

        {/* General Information */}
        <TabPanel>
          {/* First Name */}
          <p>
            <input
              type="text"
              className="search-field form-control"
              name="first_name"
              id="profile_first_name"
              placeholder="First Name"
              value={state.profile.first_name}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.first_name}
              message="*Required"
            />
          </p>

          {/* Last Name */}
          <p>
            <input
              type="text"
              className="search-field form-control"
              name="last_name"
              id="profile_last_name"
              placeholder="Last Name"
              value={state.profile.last_name}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.last_name}
              message="*Required"
            />
          </p>

          {/* Phone */}
          <p>
            <InputMask
              name="phone"
              className="search-field form-control"
              placeholder="Phone"
              type="text"
              // mask="+9 (999) 999-9999"
              // maskChar=" "
              value={state.profile.phone}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.phone}
              message="*Required"
            />
          </p>

          {/* Zip Code */}
          <p>
            <input
              type="text"
              className="search-field form-control"
              name="zip"
              id="profile_zip"
              placeholder="Zip Code"
              value={state.profile.zip}
              onChange={(e) => updateProfile(e)}
            />
            <ValidationError
              display={state.validation_errors.zip}
              message="*Required"
            />
          </p>

          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => selectTab(1)}
            >
              Continue
            </a>
          </div>
        </TabPanel>

        {/* Positions */}
        <TabPanel>
          <ProfileJobTypes
            selected={state.profile.job_type_ids}
            job_types={job_types}
            update={updateProfileCheckboxes}
          />
          <ValidationError
            display={state.validation_errors.positions}
            message="Select all that apply."
          />
          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink reversed btn btn-dark pull-left"
              onClick={() => selectTab(0)}
            >
              Back
            </a>
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => selectTab(2)}
            >
              Continue
            </a>
          </div>
        </TabPanel>

        {/* Availability */}
        <TabPanel>
          <ProfileHiringTypes
            selected={state.profile.position_type_ids}
            hiring_types={hiring_types}
            update={updateProfileCheckboxes}
          />

          {/* Checkbox - Opt-In to receive text messages about jobs */}
          <p>
            <label htmlFor="profile_sms_optin" style={{ marginLeft: 10 }}>
              <input
                type="checkbox"
                name="sms_optin"
                id="profile_sms_optin"
                checked={state.profile.sms_optin}
                onChange={(e) =>
                  updateProfileCheckboxes(e.target.name, e.target.checked)
                }
                style={{ marginRight: 10 }}
              />
              Opt-In to receive an SMS when a dental office is interested in
              your application or temp availability.
            </label>
            {/* When unchecked, display red text saying you wont receive any messages */}
            {!state.profile.sms_optin && (
              <span style={{ color: "red", marginLeft: 10, display: "block" }}>
                By opting out of SMS communications, you won't receive any SMS
                alerts when dental offices invite you to apply or temp.
              </span>
            )}
          </p>

          <ValidationError
            display={state.validation_errors.availability}
            message="Select all that apply."
          />
          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink reversed btn btn-dark pull-left"
              onClick={() => selectTab(1)}
            >
              Back
            </a>
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => {
                unselectExperienceAndResume();
                selectTab(3);
              }}
            >
              Continue
            </a>
          </div>
        </TabPanel>

        {/* Experience */}
        <TabPanel>
          <div style={{ margin: "30px auto", textAlign: "center" }}>
            {!state.experience_selected && (
              <React.Fragment>
                <a
                  href="#"
                  className="button outline btn btn-primary"
                  onClick={selectExperience}
                >
                  Enter Experience
                </a>
                <span style={{ display: "block", margin: "20px 5px" }}>Or</span>
                <a
                  href="#"
                  className="button outline btn btn-primary"
                  onClick={selectResume}
                >
                  Upload Resume
                </a>
              </React.Fragment>
            )}
            {state.experience_selected && state.experience && (
              <ProfileExperience
                experience={state.experience_array}
                update={updateExperience}
              />
            )}
            {state.experience_selected && !state.experience && (
              <label
                htmlFor="fileUpload"
                class="fileUpload"
                style={{ marginLeft: 20 }}
              >
                {!state.profile.resume && <>Choose File</>}
                <input
                  type="file"
                  id="fileUpload"
                  onChange={(e) => handleFileChange(e.target.files)}
                />
                {state.profile.resume && (
                  <>Selected File {state.profile.resume.name}</>
                )}
              </label>
            )}
            <br />
            <br />
            <p>
              <ValidationError
                display={state.validation_errors.experience_or_resume}
                message="Upload your resume or enter your experience."
              />
            </p>
          </div>
          <div
            className="formbuttons"
            style={{ margin: "10px auto", textAlign: "center" }}
          >
            <a
              href="#"
              className="button pink reversed btn btn-dark pull-left"
              onClick={() => {
                unselectExperienceAndResume();
                selectTab(2);
              }}
            >
              Back
            </a>
            <a
              href="#"
              className="button pink btn btn-primary pull-right"
              onClick={() => submitForm()}
            >
              Submit
            </a>
          </div>
        </TabPanel>
      </Tabs>
    );
  }
};

const ValidationError = ({ display, message }) => {
  return (
    <React.Fragment>
      {display && <span style={{ color: "#555" }}>{message}</span>}
    </React.Fragment>
  );
};

const checkValidations = ({ field, profile, validation_errors }) => {
  let errors = validation_errors;

  switch (field) {
    // Should not be blank
    case "first_name":
    case "last_name":
      errors[field] = profile[field].length < 1;
      break;
    case "phone":
      errors[field] = profile[field].length < 1 || profile[field].length < 10;
      break;
    case "zip":
      errors[field] = profile[field].length < 1 || profile[field].length < 5;
      break;

    // Should have a value
    case "positions":
      errors[field] = profile["job_type_ids"].length < 1;
      break;
    case "availability":
      errors[field] = profile["position_type_ids"].length < 1;
      break;
    case "experience_or_resume":
      let allBlank = true;

      if (profile.experiences_attributes.length > 0) {
        allBlank = profile.experiences_attributes.every((exp) =>
          Object.keys(exp).every((k) => exp[k] == ""),
        );
      }

      errors[field] =
        profile.resume.length < 1 &&
        (profile.experiences_attributes.length < 1 || allBlank);

      break;
    default:
      break;
  }

  return errors;
};

const sendProfile = ({
  profile,
  setProcessing,
  authenticity_token,
  tabIndex,
  checkValidations,
  state,
}) => {
  let errors = false;

  const validation_fields = Object.keys(state.validation_errors);

  let _cv = validation_fields.map((f) => {
    return checkValidations({
      profile: state.profile,
      field: f,
      validation_errors: state.validation_errors,
    });
  });

  errors = validation_fields.some((f) => _cv[_cv.length - 1][f] == true);

  if (!errors) {
    setProcessing(true);
    let formData = new FormData();
    console.log("state", state);
    Object.keys(profile).forEach((key) => {
      const isArray = [
        "position_type_ids",
        "job_type_ids",
        "experiences_attributes",
      ].includes(key);
      // Resume
      if (key == "resume" && profile[key] != "") {
        formData.append(`profile[${key}]`, profile[key], profile[key].name);
      } else if (isArray) {
        // Positions
        if (key == "position_type_ids") {
          profile[key].forEach((pk) =>
            formData.append(`profile[${key}][]`, pk),
          );
        }
        // Jobs
        if (key == "job_type_ids") {
          profile[key].forEach((pk) =>
            formData.append(`profile[${key}][]`, pk),
          );
        }
        // Experience
        if (key == "experiences_attributes") {
          profile.experiences_attributes.forEach((exp) => {
            formData.append(
              `profile[experiences_attributes][][employer]`,
              exp["employer"],
            );
            formData.append(
              `profile[experiences_attributes][][title]`,
              exp["title"],
            );
            formData.append(
              `profile[experiences_attributes][][start_date]`,
              exp["start_date"],
            );
            formData.append(
              `profile[experiences_attributes][][end_date]`,
              exp["end_date"],
            );
            formData.append(
              `profile[experiences_attributes][][description]`,
              exp["description"],
            );
            formData.append(
              `profile[experiences_attributes][][current]`,
              exp["current"],
            );
          });
        }
      } else {
        formData.append(`profile[${key}]`, profile[key]);
      }
    });

    const options = {
      headers: {
        "X-CSRF-Token": authenticity_token,
        "Content-type": "multipart/form-data",
      },
    };

    axios
      .post("/profiles", formData, options)
      .then((res) => {
        const url = res.request.responseURL;
        if (url) window.location.href = url;
      })
      .catch((err) => {
        setProcessing(false);
      });
  }
};

export default ProfileForm;
