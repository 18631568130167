import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import LocationsToggle from './LocationsToggle';

export default function Locations({
  locations,
  form_authenticity_token,
  subscriptions,
  total_locations = 1,
  plans,
  update_subscription_path,
  update_active_practice,
  slot_plan = false
}) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showLocations, setShowLocations] = React.useState(false);
  const handleCloseLocation = () => setShowLocations(false);
  const [locationsState, setLocationsState] = React.useState(locations.map(location => ({
    ...location, active: location.active || false
  })));
  const formRef = React.useRef(null);

  // Get all of the "plan" from subscriptions
  const plans_array = subscriptions.map(subscription => subscription.plan);

  // Get all of the plans where stripe_plan is in plans_array
  const plans_array_stripe_plan = plans.filter(plan => plans_array.includes(plan.stripe_plan));

  // Map plans_array_stripe_plan and add subscriptions.quantity where subscription.plan == plan.stripe_plan
  const plans_with_quantity = plans_array_stripe_plan.map(plan => {
    const subscription = subscriptions.find(subscription => subscription.plan === plan.stripe_plan);
    return {
      ...plan,
      quantity: subscription ? subscription.quantity : 0,
      total: plan.price + (plan.graduated_price * subscription.quantity),
      sub: subscription.sub_id
    };
  });

  // Primary Plan = last plans_with_quantity[0]
  const primary_plan = plans_with_quantity[plans_with_quantity.length - 1];

  // Number of locations where location.active
  const active_locations = locations.filter(location => location.active).length;

  const onLocationUpdateAndSubmit = (e) => {
    const form = formRef.current;
    form.submit();
  }

  // Function to go to href="/practices/new"
  const goToNewPractice = () => {
    window.location.href = '/practices/new';
  }

  // Console log all props
  React.useEffect(() => {
    console.log(locations, subscriptions, total_locations, active_locations, plans_with_quantity, primary_plan);
  }, [locations, subscriptions, total_locations, active_locations, plans_with_quantity]);

  return (
    <>
      <div className="col-12">
        <h1>Manage Your Locations</h1>
        {primary_plan && !slot_plan && <p>You are using {active_locations} of {total_locations} location(s).</p>}
        <LocationTable
          locations={locations}
          sub={primary_plan}
          primary_plan={primary_plan}
          form_authenticity_token={form_authenticity_token}
          update_subscription_path={update_subscription_path}
          extra_locations={(total_locations - active_locations > 0)}
          setShowLocations={setShowLocations}
        />
        {/* <a className="btn btn-dd">Add Practice</a> */}
        {primary_plan && <LocationModal
          show={show}
          handleClose={handleClose}
          locations={locations}
          upsellPrice={primary_plan.graduated_price}
          interval={primary_plan.term}
          update_subscription_path={update_subscription_path}
          form_authenticity_token={form_authenticity_token}
          primary_plan={primary_plan}
        />}
        <LocationsToggle
          setLocationsStateFunction={setLocationsState}
          limit={total_locations}
          showLocations={showLocations}
          handleCloseLocation={handleCloseLocation}
          locations={locations}
          buttonText="Update"
          buttonFunction={onLocationUpdateAndSubmit}
        />
      </div>
      <form action={update_active_practice} method="post" ref={formRef}>
        <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
        {locationsState.map(location => {
          return (
            <>
              <input type="hidden" name="locations[][id]" value={location.id} />
              <input type="hidden" name="locations[][active]" value={location.active} />
            </>
          )
        })}
      </form>
      <div className="col-12">
        <button className="btn btn-dd-small" onClick={goToNewPractice}>Add Location</button>
        {/* {(active_locations < total_locations) ?
          <button className="btn btn-dd" onClick={goToNewPractice}>Add Location</button> :
          <button className="btn btn-dd" onClick={() => setShow(true)}>Add Location</button>
        } */}
        {!slot_plan &&
          <strong>
            <a className="pull-right my-4" href="#active" onClick={() => setShowLocations(true)}>Change Active Locations</a>
          </strong>
        }
      </div>
    </>
  );
}

function LocationTable({
  locations,
  sub,
  primary_plan,
  form_authenticity_token,
  update_subscription_path,
  extra_locations = false,
  setShowLocations
}) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col"><i className="fa fa-file-text"></i> Location Name</th>
          <th scope="col"><i className="fa fa-user"></i> Location Membership Status</th>
          <th scope="col"><i className="fa fa-cog"></i> Actions</th>
        </tr>
      </thead>
      <tbody>
        {locations.map(location =>
          <Location
            location={location}
            sub={sub}
            primary_plan={primary_plan}
            form_authenticity_token={form_authenticity_token}
            update_subscription_path={update_subscription_path}
            extra_locations={extra_locations}
            setShowLocations={setShowLocations}
          />
        )}
      </tbody>
    </table>
  )
}

function Location({
  location,
  sub,
  primary_plan,
  form_authenticity_token,
  update_subscription_path,
  extra_locations = false,
  setShowLocations
}) {
  return (
    <tr style={{ lineHeight: 3 }}>
      <td scope="row">
        <strong><a href={`/practices/${location.id}`}>{location.name}</a></strong>
      </td>
      <td>
        {!sub &&
          <a href="/get_started">Start a Membership</a>
        }
        {(sub && location.active) &&
          <>
            Active
          </>
        }
        {(sub && !location.active) &&
          (extra_locations ?
            <a href="#" onClick={() => setShowLocations(true)}>Activate Location</a> :
            <LocationFormPurchase
              location_id={location.id}
              primary_plan={primary_plan}
              form_authenticity_token={form_authenticity_token}
              update_subscription_path={update_subscription_path}
            />)
        }
      </td>
      <td>
        <a href={`/jobs/new?practice=${location.id}`}>
          <i className="fa fa-newspaper"></i> Post Job
        </a>
      </td>
      <td>
        <a href={`/practices/${location.id}/edit`}>
          <i className="fa fa-edit"></i> Edit Location
        </a>
      </td>
    </tr>
  )
}

function LocationFormPurchase({
  location_id,
  primary_plan,
  form_authenticity_token,
  update_subscription_path
}) {
  return (
    <form action={update_subscription_path} method="post" className="form-inline">
      <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
      <input type="hidden" name="subscription" value={primary_plan.sub} />
      <input type="hidden" name="price" value={primary_plan.stripe_plan} />
      <input type="hidden" name="new_quantity" value={primary_plan.quantity + 1} />

      <input type="hidden" name="location_id" value={location_id} />
      <input type="hidden" name="return_url" value={`/practices`} />
      <input type="submit" className="btn btn-dd" value="Purchase Add-On Membership" style={{ fontSize: 16, padding: '3px 10px', margin: 0 }} />
    </form>
  )
}

function LocationModal({
  show,
  handleClose,
  locations,
  upsellPrice,
  interval,
  update_subscription_path,
  form_authenticity_token,
  primary_plan
}) {
  const [locationsState, setLocationsState] = React.useState(locations);

  // Active Location Modal
  const setLocationStateActive = (locationId, active) => {
    const newLocations = locationsState.map(location => {
      if (location.id === locationId) {
        location.active = active;
      }
      return location;
    });
    setLocationsState(newLocations);
  }

  const locationsActive = locationsState.map(location => ({
    id: location.id,
    active: location.active
  }));

  React.useEffect(() => {
    console.log(locationsActive);
  }, [locationsActive]);

  return (
    <>
      {/* Add Location */}
      <Modal show={show} onHide={handleClose} className="ddModal mt-5">
        <Modal.Header closeButton>
          <Modal.Title>Add New Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Additional locations can be added for <u>${upsellPrice} {interval}</u>.</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {/* Form */}
          <form action={update_subscription_path} method="post" className="form-inline">
            <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
            <input type="hidden" name="subscription" value={primary_plan.sub} />
            <input type="hidden" name="price" value={primary_plan.stripe_plan} />
            <input type="hidden" name="new_quantity" value={primary_plan.quantity + 1} />
            <input type="hidden" name="return_url" value={`/practices/new`} />
            <input type="submit" className="btn btn-dd" value="Continue" />
          </form>
        </Modal.Footer>
      </Modal>
    </>
  )
}

