import './Common-poly'
import 'whatwg-fetch'
import React from "react"
import EmbedForm from './Jobs/EmbedForm'
import moment from 'moment';
import { Modal } from 'react-bootstrap';

import { dotStream } from 'ldrs'

dotStream.register()

const ReadablePositionType = (pt) => {
  if (pt === 'fulltime') return "Full Time"
  if (pt === 'parttime') return "Part Time"
  if (pt === 'temp') return "Temp"
  if (pt === 'longtermtemp') return "Long Term Temp"
}

function renderOption(props, option, snapshot, className) {
  console.log(props, option, snapshot, className)
  return (
    <div {...props} className="d-flex flex-row p-2" type="button" style={{ height: 'auto', paddingTop: 5, paddingBottom: 5 }}>
      <img src={option.avatar} className="img-fluid rounded-circle col-2 p-2" alt="" />
      {/* Vertically align */}
      <div className="col-10 text-left d-flex flex-column justify-content-center">
        <div>{option.title} - {option.practice}</div>
        <div>{option.date}</div>
      </div>
    </div>
  );
}

const ExistingJobSelect = ({ jobs, existingJob, setJob, practices = [] }) => {
  // const options = jobs.map(job => {
  //     // Select practice from practices where practice.id === job.practice_id
  //     const practice = practices.find(p => p.id === job.practice_id)

  //     return {
  //         title: job.job_type.title,
  //         avatar: practice.avatar_url,
  //         practice: practice.name,
  //         date: `${ReadablePositionType(job.position_type)}, ${moment(job.position_type === 'temp' ? job.temp_date : job.published_at).format("MMM Do YYYY")}`,
  //         value: `${job.id}`
  //     }
  // });

  return (
    <>
      {/* <SelectSearch
                options={options}
                value={existingJob}
                name="language"
                placeholder="Choose Your Job"
                renderOption={renderOption}
            /> */}

      <select className="form-select" value={existingJob} onChange={setJob}>
        {jobs.map(job => <JobOption key={job.id} job={job} />)}
      </select>
    </>
  );
}

const JobOption = ({ job }) => (
  <option value={`${job.id}`}>
    {job.job_type.title} at {job.practice.name} ({ReadablePositionType(job.position_type)}, {moment(job.position_type === 'temp' ? job.temp_date : job.published_at).format("MMM Do YYYY")})
  </option>
);

const ExistingJobButton = ({ requestTemp }) => (
  <a className="btn btn-primary btn-rounded" onClick={requestTemp}>
    <i className="fa fa-envelope"></i> Select Job
  </a>
)

const NewJobButton = ({ openNewJob }) => (
  <a className="btn btn-primary btn-rounded col-6" onClick={openNewJob}>
    <i className="fa fa-envelope"></i> Create a New Job
  </a>
)

const ModalWindow = ({ jobs, existingJob, setJob, requestTemp, openNewJob, temp, practices = [] }) => {
  return (
    <div>
      {!temp && (
        <h3 style={{ fontSize: 20 }}>
          <center>We will instantly send this candidate a direct message inviting them to apply!</center>
        </h3>
      )}
      {jobs.length > 0 && (
        <div className="behind" style={{ margin: 10 }}>
          <center>
            <div className="form-group">
              <ExistingJobSelect jobs={jobs} existingJob={existingJob} setJob={setJob} practices={practices} /><br />
            </div>
            <ExistingJobButton requestTemp={requestTemp} />
          </center>
        </div>
      )}
      {jobs.length === 0 && (
        <div className="behind" style={{ margin: 10 }}>
          <h3 style={{ fontSize: 20 }}>
            {/* Say Invite if not temp */}
            <center>Create a job to {temp ? "request this temp" : "invite this candidate"}.</center>
          </h3>
          <center>
            <NewJobButton openNewJob={openNewJob} />
          </center>
        </div>
      )}
    </div>
  );
}

export default function RequestProfessional({
  authenticity_token,
  filter_by_job_type_id,
  job_type_group,
  skills,
  job,
  profile_id,
  is_temp_job = true,
  jobFormTags = {},
}) {
  const [state, setState] = React.useState({
    modalOpen: false,
    newJob: false,
    jobs: [],
    existingJob: false,
    requested_job_id: undefined,
    processing: true,
    error: false
  });

  const { requested_job_id, jobs, existingJob, clickedDay, newJob, processing, requestSent, modalOpen } = state;

  const refreshJobs = () => {
    if (is_temp_job) {
      getTempJobs({ setState, filter_by_job_type_id, job_type_group, profile_id });
    } else {
      getJobs({ setState, filter_by_job_type_id, job_type_group, profile_id });
    }
  }

  React.useEffect(() => {
    if (is_temp_job) {
      getTempJobs({ setState, filter_by_job_type_id, job_type_group, profile_id });
    } else {
      getJobs({ setState, filter_by_job_type_id, job_type_group, profile_id });
    }
  }, []);

  // Open Job Form
  const openNewJob = () => setState(s => ({ ...s, newJob: true }));

  // Reset Form
  const resetForm = () => {
    setState({
      modalOpen: true,
      newJob: false,
      jobs: [],
      existingJob: false,
      requested_job_id: undefined,
      processing: true,
      error: false
    });

    refreshJobs()
  }

  const setJob = (event) => setState({
    ...state,
    existingJob: event.target.value
  })

  const requestTempFun = () => {
    setState(state => ({ ...state, processing: true }));
    requestTemp({ setState, authenticity_token, profile_id, state });
    window.scrollTo(0, 100);
  }

  const requestProfFun = () => {
    setState(state => ({ ...state, processing: true }));
    requestProfessional({ setState, authenticity_token, profile_id, state });
    window.scrollTo(0, 100);
  }

  const embedFormOptions = {
    ...jobFormTags,
    profile_id: profile_id,
    temp: is_temp_job
  }

  console.log("embedFormOptions", embedFormOptions)

  // Close Modal
  const closeModal = () => setState({ ...state, modalOpen: false });

  // Open Modal
  const openModal = () => setState({ ...state, modalOpen: true });

  return (
    <React.Fragment>
      {/* Button to open modal */}
      <a className="popup-with-zoom-anim button orange btn btn-primary btn-rounded col-12" onClick={openModal}>
        {is_temp_job ? <i className="fa fa-calendar-alt"></i> : <i className="fa fa-paper-plane"></i>}
        {is_temp_job ? " Request to Temp" : " Invite to Apply"}
      </a>
      <Modal show={modalOpen} onHide={closeModal} centered>
        {/* Close button class btn-close-white */}
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>
            {is_temp_job ? "Request for Temp" : "Invite to Apply"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {newJob && (
            <div>
              <EmbedForm {...embedFormOptions} />
            </div>
          )}
          {processing && (
            <div className="text-center my-5">
              <div className="spinner-border text-primary" role="status">
                <l-dot-stream
                  size="60"
                  speed="2.5"
                  color="black"
                ></l-dot-stream>
                <span className="visually-hidden">Loading...</span>

              </div>
            </div>
          )}
          {requestSent && (
            <center>
              {is_temp_job ?
                <>
                  <h2>Request Sent!</h2>
                  <a className="btn btn-primary btn-rounded" onClick={resetForm}>Send Another</a>
                </> : <>
                  <h2 style={{ fontSize: 20 }}>Invite Sent! Want to invite similar candidates to apply?</h2>
                  <a href={`/jobs/${state.existingJob}`} className="btn btn-primary btn-rounded">View Matched Candidates</a>
                </>
              }
            </center>
          )}
          {!newJob && !processing && !requestSent && (
            <div>
              <ModalWindow
                resetForm={resetForm}
                jobs={jobs}
                existingJob={existingJob}
                setJob={setJob}
                requestTemp={is_temp_job ? requestTempFun : requestProfFun}
                openNewJob={openNewJob}
                temp={is_temp_job}
                practices={jobFormTags.practices}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}


const getJobs = ({ setState, filter_by_job_type_id, job_type_group, profile_id }) => {
  let jobs = [];
  fetch(`/jobs/manage.json?no_closed=1&profile_id=${profile_id}`)
    .then(res => res.json())
    .then(result => {
      result["perm_jobs"].map(r => {
        if (filter_by_job_type_id == true) {
          if (job_type_group.includes(r.job_type.group)) jobs.push(r);
        } else {
          jobs.push(r);
        }
      });

      if (jobs.length > 0) {
        setState(state => ({ ...state, jobs, existingJob: `${jobs[0].id}` }));
      } else {
        setState(state => ({ ...state, jobs }));
      }

      //  processing: false
      setState(state => ({ ...state, processing: false }));
    },
      (error) => {
        // Set state error: true
        setState(state => ({ ...state, error: true }));

        //  processing: false
        setState(state => ({ ...state, processing: false }));

        console.log(error);
      }
    )
}

const getTempJobs = ({ setState, filter_by_job_type_id, job_type_group, profile_id }) => {
  let jobs = [];
  fetch(`/jobs/manage.json?temp=1&no_closed=1&profile_id=${profile_id}`)
    .then(res => res.json())
    .then(result => {
      result["jobs"].map(r => {
        if (filter_by_job_type_id == true) {
          if (job_type_group.includes(r.job_type.group)) jobs.push(r);
        } else {
          jobs.push(r);
        }
      });


      if (jobs.length > 0) {
        setState(state => ({ ...state, jobs, existingJob: `${jobs[0].id}` }));
      } else {
        setState(state => ({ ...state, jobs }));
      }

      //  processing: false
      setState(state => ({ ...state, processing: false }));
    },
      (error) => {
        // Set state error: true
        setState(state => ({ ...state, error: true }));

        //  processing: false
        setState(state => ({ ...state, processing: false }));

        console.log(error);
      }
    )
}

const requestTemp = ({ setState, authenticity_token, profile_id, state }) => {
  let url = `/jobs/${state.existingJob}/request.json`;

  fetch(url, {
    method: 'POST',
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticity_token
    },
    body: JSON.stringify({ temp_requests: [`${profile_id}`] })
  })
    .then(
      result => setState(state => ({ ...state, processing: false, requestSent: true })),
      error => {
        console.log(error)
        setState(state => ({ ...state, processing: false, error: true }))
      }
    )
}

const requestProfessional = ({ setState, authenticity_token, profile_id, state }) => {
  const url = `/jobs/${state.existingJob}/invite.json`;

  fetch(url, {
    method: 'POST',
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticity_token
    },
    body: JSON.stringify({ invitees: [`${profile_id}`] })
  })
    .then(
      result => {
        setState(state => ({ ...state, processing: false, requestSent: true }))
      },
      error => {
        console.log(error)
        setState(state => ({ ...state, processing: false, error: true }))
      }
    )
}
