import * as React from 'react'

export default function UpdateSubscription({ form_path, price, return_url, new_quantity, stripe_plans, stripe_plan, form_authenticity_token, subscription
}) {
  const [quantity, setQuantity] = React.useState(new_quantity)
  const [stripe_plan_state, setStripePlanState] = React.useState(stripe_plan)

  // Store stripe_plans in a state and reject all that include "HS" in the name
  const [filtered_stripe_plans, setFilteredStripePlans] = React.useState(stripe_plans.filter(plan => plan[0].includes("HS") === false))

  const call_now = quantity > 4;
  return (
    <>
      <div className="d-flex align-items-center justify-content-center flex-row flex-wrap text-center">
        <form action={form_path} method="post">
          <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
          <input type="hidden" name="subscription" value={subscription} />
          <input type="hidden" name="price" value={price} />
          <input type="hidden" name="return_url" value={return_url} />
          <div className="form-group">
            <label>Total Locations</label>
            <input type="number" className="form-control my-2" id="quantity" name="new_quantity" value={quantity} onChange={e => setQuantity(e.target.value)} />
          </div>

          <div className="form-group">
            <label>Select Term</label>
            {/* Select Tag */}
            <select className="form-select my-2" id="stripe_plan" name="stripe_plan" onChange={e => setStripePlanState(e.target.value)}>
              {/* Loop through @stripe_plans */}
              {filtered_stripe_plans.map((plan, index) => (
                <option key={index} value={plan[1]} selected={plan[1] === stripe_plan_state}>
                  {plan[0]}
                </option>
              ))}
            </select>
          </div>
          {/* Submit Tag */}

          {!call_now && (
            <button type="submit" className="btn text-white cardButton mx-1">
              Preview Changes
            </button>
          )}
        </form>
      </div>
      {call_now && (
        <div className="text-center mt-3">
          <a href="tel:18007992644" className="btn text-white cardButton mx-1">
            Please Call for Special Pricing
          </a>
          <h3 class="mt-2">Toll Free +1.800.799.2644</h3>
        </div>
      )}
    </>
  )
}