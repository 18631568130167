import React, { useState, useRef, useEffect } from "react";
import { useStore } from "@nanostores/react";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import SBConversation from "@sendbird/uikit-react/GroupChannel";
import SBChannelList from "@sendbird/uikit-react/GroupChannelList";
import "@sendbird/uikit-react/dist/index.css";
import { SBChatShow, SBChatUnread, SBChatChannel } from "./SBChatStore";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function SBChatModal({
  avatar_url,
  nickname,
  profile_id,
  app_id,
  initialChannelUrl,
}) {
  const currentChannelUrl = useStore(SBChatChannel);
  const show = useStore(SBChatShow);
  const unreadCount = useStore(SBChatUnread);
  const [size, setSize] = useState({ width: 700, height: 500 });
  const [channelListWidth, setChannelListWidth] = useState(180);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 720,
    y: window.innerHeight - 520,
  });
  const dragRef = useRef(null);
  const [mobileView, setMobileView] = useState(
    currentChannelUrl ? "conversation" : "channels",
  );

  const setShow = () => {
    if (isMobile) {
      setMobileView("channels");
      SBChatChannel.set(null);
    }
    SBChatShow.set(!show);
  };

  const handleMobileBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMobileView("channels");
    SBChatChannel.set(null);
  };

  const onResize = (event, { size }) => {
    setSize({ width: size.width, height: size.height });
  };

  const onChannelListResize = (event, { size }) => {
    setChannelListWidth(size.width);
  };

  const onDragStart = (e) => {
    setIsDragging(true);
    const startX = e.clientX - position.x;
    const startY = e.clientY - position.y;

    const onDrag = (e) => {
      setPosition({
        x: e.clientX - startX,
        y: e.clientY - startY,
      });
    };

    const onDragEnd = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("mouseup", onDragEnd);
    };

    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", onDragEnd);
  };

  useEffect(() => {
    const handleResize = () => {
      setPosition((prev) => ({
        x: Math.min(prev.x, window.innerWidth - size.width),
        y: Math.min(prev.y, window.innerHeight - size.height),
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  useEffect(() => {
    if (initialChannelUrl) {
      SBChatChannel.set(initialChannelUrl);
    }
  }, [initialChannelUrl]);

  useEffect(() => {
    if (currentChannelUrl && isMobile) {
      setMobileView("conversation");
    }
  }, [currentChannelUrl]);

  useEffect(() => {
    console.log("Unread message count changed:", unreadCount);
  }, [unreadCount]);

  if (!show) return null;

  return (
    <>
      <BrowserView>
        <Resizable
          width={size.width}
          height={size.height}
          onResize={onResize}
          minConstraints={[350, 400]}
          maxConstraints={[1000, 800]}
          handle={<div className="custom-handle" />}
        >
          <div
            style={{
              position: "fixed",
              width: `${size.width}px`,
              height: `${size.height}px`,
              bottom: `${window.innerHeight - position.y - size.height}px`,
              right: `${window.innerWidth - position.x - size.width}px`,
              backgroundColor: "white",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              borderRadius: "8px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              zIndex: 10,
            }}
          >
            <div
              ref={dragRef}
              onMouseDown={onDragStart}
              style={{
                padding: "10px",
                backgroundColor: "#176fc9",
                color: "white",
                cursor: "move",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ margin: 0 }}>Messages</h3>
              <button
                onClick={setShow}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                &times;
              </button>
            </div>
            <div style={{ flex: 1, display: "flex", overflow: "hidden" }}>
              <Resizable
                width={channelListWidth}
                height={size.height - 40}
                onResize={onChannelListResize}
                axis="x"
                minConstraints={[120, 100]}
                maxConstraints={[300, 800]}
                handle={<div className="custom-handle-vertical" />}
              >
                <div
                  className="custom-channel-list"
                  style={{
                    width: `${channelListWidth}px`,
                    height: "100%",
                    borderRight: "1px solid #e0e0e0",
                    overflow: "auto",
                  }}
                >
                  <SBChannelList
                    selectedChannelUrl={currentChannelUrl}
                    onChannelSelect={(channel) => {
                      SBChatChannel.set(channel?.url);
                      SBChatUnread.set(channel?.unreadMessageCount || 0);
                    }}
                    renderHeader={() => (
                      <SBChatChannelListHeader
                        avatar_url={avatar_url}
                        nickname={nickname}
                      />
                    )}
                    renderChannelPreview={({ channel }) => {
                      const otherMember = channel.members.filter(
                        (member) => member.userId !== profile_id,
                      )[0];
                      return (
                        <div
                          style={{
                            padding: "10px",
                            borderBottom: "1px solid #e0e0e0",
                            cursor: "pointer",
                            background:
                              channel.url === currentChannelUrl
                                ? "#f0f0f0"
                                : "transparent",
                            display: "flex",
                            alignItems: "flex-start",
                          }}
                        >
                          <button
                            onClick={() =>
                              window.open(
                                `/profiles/redirect/${otherMember?.userId}`,
                                "_blank",
                              )
                            }
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                channel.coverUrl ||
                                "https://via.placeholder.com/40"
                              }
                              alt={channel.name}
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                marginRight: "8px",
                                flexShrink: 0,
                              }}
                            />
                          </button>
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <div
                              style={{
                                fontWeight: "bold",
                                marginBottom: "2px",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                fontSize: "12px",
                              }}
                            >
                              {otherMember?.nickname || "Unknown User"}
                            </div>
                            <div
                              style={{
                                fontSize: "11px",
                                color: "#666",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                lineHeight: "1.2em",
                                maxHeight: "2.4em",
                              }}
                            >
                              {channel.lastMessage
                                ? channel.lastMessage.message
                                : "No messages yet"}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </Resizable>
              <div style={{ flex: 1, overflow: "auto" }}>
                {currentChannelUrl ? (
                  <SBConversation
                    channelUrl={currentChannelUrl}
                    renderChannelHeader={() => null}
                    renderUserProfile={(props) => {
                      if (props.user?.userId) {
                        window.open(
                          `/profiles/redirect/${props.user.userId}`,
                          "_blank",
                        );
                      }
                      return null;
                    }}
                    onMessagesReceived={(channel) => {
                      SBChatUnread.set(channel.unreadMessageCount);
                    }}
                  />
                ) : (
                  <div style={{ padding: "20px", textAlign: "center" }}>
                    Select a channel or start a new conversation
                  </div>
                )}
              </div>
            </div>
          </div>
        </Resizable>
      </BrowserView>
      <MobileView>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            width: "100vw",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              padding: "10px",
              backgroundColor: "#176fc9",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {mobileView === "conversation" && (
              <button
                onClick={handleMobileBack}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                &larr;
              </button>
            )}
            <h3 style={{ margin: 0 }}>Messages</h3>
            <button
              onClick={setShow}
              style={{
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
                color: "white",
              }}
            >
              &times;
            </button>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {mobileView === "channels" ? (
              <div style={{ flex: 1, width: "100%" }}>
                <style>
                  {`
                    @media (max-width: 768px) {
                      .sendbird-channel-list {
                        width: 100% !important;
                      }
                    }
                  `}
                </style>
                <SBChannelList
                  disableAutoSelect={true}
                  selectedChannelUrl={currentChannelUrl}
                  onChannelSelect={(channel) => {
                    if (channel?.url) {
                      SBChatChannel.set(channel.url);
                      SBChatUnread.set(channel.unreadMessageCount || 0);
                      setMobileView("conversation");
                    }
                  }}
                  renderHeader={() => (
                    <SBChatChannelListHeader
                      avatar_url={avatar_url}
                      nickname={nickname}
                    />
                  )}
                  renderChannelPreview={({ channel }) => {
                    const otherMember = channel.members.filter(
                      (member) => member.userId !== profile_id,
                    )[0];
                    return (
                      <div
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #e0e0e0",
                          cursor: "pointer",
                          background:
                            channel.url === currentChannelUrl
                              ? "#f0f0f0"
                              : "transparent",
                          display: "flex",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <button
                          onClick={() =>
                            window.open(
                              `/profiles/redirect/${otherMember?.userId}`,
                              "_blank",
                            )
                          }
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={
                              channel.coverUrl ||
                              "https://via.placeholder.com/40"
                            }
                            alt={channel.name}
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              marginRight: "8px",
                              flexShrink: 0,
                            }}
                          />
                        </button>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <div
                            style={{
                              fontWeight: "bold",
                              marginBottom: "2px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              fontSize: "12px",
                            }}
                          >
                            {otherMember?.nickname || "Unknown User"}
                          </div>
                          <div
                            style={{
                              fontSize: "11px",
                              color: "#666",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.2em",
                              maxHeight: "2.4em",
                            }}
                          >
                            {channel.lastMessage
                              ? channel.lastMessage.message
                              : "No messages yet"}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            ) : mobileView === "conversation" && currentChannelUrl ? (
              <SBConversation
                channelUrl={currentChannelUrl}
                renderChannelHeader={() => null}
                renderUserProfile={(props) => {
                  if (props.user?.userId) {
                    window.open(
                      `/profiles/redirect/${props.user.userId}`,
                      "_blank",
                    );
                  }
                  return null;
                }}
                onMessagesReceived={(channel) => {
                  SBChatUnread.set(channel.unreadMessageCount);
                }}
              />
            ) : (
              <div style={{ padding: "20px", textAlign: "center" }}>
                Select a channel to start messaging
              </div>
            )}
          </div>
        </div>
      </MobileView>
    </>
  );
}

function SBChatChannelListHeader({ avatar_url, nickname }) {
  return (
    <div
      style={{
        padding: "8px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e0e0e0",
        backgroundColor: "#f8f9fa",
        width: "100%",
      }}
    >
      <img
        src={avatar_url}
        alt="User avatar"
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          marginRight: "8px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#212529",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {nickname}
        </span>
      </div>
    </div>
  );
}
