import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Testimonials({ testimonials, googleLogo, starLogo, quoteLogo }) {
  return (
    <div>
      <div className="d-flex row text-center ps-sm-5 py-5 d-md-none">
        <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} autoplaySpeed={2000} autoHeightClass="test">
          {testimonials.map((testimonial, index) => (
            <Testimonial testimonial={testimonial} googleLogo={googleLogo} starLogo={starLogo} quoteLogo={quoteLogo} responsiveClass="test" key={testimonial.id} />
          ))}
        </OwlCarousel>
      </div>
      <div className="d-flex row text-center py-1 d-none d-xl-block">
        <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} autoplaySpeed={2000} autoHeightClass="test">
          {testimonials.map((testimonial, index) => (
            <Testimonial testimonial={testimonial} googleLogo={googleLogo} starLogo={starLogo} quoteLogo={quoteLogo} key={testimonial.id} />
          ))}
        </OwlCarousel>
      </div>
      <div className="d-flex row text-center py-5 d-none d-md-block d-xl-none">
        <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} autoplaySpeed={2000} autoHeightClass="test">
          {testimonials.map((testimonial, index) => (
            <Testimonial testimonial={testimonial} googleLogo={googleLogo} starLogo={starLogo} quoteLogo={quoteLogo} key={testimonial.id} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  )
}

function Testimonial({ testimonial, googleLogo, starLogo, quoteLogo }) {
  return (
    // <div className="col-xxl-3 py-5">

    <div className="card px-5 py-3 mt-5" id="reviewCard">
      <div className="text-center">
        <center><img src={quoteLogo} className="img-fluid" style={{ maxWidth: 100 }} id="quoteLogo" /></center>
      </div>
      <div>
        <center><img src={googleLogo} className="img-fluid" id="googleLogo" style={{ maxWidth: 75 }} /></center>
      </div>
      <div>
        <center><img src={starLogo} className="img-fluid" id="starLogo" style={{ maxWidth: 100 }} /></center>
      </div>
      <div className="pt-4">
        <p id="reviewP">
          {testimonial.short_testimonial}
        </p>
        <p id="reviewP">
          {testimonial.author}
        </p>
      </div>
    </div>
  )
}