import * as React from "react";

interface JobTypeProps {
  job_type: string;
}

export default function ProfileJobType({ job_type }: JobTypeProps) {
  const icon = <>
    <i className="fa fa-tooth"></i> {job_type}
  </>
  return (
    <>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-md-none col-12">
        {icon}
      </span>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
        {icon}
      </span>
    </>
  );
}
