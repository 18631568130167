import "../Common-poly"
import * as React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import ManageJobList from "./ManageJobList"

const fetchJobs = ({ setState }) => {
  fetch("/jobs/manage.json")
    .then(res => res.json())
    .then(res => {
      // Map res.temp_jobs, if temp_date is null, set to published_at
      const temp_jobs = res.temp_jobs.map(job => {
        if (job.temp_date === null) {
          job.temp_date = job.published_at
        }
        return job
      })

      setState({
        jobs: res.perm_jobs, temp_jobs
      })
    })
    .catch(error => console.log(error))
}

export default function Manage({
  jobs,
  temp_jobs,
  position_types,
  job_types,
  practices,
}) {
  const [state, setState] = React.useState({ jobs, temp_jobs })

  const index = window.location.hash.substr(1) === "temp" ? 1 : 0

  const onSelect = i => {
    history.replaceState(undefined, undefined, i === 1 ? "#temp" : "#perm")
  }

  React.useEffect(() => {
    setInterval(() => fetchJobs({ setState }), 30000)
  }, [])

  return (
    <Tabs
      defaultIndex={index}
      onSelect={(index, lastIndex, event) => onSelect(index)}
    >
      <TabList className="manageTabList border-0">
        <Tab>My Permanent Jobs</Tab>
        <Tab className="temp_tab react-tabs__tab">My Temp Jobs</Tab>
      </TabList>
      <TabPanel>
        <ManageJobList
          jobs={state.jobs}
          position_types={position_types}
          job_types={job_types}
          practices={practices}
          temp={false}
        />
      </TabPanel>
      <TabPanel>
        <ManageJobList
          jobs={state.temp_jobs}
          position_types={position_types}
          job_types={job_types}
          practices={practices}
          temp={true}
        />
      </TabPanel>
    </Tabs>
  )
}
