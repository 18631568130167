import '../Common-poly'
import React from "react"
import Ratings from 'react-ratings-declarative';

const TempRating = props => {
    const { path, authenticity_token, id, temp_rating, owner_type, comments, comment_options, professional_name, practice_name, image } = props;

    const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticity_token
    }

    // Existing Rating
    const rating = temp_rating ? temp_rating : 0;

    // Existing Comments
    const comments_object = {}
    comments.forEach(comment => comments_object[comment] = true);

    // Form State
    const [state, setState] = React.useState({
        id,
        rating,
        comments: comments_object,
        submitted: false
    });

    // Update Rating Function
    const updateRating = rating => setState({
        ...state, rating, submitted: true
    });

    // Toggle Checkbox and Update State
    const toggleCheckbox = checkbox => setState({
        ...state,
        submitted: true,
        comments: {
            ...state.comments,
            [checkbox.target.value]: checkbox.target.checked,
        }
    });

    // Update Ratings API
    const updateRatingsAPI = () => {
        const { id, rating } = state;
        const filtered_comments = Object.keys(state.comments).filter(c => state.comments[c] === true);
        const body = JSON.stringify({ id, rating, comments: filtered_comments });
        const options = { headers, body, method: 'PUT' }
        if (state.rating > 0) fetch(path, options)
    }

    // Update Ratings API if state changes
    React.useEffect(() => updateRatingsAPI(), [state.rating, state.comments]);

    return (
        <div>
            <img src={image} alt="" />
            {owner_type === 'Profile' &&
                <ProfileView
                    professional_name={professional_name}
                    practice_name={practice_name} />
            }

            {owner_type === 'Practice' &&
                <PracticeView
                    professional_name={professional_name}
                    practice_name={practice_name} />
            }

            <Ratings rating={state.rating} widgetRatedColors="blue" changeRating={updateRating}>
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
            </Ratings>

            <Comments
                comments={comment_options}
                rating={state.rating}
                owner_type={owner_type}
                toggleCheckbox={toggleCheckbox}
                checkboxes={state.comments} />

            {state.submitted && (
                <p style={{ marginTop: 10 }}>Your rating has been submitted, please check any boxes that apply to your temp experience. You can also change or update your rating if necessary.</p>
            )}
        </div>
    );
}

const Comments = ({ comments, rating, owner_type, checkboxes, toggleCheckbox }) => {
    const comment_list = comments.filter(comment =>
        comment.ratings.includes(rating) &&
        comment.owner_type == owner_type
    );

    return (
        <ul className="checkboxes">
            {comment_list.map(comment => {
                const { id, name } = comment;
                const checkbox = checkboxes[id] === true;
                const options = {
                    id: `check-comment-${id}`,
                    name: `comment_${id}`,
                    value: id,
                    checked: checkbox,
                    onChange: toggleCheckbox
                }

                return (
                    <li key={options.id}>
                        <input type="checkbox" {...options} />
                        <label htmlFor={options.id}>{name}</label>
                    </li>
                )
            })}
        </ul>
    )
}

const ProfileView = ({ professional_name, practice_name }) => (
    <p>Hi {professional_name}! Would you work again at {practice_name}?</p>
)

const PracticeView = ({ professional_name, practice_name }) => (
    <p>Hi {practice_name}! Would you work again with {professional_name}?</p>
)

export default TempRating;