import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Applications from './Applications';
import ApplicationInvitations from './ApplicationInvitations';


const ManageApplications = ({ applications, invitations, authenticity_token, edit_profile_path }) => {
    const [state, setState] = React.useState({
        applications, invitations, loaded: false
    });

    console.log(state);

    const getNotes = () => {
        // get_notes({ callback: notes => setState({ ...state, notes, loaded: true }) });
    }

    React.useEffect(() => getNotes(), []);

    const index = window.location.hash.substr(1) === 'invited' ? 1 : 0;

    const onSelect = i => {
        // window.location.hash = (i === 1) ? '#invited' : '#applicants';
    }


    return (
        <Tabs defaultIndex={index} onSelect={(index, lastIndex, event) => onSelect(index)}>
            <TabList style={{ marginBottom: 10 }}>
                <Tab>My Invitations to Apply</Tab>
                <Tab style={{ color: 'black' }}>Application History</Tab>
            </TabList>
            <br />
            <TabPanel>
                <p className="margin-bottom-25">
                    This is where you can see all of your invitations to apply to jobs.
                </p>
                {state.invitations.length < 1 && (
                    <div>
                        <h4 className="text-center">
                            Hiring Managers can view your profile and Invite you to Apply. View Job and Apply if interested. Don't see any job invites? Update your profile to attract the best jobs.<br />
                        </h4>
                        <p className="text-center">
                            <a className="button" href={edit_profile_path}>Edit Profile</a>
                        </p>
                    </div>
                )}
                <ApplicationInvitations
                    invites={state.invitations}
                    authenticity_token={authenticity_token}
                />
            </TabPanel>
            <TabPanel>
                <p className="margin-bottom-25">
                    This is where we keep your job applications.
                </p>
                {state.applications.length < 1 && (
                    <div>
                        <h4 className="text-center">You currently have zero job applications.  Search jobs and apply instantly using your profile.</h4><br />
                        <p className="text-center">
                            <a href="/jobs" className="button">Search Jobs</a>
                        </p>
                    </div>
                )}

                <Applications
                    applications={state.applications}
                    authenticity_token={authenticity_token}
                />

            </TabPanel>
        </Tabs>
    )
}

export default ManageApplications;