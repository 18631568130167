import * as React from "react";

import { ProfileFormContext } from "../../ProfileEditForm";
import { ACTIONS } from "../ProfileFormReducer";

// Rich Text Editor
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import RichTextareaEditor from "../../../FormComponents/RichTextareaEditor";
import { initialEditorState } from "../../../FormComponents/RichTextareaEditor";

const BlankExperienceItem = {
  employer: "",
  title: "",
  start_date: "",
  end_date: "",
  current: false,
  description: "",
  editing: true,
};

export default function ExperienceHistory() {
  const [state, dispatch] = React.useContext(ProfileFormContext);

  // Add new experience item
  const addExperienceItem = () => {
    dispatch({
      type: ACTIONS.ADD_EXPERIENCE,
      payload: BlankExperienceItem,
    });
  };

  // Remove experience item by index
  const removeExperienceItem = (index: number) => {
    dispatch({
      type: ACTIONS.REMOVE_EXPERIENCE,
      payload: index,
    });
  };

  // Edit experience item by index
  const editExperienceItem = (index: number) => {
    dispatch({
      type: ACTIONS.EDIT_EXPERIENCE,
      payload: index,
    });
  };

  // Unedit experience item by index
  const uneditExperienceItem = (index: number) => {
    dispatch({
      type: ACTIONS.UNEDIT_EXPERIENCE,
      payload: index,
    });
  };

  // Update experience item by id
  const updateExperienceItem = (index: number, experience: any) => {
    dispatch({
      type: ACTIONS.UPDATE_EXPERIENCE,
      payload: { index, experience },
    });
  };

  // Console log after state changes
  React.useEffect(() => {
    console.log(state.profile.experiences);
  }, [state.profile.experiences]);

  return (
    <div key={state}>
      {/* Use Index */}
      {state.profile.experiences.map((experience, index) => (
        <div key={index} className="shadow m-3 p-3 bg-light bg-gradient">
          {/* Remove Experience Item */}
          {/* Align Top right */}
          <button
            type="button"
            className="btn btn-danger pull-right"
            onClick={() => removeExperienceItem(index)}
          >
            <i className="fas fa-trash"></i>
          </button>

          {/* Edit Experience Item */}
          {/* Align Top right */}
          {experience.editing ? (
            <button
              type="button"
              className="btn btn-success pull-right me-1"
              onClick={() => uneditExperienceItem(index)}
            >
              <i className="fas fa-check"></i>
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-warning pull-right me-1"
              onClick={() => editExperienceItem(index)}
            >
              <i className="fas fa-edit"></i>
            </button>
          )}

          <ExperienceItem
            experience={experience}
            updateExperienceItem={(exp: any) =>
              updateExperienceItem(index, exp)
            }
            editing={experience.editing}
            key={index}
          />
        </div>
      ))}
      {/* Button to add new experience item */}
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={addExperienceItem}
        >
          Add Experience
        </button>
      </div>
    </div>
  );
}

function ExperienceItem({ experience, updateExperienceItem, editing }) {
  // Create state object for experience
  const [experienceState, setExperienceState] = React.useState(experience);
  const { employer, title, start_date, end_date, current, description } =
    experienceState;

  const [editorState, setEditorState] = React.useState(
    initialEditorState(description),
  );

  const onChangeEditor = (editorState: any) => {
    setEditorState(editorState);
  };

  // Update state object for experience
  const updateExperienceState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExperienceState({
      ...experienceState,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    setExperienceState({
      ...experienceState,
      description: markup,
    });
  }, [editorState]);

  // Reset state object for experience when experience prop changes
  React.useEffect(() => {
    setExperienceState(experience);
  }, [experience]);

  // Update experience in parent component when state changes
  React.useEffect(() => {
    console.log("Experience State Changed", experienceState);
    updateExperienceItem(experienceState);
  }, [experienceState]);

  return (
    <>
      {editing ? (
        <div className="mt-4">
          <div className="form-group">
            <label htmlFor="employer" className="form-label">
              Employer
            </label>
            <input
              type="text"
              className="form-control"
              id="employer"
              name="employer"
              value={employer}
              onChange={updateExperienceState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={title}
              onChange={updateExperienceState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="start_date" className="form-label">
              Start Date
            </label>
            <input
              type="date"
              className="form-control"
              id="start_date"
              name="start_date"
              value={start_date}
              onChange={updateExperienceState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="end_date" className="form-label">
              End Date
            </label>
            <input
              type="date"
              className="form-control"
              id="end_date"
              name="end_date"
              value={end_date}
              onChange={updateExperienceState}
              disabled={current}
            />
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="current"
              name="current"
              checked={current}
              onChange={(e) =>
                setExperienceState({
                  ...experienceState,
                  current: e.target.checked,
                })
              }
            />
            <label className="form-check-label" htmlFor="current">
              Current Position
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <RichTextareaEditor
              editorState={editorState}
              onChangeDescription={onChangeEditor}
            />
          </div>
        </div>
      ) : (
        <ExperiencePreview experience={experienceState} />
      )}
    </>
  );
}

const ExperiencePreview = ({ experience }) => {
  return (
    <div>
      <h5>
        <strong>{experience.title}</strong>
      </h5>
      <h6>{experience.employer}</h6>
      <h6>{`${experience.start_date} - ${experience.current ? "Present" : experience.end_date}`}</h6>
      <div dangerouslySetInnerHTML={{ __html: experience.description }} />
    </div>
  );
};
