import * as  React from "react";
class PracticeSelect extends React.Component {
    practices = () => {
        if (this.props.practices.length > 0) {
            return (
                this.props.practices.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.name} - {item.street_address}, {item.city}, {item.state} {item.zip}
                    </option>
                ))
            )
        } else {
            return (<option>Loading...</option>);
        }
    }

    render() {
        return (
            <div className="form-group">
                <h3>Practice Location</h3>
                <select name="job[practice_id]"
                    className="form-control"
                    value={this.props.practice_id}
                    onChange={(e) => this.props.handlePracticeChange(e, 'practice_id')}>
                    {this.practices()}
                </select>
            </div>
        );
    }
}

export default PracticeSelect;