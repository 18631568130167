import React from "react"
import JobTags from "./JobTags"
// import moment from 'moment';

const Job = ({ job, noTags }) => {
  return (
    <div className="field">
      <li>
        <a href={job.url} target="_blank">
          <img src={job.image} alt="" className="job-avatar" />
          <div className="job-list-content">
            <h4 className="fullname">{job.title}</h4>
            <div className="job-icons">
              <span>
                <i className="fa fa-briefcase"></i> {job.practice_name}
              </span>
              <span>
                <i className="fa fa-map-marker"></i> {job.practice_city},{" "}
                {job.practice_state}
              </span>
              {job.compensation && (
                <span>
                  <i className="fa fa-money"></i> {job.compensation}
                </span>
              )}
            </div>
            {!noTags && <JobTags job={job} />}
          </div>
          {noTags && (
            <a href="https://www.ziprecruiter.com/jobs" id="jobs_widget_link">
              <span>Job Search by</span>{" "}
              <span id="zr_logo_container">
                <img
                  id="zr_logo"
                  src="https://www.ziprecruiter.com/img/logos/logo-sm-black-304px.png"
                  alt="ZipRecruiter"
                />
              </span>
            </a>
          )}
        </a>
      </li>
      <div className="clearfix"></div>
    </div>
  )
}

export default Job