import * as React from "react";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

function JobAnalyics({ job_analytics }) {
  const rowData = job_analytics;
  const colDefs = [
    { field: 'id' },
    { field: 'job' },
    { field: 'practice' },
    { field: 'job_title' },
    { field: 'job_age' },
    { field: 'invites' },
    { field: 'jobsync_apps' },
    { field: 'pando_tag' },

  ]

  console.log('colDefs', colDefs);

  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 500 }} // the grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
      />
    </div>
  )
}
export default JobAnalyics;