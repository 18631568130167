import * as  React from "react";
class PositionTypeSelect extends React.Component {
    render() {
        return (
            <div className="form-group">
                <h3>Position Type</h3>
                <select name="job[job_type_id]"
                    className="form-control"
                    value={this.props.job_type_id}
                    onChange={(e) => this.props.handleChange(e, 'job_type_id')}>
                    {this.props.job_types.map(item => (
                        <option key={item.id} value={item.id}>
                            {item.title}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

export default PositionTypeSelect;