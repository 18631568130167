import * as React from 'react';

const SubFAQ = (props) => {
    const [state, setState] = React.useState({ more: false })
    const showMore = () => setState({ more: !state.more });
    return <Questions more={state.more} showMore={showMore} />;
}

const Questions = ({ more, showMore }) => {
    return (
        <div className="sixteen columns plan-faq">
            <h1 className="text-center">Frequently Asked Questions</h1>
            <div className="one-third column">
                <h4>I'm a job seeker looking for permanent and temporary jobs, is DirectDental.com free to join?</h4>
                <p>Yes, DirectDental.com is 100% free to use for job seekers. Find perm & temp jobs, list your availability, accept or decline job requests and apply instantly using your free dental professional profile. Your profile can be used as a professional online resume that can be shared with potential employers for interviews and even on social media.</p>
            </div>
            <div className="one-third column">
                <h4>I’m a dental office looking to hire permanent and temporary staff, what plans do you offer?</h4>
                <p>We like to keep things easy and affordable so we offer all dental offices the perfect Essential Plan. This plan includes unlimited job postings, candidate searches, profile views, temp requests, direct messages and more! You have the option to choose between monthly, quarterly or annual pricing plans. No perm placement fees. No temp daily rates. No temp buyouts to worry about. Just simple affordable pricing.</p>
            </div>
            <div className="one-third column">
                <h4>I’d prefer calling a dental staffing agency to get candidates and temps, do you offer that option too?</h4>
                <p>Yes, but way better. Our business started as a dental staffing agency and we love chatting with our amazing dental office clients. So we listened and created a new, simple and more cost effective staffing service for dental offices. Keeping the personal touch, our experienced staffing team is available to help you find perm or temp staff as needed. And our unique service plans and pricing give you more flexibility than ever before. Call us to learn more. </p>
            </div>
            {more && (
                <React.Fragment>
                    <div className="one-third column">
                        <h4>I'm a dental professional looking for new job opportunties, is DirectDental.com free for job seekers to join?</h4>
                        <p>Yes,  DirectDental.com is 100% free to use for job seekers. Find perm & temp jobs, list your availability, accept or decline job requests and apply instantly using your free dental professional profile.</p>
                    </div>
                    <div className="one-third column">
                        <h4>How many jobs can I post?</h4>
                        <p>You can post unlimited jobs. You have the option to select Full-time, part-time, long term temporary and (daily) temporary. We recommend posting temp jobs as far in advance as possible to have more temp candidate options. If you have regular employees scheduled to go on vacation, we recommend posting temp jobs to get those days covered for your office!</p>
                    </div>
                    <div className="one-third column">
                        <h4>Can I find temps to work at my office today?</h4>
                        <p>Yes, you can post temp jobs and even request your favorite compatible temps for next day or same day jobs. We always recommend posting temp jobs as far in advance as possible to give you the most compatible temp options. However we understand employees call out sick or emergencies happen and DirectDental.com is built to help you book a temp quickly whenever possible.</p>
                    </div>
                    <div className="one-third column">
                        <h4>How is DirectDental.com different from other places where I can post jobs?</h4>
                        <p>DirectDental.com was built to enhance the perm & temp hiring experience for dental employers of all size and specialty type. We've taken our experience running a traditional dental staffing agency and created a modern service for dental offices wanting a better solution. While most job boards are meant for general business and offer general job posting options, DirectDental.com was designed to match the job requirements for every dental office better than anyone.</p>
                    </div>
                    <div className="one-third column">
                        <h4>Is there a limit on candidate profiles I can view and contact?</h4>
                        <p>No limits!  You can contact unlimited applicants and search all candidate profiles. Send direct messages, invite to apply and even request specific temp professionals for the day. </p>
                    </div>
                    <div className="one-third column">
                        <h4>If I want to hire candidates are there any placement fees or additional costs?</h4>
                        <p>There are Zero Placement Fees to hire on DirectDental.com. You can hire anyone you like whenever you're ready at no additional charge.</p>
                    </div>
                    <div className="one-third column">
                        <h4>What is the commitment?</h4>
                        <p>You can cancel at any time. Your plan will expire at the end of the billing period and you won't be charged again. </p>
                    </div>
                    <div className="one-third column">
                        <h4>Do you accept payment by credit/debit card to start?</h4>
                        <p>Yes, we accept credit/debit cards to start. You can cancel at any time.</p>
                    </div>
                </React.Fragment>
            )}
            <div className="sixteen columns text-center">
                <a href="#more-faqs" className="button" onClick={() => showMore()}>
                    <i className="fa fa-question-circle"></i> More Frequently Asked Questions
                </a>
            </div>
        </div>
    )
}

export default SubFAQ;