import React from "react"
import JobSkills from "./JobSkills"

export default function JobTags(props) {
  const { job } = props
  return (
    <h4 className="tags-no-padding">
      {job.skills !== undefined && (
        <JobSkills
          job={job}
          position_type={job.position_type}
          skills={job.skills}
        />
      )}
    </h4>
  )
}
