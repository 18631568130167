import React from "react"

const TextField = ({ value, name, id, updateFunc, placeholder = "" }) => {
  const [state, setState] = React.useState({ value })
  const update = (string) => {
    setState({ value: string })
    updateFunc(string)
  }
  React.useEffect(() => setState({ value }), [value])
  return (
    <input
      type="text"
      name={name}
      id={id}
      value={state.value}
      onChange={(e) => update(e.target.value)}
      className="form-control"
      placeholder={placeholder}
    />
  )
}

export default TextField
