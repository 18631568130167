import * as React from "react";
// import ReactDOM from 'react-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'draft-js/dist/Draft.css';
import RichTextareaEditor from "../FormComponents/RichTextareaEditor";

const initialEditorState = (initialDescription) => {
  if (initialDescription) {
    const contentBlock = htmlToDraft(initialDescription);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
  }
  return EditorState.createEmpty();
}

export default function LocationDescription({ initialDescription, fieldName = "practice[description]" }) {

  const [editorState, setEditorState] = React.useState(initialEditorState(initialDescription));
  const [editorMarkup, setEditorMarkup] = React.useState();

  const onChangeEditor = editorState => {
    setEditorState(editorState);
  }

  React.useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    setEditorMarkup(markup);
    console.log(editorState, markup);
  }, [editorState])

  return (
    <>
      <RichTextareaEditor editorState={editorState} onChangeDescription={onChangeEditor} />
      <input type="hidden" name={fieldName} value={editorMarkup} />
    </>
  )
}

