import * as React from 'react'

export default function RecruiterServiceCartItems({ state, removeItem, updateJobType, updateProduct, job_types, products, getItemPrice }) {
  return (
    <ul className="list-unstyled">
      {state.items.map(item => (
        // Horizontal align
        <li key={item.id}
          className={` d-flex flex-row justify-content-between align-items-center ${item.id % 2 === 0 ? 'bg-light' : 'bg-white'} gap-2`}>
          {/* Update Job Type Dropdown */}
          <select
            className="form-control form-select my-1"
            value={item.jobType.id}
            onChange={event => {
              const jobTypeId = parseInt(event.target.value)
              const jobType = job_types.find(jobType => jobType.id === jobTypeId)
              updateJobType(item.id, jobType)
            }}
          >
            {job_types.map(jobType => (
              <option key={jobType.id} value={jobType.id}>
                {jobType.title}
              </option>
            ))}
          </select>

          {/* Update Product Dropdown */}
          <select
            className="form-control form-select my-1"
            value={item.product.id}
            onChange={event => {
              const productId = parseInt(event.target.value)
              const product = products.find(product => product.id === productId)
              updateProduct(item.id, product)
            }}
          >
            {products.map(product => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>

          {getItemPrice(item)}

          {/* Remove Button */}
          <button onClick={() => removeItem(item)}
            className="btn btn-danger btn-sm">
            <i className="fas fa-trash"></i>
          </button>
        </li>
      ))}
    </ul>
  )
}