import * as React from "react";
import InviteProfile from "./InviteProfile";

import ProfileTags from "./ProfileComponents/ProfileTags";
import ProfileName from "./ProfileComponents/ProfileName";
import ProfileLocation from "./ProfileComponents/ProfileLocation";
import ProfileCompensation from "./ProfileComponents/ProfileCompensation";
import ProfileDistance from "./ProfileComponents/ProfileDistance";
import ProfileJobType from "./ProfileComponents/ProfileJobType";

import { ListContext } from '../profiles_search/ProfileList'

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  zip: string;
  image: string;
  url: string;
  compensation: string | number;
  job_types: Array<any>;
  skills: Array<any>;
  position_types: Array<any>;
}

interface Job {
  [prop: string]: any;
}

interface ProfileProps {
  profile: Profile;
  job?: any;
  user_id?: number;
  authenticity_token?: string;
  distance?: number;
  favorites?: Array<number>;
  blocked?: Array<number>;
  requested?: Array<number>;
  accepted?: Array<number>;
  declined?: Array<number>;
  addToRequestedFunction?: () => Array<any>;
}

export default function Profile({
  profile,
  authenticity_token = "",
  favorites = [],
  blocked = [],
  job = {},
  requested = [],
  accepted = [],
  declined = [],
  addToRequestedFunction = undefined,
  distance = undefined,
  user_id = undefined
}: ProfileProps) {
  const [state, setState] = React.useState({ blocked: false })
  const blockedCallback = () => setState({ blocked: !state.blocked })
  const job_id = job ? job.id : undefined
  const [context, setContext] = React.useContext(ListContext)
  const contextBlocked = context.blocked.includes(profile.id)
  return (
    <div className={`field transition-profile ${state.blocked ? 'blocked' : ''} ${contextBlocked ? 'contextBlocked' : ''}`} key={profile.id}>
      <li>
        <a
          target="_blank"
          onClick={() => window.open(profile.url, "_blank")}
          style={{ cursor: "pointer" }}
        >
          <img src={profile.image} alt="" />
          {job.hasOwnProperty('id') && (
            <InviteProfile
              requested={requested}
              accepted={accepted}
              declined={declined}
              profile={profile}
              job={job}
              authenticity_token={authenticity_token}
              callback={addToRequestedFunction}
            />
          )}
          <div className="job-list-content">
            <ProfileName
              blockedCallback={() => blockedCallback}
              favorites={favorites}
              blocked={blocked}
              profile={profile}
              job_id={job_id}
              user_id={user_id}
              authenticity_token={authenticity_token}
            />
            <div className="job-icons">
              {profile.job_types && profile.job_types.length >= 4 &&
                profile.job_types.slice(0, 3).map((jt: string) => (
                  <ProfileJobType job_type={jt} />
                ))}
              {profile.job_types && profile.job_types.length >= 4 &&
                <span>
                  <i className="fa fa-plus"></i> {profile.job_types.length - 3} More Positions
                </span>
              }
              {profile.job_types && profile.job_types.length < 4 &&
                profile.job_types.map((jt: string) => (
                  <ProfileJobType job_type={jt} />
                ))}
              {profile.city && profile.state && profile.zip && (
                <ProfileLocation
                  city={profile.city}
                  state={profile.state}
                  zip={profile.zip}
                />
              )}
              {profile.compensation && (
                <ProfileCompensation compensation={profile.compensation} />
              )}
              {distance && <ProfileDistance distance={distance} />}
            </div>
            <ProfileTags profile={profile} />
          </div>
        </a>
      </li>
      <div className="clearfix"></div>
    </div>
  );
}
