import * as React from 'react'
import { Modal } from 'react-bootstrap'

export default function JobFilled({
  phone,
  job,
  practice
}) {
  // Show Popup State
  const [modalOpen, setModalOpen] = React.useState(true)

  // Close Modal
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Temp Job Accepted
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>YOU ACCEPTED THIS TEMP JOB!</h3>
          <br />
          <h5>NEXT STEP</h5>
          <p>You are now Booked for this Temp Job, please view the info below and call the Practice to confirm the job details at <strong>{phone}</strong>.</p>
          <h5>VERY IMPORTANT</h5>
          <p>If you are unable to keep your commitment to this job, please call the Practice to inform them ASAP.
            Note that cancelling on jobs after you have accepted can potentially have a negative impact on your professional profile rating.
          </p>

          {/* Close button */}
          <button className="btn btn-primary" onClick={closeModal}>
            Close
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}