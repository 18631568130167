import * as React from 'react';
import Job from './Job';

export default function JobsList(props) {
    const { jobs, zipjobs, sort } = props;
    let sortedZipJobs;

    switch (sort) {
        case 'ratehigh':
            sortedZipJobs = zipjobs.sort((a, b) => (a.salary_max < b.salary_max) ? 1 : -1);
            break;

        case 'ratelow':
            sortedZipJobs = zipjobs.sort((a, b) => (a.salary_max > b.salary_max) ? 1 : -1);
            break;

        default:
            sortedZipJobs = zipjobs;
            break;
    }

    return (
        <div className="padding-right">
            <ul className="job-list full">
                {jobs.map(job => <Job job={job} noTags={false} />)}
                {sortedZipJobs.map(job => <ZipJob {...job} />)}
            </ul>
        </div>
    );
}

const ZipJob = ({ city, state, hiring_company, salary_max, salary_interval, url, name, ddid }) => {
    const compensation = salary_max && salary_interval
        ? `\$${numberWithCommas(salary_max)} / ${salary_interval.replace('ly', '')}`
        : false;

    const jobObject = {
        // url: `/exit?partner=Zip Recruiter&url=${url}`,
        url: `/sponsored_jobs/${ddid}`,
        image: 'https://directdental-production.s3.us-west-2.amazonaws.com/missing/missing.png',
        title: name,
        practice_name: hiring_company.name,
        practice_city: city,
        practice_state: state,
        compensation
    }

    return <Job job={jobObject} noTags={true} />;
}

const numberWithCommas = (x) => {
    try {
        const number = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return number;
    } catch (error) {
        return x;
    }
}
