import * as React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-datepicker/dist/react-datepicker.css"

import JobForm from "./FormComponents/JobFormContext"
import GeneralInfo from "./FormComponents/GeneralInfo"
// import Skills from "./FormComponents/Skills"
import Compensation from "./FormComponents/Compensation"
import Description from "./FormComponents/Description"

// Job Types are "Dental Assistant"....
// Position Types are "Full Time"....

const styling = { flex: "1", textAlign: "center", margin: "5px", }

const JobFormInitialState = (job, job_skills, temp) => ({
  ...job,
  skill_ids: job_skills.map(skill => skill.id),
  position_type: temp ? "temp" : null,
  description: "",
  job_type_id: 1,
})

export default function EmbedForm({
  job,
  practices,
  skills,
  job_skills,
  job_types,
  position_types,
  form_elements,
  skill_categories,
  authenticity_token,
  profile_id,
  temp = false
  // compact,
  // hide,
  // temp_only,
  // form_tags,
}) {
  const [jobState, setJobState] = React.useState(JobFormInitialState(job, job_skills, temp))
  const [practicesState, setPracticesState] = React.useState(practices)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [validation, setValidation] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  console.log("practices", practices)

  React.useEffect(() => {
    const {
      position_type,
      job_type_id,
      practice_id,
      sms_phone,
      temp_date,
      temp_start,
      temp_end,
    } = jobState
    if (
      position_type !== null &&
      position_type !== "" &&
      job_type_id !== null &&
      job_type_id !== "" &&
      practice_id !== null &&
      practice_id !== ""
    ) {
      if (jobState.position_type !== "temp") {
        setValidation(false)
      } else {
        //these are the checks for the temp position
        if (
          sms_phone !== null &&
          sms_phone !== "" &&
          temp_date !== null &&
          temp_date !== "" &&
          temp_start !== null &&
          temp_start !== "" &&
          temp_end !== null &&
          temp_end !== ""
        ) {
          setValidation(false)
        } else {
          setValidation(true)
        }
      }
    } else {
      setValidation(true)
    }
  }, [jobState])

  React.useEffect(() => {
    console.log(jobState)
  }, [jobState])

  const apiRequestData = {
    request_profile: profile_id !== undefined,
    profile_id: profile_id,
    job: jobState,
  }

  function submitToApi() {
    const url = apiRequestData.job.id
      ? `/jobs/${apiRequestData.job.id}.json`
      : "/jobs"
    const method = apiRequestData.job.id ? "PUT" : "POST"

    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    }

    setLoading(true)
    try {
      fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(apiRequestData),
      }).then(response => {
        // Check if node development mode
        // if (process.env.NODE_ENV === "development") {
        if (response.ok) {
          // console.log("success", response)
        } else {
          setLoading(false)
          setError(true)
          // console.log("error", response)
        }
        // }

        // If response redirected or "PUT" request, redirect to the new location
        if (response.redirected || method === "PUT") {
          // Remove ".json" from the end of the URL
          const newUrl = response.url.replace(/\.json$/, "")
          window.location.href = newUrl
        }
      })
    } catch (err) {
      setLoading(false)
      setError(true)
    }
  }

  // Hide form if error || loading == true
  // IF loading show a loading spinner
  // IF error show an error message

  return (
    <div className="d-flex justify-content-center">
      {loading && (
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="">Processing</p>
        </div>
      )}
      {error && <>Error</>}
      {!loading && !error && (
        <Tabs className="col-12 sign-in-font" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList className="text-center d-md-flex justify-content-between manageTabList border-0">
            <Tab style={styling}>General</Tab>
            {/* <Tab style={styling} disabled={validation ? true : false}>Skills</Tab> */}
            <Tab style={styling} disabled={validation ? true : false}>Compensation</Tab>
            <Tab style={styling} disabled={validation ? true : false}>Description</Tab>
          </TabList>
          {/* Context Provider */}
          <JobForm.Provider value={[jobState, setJobState]}>
            {/* Step 1 */}
            <TabPanel>
              <GeneralInfo
                practices={practicesState}
                job_types={job_types}
                position_types={position_types}
                tabIndexFunc={setTabIndex}
                validation={validation}
                setValidation={setValidation}
                temp={temp}
              />
            </TabPanel>
            {/* Step 2 */}
            {/* <TabPanel>
              <Skills
                skills={skills}
                skill_categories={skill_categories}
                tabIndexFunc={setTabIndex}
              />
            </TabPanel> */}
            {/* Step 3 */}
            <TabPanel>
              <Compensation
                tabIndexFunc={e => setTabIndex(e - 1)}
                benefitsClass="flex-row justify-content-between"
                setValidation={setValidation}
              />
            </TabPanel>
            {/* Step 4 */}
            <TabPanel>
              <Description
                tabIndexFunc={e => setTabIndex(e - 1)}
                validation={validation}
                job_types={job_types}
                form_submit={submitToApi}
              />
            </TabPanel>
          </JobForm.Provider>
        </Tabs>
      )}
    </div>
  )
}
