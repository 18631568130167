import * as React from 'react';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';

const truncate = (str) => {
  return str.length > 120 ? str.substring(0, 120) + "..." : str;
}
export const ApplicantsNotes = ({ profile_id, notes, authenticity_token, load_notes, loaded }) => {
  // var Spinner = require('react-spinkit');
  const loadNotesCallback = () => load_notes();

  return (

    <div className="app-content d-flex flex-column justify-content-between px-0">
      <div className="info p-2">
        {notes.length < 1 && !loaded && (
          // <center><Spinner name="line-scale" color="#186fc9" /></center>
          <center>Loading...</center>
        )}
        {notes.length < 1 && loaded && (
          <p>Save a note to remember important details about this candidate. </p>
        )}
        {notes.length > 0 && (
          <p>
            <strong>{moment(notes[0].created_at).format('llll z')}:</strong> {truncate(notes[0].note)}
          </p>
        )}
      </div>

      <div className="p-1 d-flex flex-row justify-content-around">
        <AllNotes
          profile_id={profile_id}
          notes={notes}
          authenticity_token={authenticity_token}
          loadNotesCallback={loadNotesCallback}
        />
        <NewNote
          profile_id={profile_id}
          authenticity_token={authenticity_token}
          loadNotesCallback={loadNotesCallback}
        />
      </div>
    </div>

  );
}

const get_notes_api = ({ profile_id, callback }) => {
  if (profile_id) {
    fetch('/profile_notes.json?profile_id=' + profile_id)
      .then(res => res.json())
      .then(res => {
        return callback(res);
      })
  } else {
    return callback([]);
  }
}

const ProfileNotes = ({ profile_id, authenticity_token, load_on_demand = false }) => {
  // var Spinner = require('react-spinkit');

  const [state, setState] = React.useState({ notes: [], loaded: false });

  const loadNotesCallback = () => {
    getNotes();
  }

  const getNotes = () => {
    get_notes_api({
      profile_id: profile_id,
      callback: notes => {
        setState({
          ...state,
          notes: notes.filter(note => note.profile_id === profile_id),
          loaded: true
        })
      }
    });
  }

  React.useEffect(() => {
    if (load_on_demand) getNotes();
  }, []);

  const { notes, loaded } = state;

  return (
    <div className="sixteen columns grey skill-widget">
      <div className="padding-right">
        {notes.length < 1 && !loaded && (
          <center>
            {/* <Spinner name="line-scale" color="#186fc9" /> */}
            Loading...
          </center>
        )}
        {notes.length < 1 && loaded && (
          <p>Save a note to remember important details about this candidate.</p>
        )}

        <p className="margin-reset">
          {notes.length > 0 && (
            <p>
              <strong>{moment(notes[0].created_at).format('llll z')}:</strong> {notes[0].note}
            </p>
          )}
        </p>

        <div className="d-flex flex-row justify-content-between gap-1">
          <AllNotes authenticity_token={authenticity_token} loadNotesCallback={loadNotesCallback} profile_id={profile_id} notes={notes} />
          <NewNote profile_id={profile_id} authenticity_token={authenticity_token} loadNotesCallback={loadNotesCallback} />
        </div>
      </div>
    </div>
  )
}

const AllNotes = ({ profile_id, notes, authenticity_token, loadNotesCallback }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <a className="btn btn-light btn-rounded flex-grow-1 mx-1" style={{ zIndex: 1 }} onClick={openModal}>
        <i className="fa fa-book"></i> All Notes
      </a>
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-book"></i> All Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="small-dialog-content" style={{ maxHeight: 350, overflowY: 'scroll' }}>
            {notes.map(note => (
              <p><strong>{moment(note.created_at).format('llll z')}</strong>: {note.note}</p>
            ))}
          </div>
          <center>
            <NewNote profile_id={profile_id} authenticity_token={authenticity_token} loadNotesCallback={loadNotesCallback} closeNotesCallback={close} />
          </center>
        </Modal.Body>
      </Modal>
    </>
  )
}

const NewNote = ({ profile_id, authenticity_token, loadNotesCallback, closeNotesCallback }) => {
  const [state, setState] = React.useState({ note: '', submitted: false })

  const [modalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const set_note = (event) =>
    setState({ ...state, note: event.target.value })

  const submit_note = (callback, close) => {
    const url = `/profile_notes.json`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticity_token
      },
      body: JSON.stringify({
        profile_id,
        note: state.note
      })
    })
      .then(res => res.json())
      .then(res => {
        setState({ ...state, note: '', submitted: true })
        return res;
      })
      .then(res => {
        callback();
        closeModal();
        if (closeNotesCallback) closeNotesCallback();
      })
  }
  return (
    <>
      <a className="btn btn-light btn-rounded flex-grow-1 mx-1" style={{ zIndex: 1 }} onClick={openModal}>
        <i className="fa fa-plus"></i> New Note
      </a>
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-plus"></i> New
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <strong><i className="fa fa-lock"></i> Notes are private and only visible to your team</strong>

          <div className="text-center mt-3">
            <textarea value={state.note} placeholder={`Type message here...`} onChange={set_note} name="note" id="profile_notes[note]" cols="30" rows="10"
              className="form-control"
            ></textarea>
            <button style={{ marginTop: 15 }} className="btn btn-primary btn-rounded"
              onClick={() => submit_note(loadNotesCallback, close)}>Save Note</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProfileNotes;