import * as React from 'react';

interface ProductProps {
  id: number;
  name: string;
  price: string;
  savings_price?: string;
  features?: string;
  trial?: boolean;
  product_name: string;
  tagline: string;
  savings: string;
  product_type?: string;
  stripe_plan: string;
  form_authenticity_token?: string;
  button?: boolean
  user_signed_in?: boolean
  stripe_price?: any
  graduated_price?: number
  graduated?: boolean
  coupons?: any
  single?: boolean
  term?: string
}

export default function Product({ name, price, id, savings_price, features, trial, product_name, tagline, savings, product_type = 'plan', stripe_plan, form_authenticity_token, user_signed_in = false, stripe_price, graduated_price, graduated, coupons, single = false, term
}: ProductProps) {

  const plan_features: Array<string> = features.split('|');

  // State to store quantity
  const [quantity, setQuantity] = React.useState(1);

  // Ensure quantity is always >= 1
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Allow newQuantity to be blank
    // const newQuantity = parseInt(e.target.value);
    // if (newQuantity >= 1) {
    // setQuantity(newQuantity);
    // }
    setQuantity(parseInt(e.target.value));
  };

  // Button Text "Start Trial"
  const buttonText = trial ? 'Start Trial' : 'Continue';

  // Calculate Price * Quantity
  // Price as number
  const price_num = parseFloat(price);
  const totalPrice = (graduated && quantity > 1)
    ? ((quantity - 1) * graduated_price) + price_num // If graduated and quantity > 1
    : (price_num * (quantity || 1)); // Otherwise, use price_num * quantity (or 1 if quantity is not defined)

  // Check if subscription based on whether or not "term" has the word Once
  const subscription = term.includes('Once') ? false : true;

  // Remove the word Once from term
  const term_text = term.replace('Once', '');

  // Check if features includes 'feature === 'Unlimited Access'
  const unlimitedAccess = plan_features.includes('Unlimited Access');
  const quantity_name = unlimitedAccess ? "Location" : "Jobs";

  // If unlimitedAccess and quantity > 4
  const call_now = unlimitedAccess && quantity > 4;

  return (
    <div className="col-12">
      <div className="d-flex align-items-center justify-content-center flex-column flex-md-row pt-1">
        <div className="">
          <div className="card text-start mb-2 pb-5" id="blueCard" style={{ height: "unset" }}>
            <div className="ps-5 pt-5" id="essCardText">
              <h2>{name}</h2>
              {plan_features.map((feature, index) => {
                {/* Unlimited Access */ }
                // If feature is "Unlimited Access"
                if (feature === 'Unlimited Access') {
                  return (
                    <p key={index}>
                      &#8226; Unlimited Access for {quantity} Location(s)* <br />
                      <small style={{ fontSize: 11 }}>* Additional Locations Can Be Added At Reduced Price</small>
                    </p>
                  )
                } else {
                  return (
                    <p key={index}>&#8226; {feature}</p>
                  )
                }
              })}
            </div>
            <div className="text-center mx-auto" style={{ width: '80%' }}>
              <form action="/create_quote" method="post">
                {/* Auth Token */}
                <input name="authenticity_token" value={form_authenticity_token} type="hidden" />
                <input name="price" value={stripe_price.id} type="hidden" />
                <input name="quantity" value={quantity || 1} type="hidden" />

                {/* Hidden Field Purchase Name */}
                {/* Subscription - Free Trial or Subscription - Restart */}
                {subscription && <input
                  type="hidden"
                  name="purchase_name"
                  value={`Subscription - ${trial ? "Free Trial" : "Restart"}`}
                />
                }
                {/* Single Purchase */}
                {!subscription && <input type="hidden" name="purchase_name" value={`Purchase - ${product_name}`} />}

                {/* Hidden Field Purchase Amount */}
                <input type="hidden" name="purchase_amount" value={totalPrice} />


                {/* Quantity input field */}
                {!single && !trial && (
                  <div className="form-group">
                    <label htmlFor="quantity">Number of {quantity_name}</label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      value={quantity}
                      onChange={handleQuantityChange}
                    />
                  </div>
                )}

                {/* Output Price * Quantity */}
                {/* {!trial && */}
                <div className="form-group mt-2">
                  {/* <label htmlFor="quantity">Total</label> */}
                  <input type="hidden" className="form-control mb-1" id="total" value={totalPrice.toFixed(2)} disabled />
                  <p>
                    <span className="fw-bold" id="essPriceSm" style={{ fontSize: '2.5rem' }}>
                      ${totalPrice} {term_text}
                    </span>
                    {trial && (
                      <>
                        <br />
                        <span className="">
                          After 7 Day Free Trial
                        </span>
                      </>
                    )}
                  </p>
                </div>
                {/* } */}

                {/* Button to go to checkout */}
                {!call_now && <button className="btn text-white btn-orange" id="essBtnGreen">
                  {buttonText}
                </button>}
                {call_now && (
                  <a href="tel:18007992644" className="btn text-white" id="essBtnGreen">
                    Please Call for Special Pricing
                  </a>
                )}
              </form>
            </div>
          </div>
          {unlimitedAccess && (
            <div className="text-center">
              <a href="/plans#essential" className="text-center text-decoration-none">View All Pricing Plans</a>
            </div>
          )}
        </div>
      </div>

    </div>
  );
}
