import React from "react"
import PropTypes from "prop-types"
import 'react-app-polyfill/ie9';

class TempProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked_checkbox: true,
            show_more: false
        }
    }

    toggleCheckbox = () => {
        if (this.props.is_form) {
            this.setState({ checked_checkbox: !this.state.checked_checkbox });
        }
    }

    toggleMore = () => this.setState((state, props) => ({ show_more: !state.show_more }));

    render() {

        let mapMarker = (
            <span>
                <i className="fa fa-map-marker"></i> {this.props.address}
            </span>
        );

        let moneyIcon;
        if (this.props.compensation) {
            moneyIcon = (
                <span>
                    <i className="fa fa-money"></i> {this.props.compensation}
                </span>
            );
        }

        let checkbox;
        if (this.props.is_form) {
            checkbox = (
                <label className="checkbox-container" >
                    <input type="checkbox"
                        name="temp_requests[]"
                        id="temp_requests_"
                        value={this.props.profile_id}
                        checked={this.state.checked_checkbox}
                        readOnly={true} />
                    <span className="checkmark" onClick={this.toggleCheckbox}></span>
                </label>
            );
        }

        let distance;
        if (this.props.distance) {
            distance = (
                <span>
                    <i className="fa fa-car"></i>
                    {this.props.distance}
                </span>
            );
        }

        let availability;
        if (this.props.availabilityDay) {
            availability = (
                <span>
                    <i className="fa fa-clock-o"></i>
                    <strong>{this.props.availabilityDay}</strong>: {this.props.availabilityStart} to {this.props.availabilityEnd}
                </span>
            );
        }

        let optionsCursorTrueWithMargin = {
            followCursor: false,
            // shiftX: 0,
            // shiftY: 
        }

        let phone;
        if (this.props.phone === "undefined" || this.props.phone == false) {
            phone = (<strong></strong>);
        } else {
            phone = (<span><i className="fa fa-phone"></i> {this.props.phone}</span>)
        }

        let jobListContent;

        if (this.state.show_more == true) {
            jobListContent = (
                <div className="job-list-content">

                    <h4 className="fullnamer">
                        {this.props.fullname}
                        {/* <small>{this.props.job_types}</small> */}
                        {this.state.checked_checkbox}
                        <a className="button temp_profile"
                            target="_blank"
                            href={`/profiles/${this.props.profile_id}`}
                        >
                            View Profile
                </a>
                        {/* <a className="button temp_profile"
                            target="_blank"
                            onClick={() => this.toggleMore()}
                        >
                            More
                </a> */}
                    </h4>


                    <div className="job-icons">
                        {availability}
                    </div>
                    <br />

                    <div className="job-icons">
                        {mapMarker}
                        {distance}
                        {moneyIcon}
                        {phone}
                    </div>

                    <h4 className="tags-no-padding" dangerouslySetInnerHTML={{ __html: this.props.position_type_tags + this.props.tags_for_profile }} >
                    </h4>
                    {/* <div className="tags-no-padding">
                        {this.props.description}
                    </div> */}

                </div>
            );
        } else {
            jobListContent = (
                <div className="job-list-content">

                    <h4 className="fullnamer">
                        {this.props.fullname}
                        {/* <small>{this.props.job_types}</small> */}
                        {this.state.checked_checkbox}
                        <a className="button temp_profile"
                            target="_blank"
                            href={`${this.props.profile_url}`}
                        >
                            View Profile
                    </a>
                        {/* <a className="button temp_profile"
                            target="_blank"
                            onClick={() => this.toggleMore()}
                        >
                            More
                    </a> */}
                    </h4>


                    <div className="job-icons">
                        {availability}
                        {this.props.job_types.map(jt => <span><i className="fa fa-tooth"></i>{jt}</span>)}
                    </div>
                    <br />

                    <div className="job-icons">
                        {mapMarker}
                        {distance}
                        {moneyIcon}
                        {phone}
                    </div>

                    <h4 className="tags-no-padding" dangerouslySetInnerHTML={{ __html: this.props.position_type_tags + this.props.shortened_tags_for_profile }} >
                    </h4>

                </div>
            );
        }

        let request_status;
        if (this.props.request_status == "") {
            request_status = (<div></div>);
        } else {
            request_status = (
                <a className={`button gray pull-right ${this.props.request_status}`} style={{ width: '100%', textAlign: 'right', width: 'auto' }}>{this.props.request_status_readable}</a>
            );
        }



        let profile = (
            <div className="field temp_profile">
                <li>
                    {checkbox}
                    <a>
                        <img className="pull-left" style={{ marginRight: 20 }} src={this.props.profile_image} alt="" />
                        {jobListContent}
                        {request_status}
                    </a>
                    <div className="clearfix"></div>
                </li>
            </div>
        );

        return (
            <React.Fragment>
                {profile}
            </React.Fragment>
        );
    }
}

TempProfile.propTypes = {
    profile: PropTypes.object
};

export default TempProfile
