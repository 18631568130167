import * as React from "react";
import * as CSS from "csstype";

interface Profile {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  zip: string;
  image: string;
  url: string;
  compensation: string | number;
  job_types: Array<any>;
  skills: Array<any>;
  position_types: Array<any>;
}

interface Job {
  [prop: string]: any;
}

interface InviteProfileProps {
  profile: Profile;
  job: Job;
  requested: Array<number>;
  accepted: Array<number>;
  declined: Array<number>;
  authenticity_token: string;
  callback: () => any;
}

interface ButtonFunctionArguments {
  profile: Profile;
  job: Job;
  callback: (id: number) => any;
  authenticity_token: string;
  finishLoading: () => any;
}

export default function InviteProfile({
  profile,
  job,
  requested = [],
  accepted = [],
  declined = [],
  callback,
  authenticity_token,
}: InviteProfileProps) {
  // var Spinner = require("react-spinkit");

  const [state, setState] = React.useState({ loading: false });

  const finishLoading = () => setState({ ...state, loading: false });

  const is_temp: boolean = job.position_type == "temp";

  const requestedButtonText: string = is_temp ? "Requested" : "Invited";
  const buttonText: string = is_temp ? "Request Temp" : "Invite to Apply";
  const acceptedButtonText: string = is_temp ? "Accepted" : "Applied";
  // const buttonColor: string = is_temp ? "#186fc9" : "#186fc9";

  const buttonFunctionArguments: ButtonFunctionArguments = {
    profile,
    job,
    callback,
    authenticity_token,
    finishLoading,
  };

  const buttonFunction: () => any = is_temp
    ? () => requestTemp(buttonFunctionArguments)
    : () => inviteToApply(buttonFunctionArguments);

  const buttonOnClick = (event) => {
    setState({ ...state, loading: true });
    event.stopPropagation();
    buttonFunction();
  };

  const profileRequested = requested.includes(profile.id);
  const profileAccepted = accepted.includes(profile.id);
  const profileDeclined = declined.includes(profile.id);

  const requestedButton =
    !state.loading && profileRequested && !(profileAccepted || profileDeclined);

  const acceptedButton = !state.loading && profileAccepted;

  const declinedButton = !state.loading && profileDeclined;

  const requestTempButton =
    !state.loading &&
    !job.closed &&
    !job.temp_filled &&
    is_temp &&
    !profileRequested;

  const inviteToApplyButton =
    !state.loading &&
    !job.closed &&
    !job.temp_filled &&
    !is_temp &&
    !profileRequested &&
    !profileAccepted;

  const classNames = "bg-dd-reversed text-decoration-none rounded-pill px-2 py-1 me-1 mb-1 pull-md-right pull-none ms-0";

  return (
    <>
      {state.loading && (<>Loading</>)}
      {requestedButton && (
        <a className={classNames} style={requestedButtonStyle}>
          <i className="fad fa-check"></i> {requestedButtonText}
        </a>
      )}
      {acceptedButton && (
        <a className={classNames} style={acceptedButtonStyle}>
          <i className="fad fa-file-user" style={{ color: "green" }}></i>{" "}
          <strong>{acceptedButtonText}</strong>
        </a>
      )}
      {declinedButton && (
        <a className={classNames} style={declinedButtonStyle}>
          <i className="fad fa-times" style={{ color: "#a00606" }}></i> Declined
        </a>
      )}
      {requestTempButton && (
        <a
          className={classNames}
          style={buttonStyle}
          onClick={(event) => buttonOnClick(event)}
        >
          <i className="fad fa-user-plus"
          // style={{ color: buttonColor }}
          ></i>{" "}
          {buttonText}
        </a>
      )}
      {inviteToApplyButton && (
        <a
          className={classNames}
          style={buttonStyle}
          onClick={(event) => buttonOnClick(event)}
        >
          <span className="">
            <i className="fad fa-user-plus"
            // style={{ color: buttonColor }}
            ></i>{" "}
            {buttonText}
          </span>
        </a>
      )}
    </>
  );
}

const requestTemp = ({
  profile,
  job,
  callback,
  authenticity_token,
  finishLoading,
}: ButtonFunctionArguments) => {
  const url = `/jobs/${job.id}/request.json`;

  const options: object = {
    method: "POST",
    redirect: "manual",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify({ temp_requests: [`${profile.id}`] }),
  };

  fetch(url, options)
    .then((result) => {
      console.log(result);
      return result.json()
    })
    .then(
      (result) => {
        callback(result[0].id);
        console.log(result);
        finishLoading();
      },
      (error) => console.log("Error", error)
    );
};

const inviteToApply = ({
  profile,
  job,
  callback,
  authenticity_token,
  finishLoading,
}: ButtonFunctionArguments) => {
  const url = `/jobs/${job.id}/invite.json`;

  const options: object = {
    method: "POST",
    redirect: "manual",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify({ invitees: [`${profile.id}`] }),
  };

  fetch(url, options)
    .then((result) => result.json())
    .then(
      (result) => {
        callback(result[0].profile_id);
        finishLoading();
      },
      (error) => console.log(error)
    );
};

const buttonStyle: CSS.Properties = {
  textAlign: "center",
  margin: "12px",
};

const spinnerStyle: CSS.Properties = {
  paddingRight: "15px",
  paddingTop: "10px",
};

const requestedButtonStyle: CSS.Properties = {
  ...buttonStyle,
  background: "#fff",
  color: "black",
  border: "none",
};

const acceptedButtonStyle: CSS.Properties = {
  ...buttonStyle,
  background: "white",
  color: "green",
  border: "none",
};

const declinedButtonStyle: CSS.Properties = {
  ...buttonStyle,
  background: "white",
  color: "#a00606",
  border: "none",
};
