import * as React from "react";

import { ProfileFormContext } from "../../ProfileEditForm";
import { ACTIONS } from "../ProfileFormReducer";

// Rich Text Editor
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import RichTextareaEditor from "../../../FormComponents/RichTextareaEditor";
import { initialEditorState } from "../../../FormComponents/RichTextareaEditor";

const BlankEducationItem = {
  school_name: "",
  qualification: "",
  description: "",
  start_date: "",
  end_date: "",
  current: false,
  editing: true,
};

export default function EducationHistory() {
  const [state, dispatch] = React.useContext(ProfileFormContext);

  // Add new education item
  const addEducationItem = () => {
    dispatch({
      type: ACTIONS.ADD_EDUCATION,
      payload: BlankEducationItem,
    });
  };

  // Remove education item by index
  const removeEducationItem = (index: number) => {
    dispatch({
      type: ACTIONS.REMOVE_EDUCATION,
      payload: index,
    });
  };

  // Update education item by id
  const updateEducationItem = (index: number, education: any) => {
    dispatch({
      type: ACTIONS.UPDATE_EDUCATION,
      payload: { index, education },
    });
  };

  // Edit Education item by index
  const editEducationItem = (index: number) => {
    dispatch({
      type: ACTIONS.EDIT_EDUCATION,
      payload: index,
    });
  };

  // Unedit Education item by index
  const uneditEducationItem = (index: number) => {
    dispatch({
      type: ACTIONS.UNEDIT_EDUCATION,
      payload: index,
    });
  };

  // Console log after state changes
  React.useEffect(() => {
    console.log(state.profile.educations);
  }, [state.profile.educations]);

  return (
    <div key={state}>
      {state.profile.educations.map((education, index) => (
        <div key={index} className="shadow m-3 p-3 bg-light bg-gradient">
          <button
            type="button"
            className="btn btn-danger pull-right"
            onClick={() => removeEducationItem(index)}
          >
            <i className="fas fa-trash"></i>
          </button>

          {education.editing ? (
            <button
              type="button"
              className="btn btn-success pull-right me-1"
              onClick={() => uneditEducationItem(index)}
            >
              <i className="fas fa-check"></i>
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-warning pull-right me-1"
              onClick={() => editEducationItem(index)}
            >
              <i className="fas fa-edit"></i>
            </button>
          )}

          <EducationItem
            education={education}
            updateEducationItem={(e) => updateEducationItem(index, e)}
            editing={education.editing}
            key={index}
          />
        </div>
      ))}
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={addEducationItem}
        >
          Add Education
        </button>
      </div>
    </div>
  );
}

function EducationItem({ education, updateEducationItem, editing }) {
  const [educationState, setEducationState] = React.useState(education);
  const {
    school_name,
    qualification,
    description,
    start_date,
    end_date,
    current,
  } = educationState;

  const [editorState, setEditorState] = React.useState(
    initialEditorState(description),
  );

  const onChangeEditor = (editorState: any) => setEditorState(editorState);

  const updateEducationState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEducationState({
      ...educationState,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  React.useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    setEducationState({
      ...educationState,
      description: markup,
    });
  }, [editorState]);

  React.useEffect(() => {
    setEducationState(education);
  }, [education]);

  React.useEffect(() => {
    console.log("Education State Changed", educationState);
    updateEducationItem(educationState);
  }, [educationState]);

  return (
    <>
      {editing ? (
        <div className="mt-4">
          <div className="form-group">
            <label htmlFor="school_name">School Name</label>
            <input
              type="text"
              className="form-control"
              id="school_name"
              name="school_name"
              value={school_name}
              onChange={updateEducationState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="qualification">Qualification</label>
            <input
              type="text"
              className="form-control"
              id="qualification"
              name="qualification"
              value={qualification}
              onChange={updateEducationState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="start_date">Start Date</label>
            <input
              type="date"
              className="form-control"
              id="start_date"
              name="start_date"
              value={start_date}
              onChange={updateEducationState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="end_date">End Date</label>
            <input
              type="date"
              className="form-control"
              id="end_date"
              name="end_date"
              value={end_date}
              onChange={updateEducationState}
              disabled={current}
            />
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="current"
              name="current"
              checked={current}
              onChange={updateEducationState}
            />
            <label className="form-check-label" htmlFor="current">
              Current
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <RichTextareaEditor
              editorState={editorState}
              onChangeDescription={onChangeEditor}
            />
          </div>
        </div>
      ) : (
        <EducationPreview education={educationState} />
      )}
    </>
  );
}

const EducationPreview = ({ education }) => {
  const {
    school_name,
    qualification,
    description,
    start_date,
    end_date,
    current,
  } = education;

  return (
    <div>
      <h5>
        <strong>{qualification}</strong>
      </h5>
      <h6>{school_name}</h6>
      <h6>
        {start_date} - {current ? "Present" : end_date}
      </h6>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};
