import * as React from "react";

interface LocationProps {
  city: string;
  state: string;
  zip: string;
}

const ProfileLocation = ({ city, state, zip }: LocationProps) => {
  return (
    <>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-md-none col-12">
        <i className="fa fa-map-marker"></i> {city}, {state} {zip}
      </span>
      <span className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
        <i className="fa fa-map-marker"></i> {city}, {state} {zip}
      </span>
    </>
  );
};

export default ProfileLocation;
