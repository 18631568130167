import React, { useState } from 'react';
import loadImage from 'blueimp-load-image';
import PinturaPopup from './PinturaPopup';

const ProfilePhoto = (props) => {
  const [src, setSrc] = useState(null);
  const [cropComplete, setCropComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      loadImage(e.target.files[0], (img, data) => {
        img.toBlob((a) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => setSrc(reader.result));
          reader.readAsDataURL(a);
        });
      }, { maxWidth: 2000, canvas: true, meta: true, orientation: true });
    }

    setCropComplete(false);
  };

  const isCropping = !cropComplete && src;

  return (
    <div>
      <div>
        {!isCropping && !loading && (
          <>
            <p><small>(Tip: A photo helps your profile standout)</small></p>
            <input type="file" onChange={onSelectFile} id="avatar_field_original" />
            <input type="hidden" id="avatar_field" name="profile[avatar]" value={src} />
          </>
        )}
      </div>

      {!cropComplete && src && (
        <div>
          <p><small>Drag the corners and click Crop when you're finished.</small></p>
          <PinturaPopup
            avatar={src}
            setImageState={(img) => setSrc(img)}
            updateProfilePhoto={(base64data) => {
              setLoading(true);
              updateProfilePhoto(
                base64data,
                props.profile.id,
                props.authenticity_token,
                (e) => {
                  setLoading(false);
                  props.callback(e);
                }
              );
            }}
          />
        </div>
      )}

      {/* Loading */}
      <div className="text-center">
        {loading && (
          <div className="py-3">
            <h5 className="text-center pt-2">Saving Photo...</h5>
          </div>
        )}
      </div>
    </div>
  );
};

// Function to send put request to update profile photo
const updateProfilePhoto = (photo, id, authenticity_token, callback) => {
  console.log("Updating Profile Photo", { photo, id, authenticity_token, callback });
  const url = `/profiles/${id}.json`;
  const method = "put";
  const data = { profile: { avatar: photo } };

  fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log("Response:", response)
      if (response.ok) {
        return response.json()
      }
      // throw new Error("Network response was not ok.")
    })
    .then((response) => {
      console.log("Success:", response)
      callback(response.image)
    })
    .catch((error) => console.error("Error:", error))
}

export default ProfilePhoto;