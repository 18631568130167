import React from "react"
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js"
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js"
import "draft-js/dist/Draft.css"

const buttonClass = "button"

class DraftTextArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editorState: EditorState.createEmpty() }
    this.focus = () => this.refs.editor.focus()

    this.handleKeyCommand = this._handleKeyCommand.bind(this)
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this)
    this.toggleBlockType = this._toggleBlockType.bind(this)
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this)
    this.handlePastedText = this._handlePastedText.bind(this);
  }

  componentDidMount() {
    this.initialize()
  }

  // componentDidUpdate(prevProps, prevState) {
  //     // if (prevProps.value != this.props.value) this.initialize();
  // }

  initialize = () => {
    const markdownString = this.props.value
      .replace(/\[u\]/g, "")
      .replace(/\[\/u\]/g, "")
      .replace(/\[ul\]/g, "")
      .replace(/\[\/ul\]/g, "")
      .replace(/\[ol\]/g, "")
      .replace(/\[\/ol\]/g, "")
      .replace(/\[i\]/g, "_")
      .replace(/\[\/i\]/g, "_")
      .replace(/\[b\]/g, "*")
      .replace(/\[\/b\]/g, "*")
      .replace(/\[li\]/g, "- ")
      .replace(/\[\/li\]/g, "")

    const rawData = markdownToDraft(markdownString)
    const contentState = convertFromRaw(rawData)
    const newEditorState = EditorState.createWithContent(contentState)

    this._onChangeDescription(newEditorState)
  }

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this._onChangeDescription(newState)
      return true
    }
    return false
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */
      )
      if (newEditorState !== this.state.editorState) {
        this._onChangeDescription(newEditorState)
      }
      return
    }
    return getDefaultKeyBinding(e)
  }

  _toggleBlockType(blockType) {
    this._onChangeDescription(
      RichUtils.toggleBlockType(this.state.editorState, blockType)
    )
  }

  _toggleInlineStyle(inlineStyle) {
    this._onChangeDescription(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    )
  }

  _onChangeDescription = (editorState) => {
    this.setState({ editorState }, () => {
      const editorContent = this.state.editorState.getCurrentContent()
      const rawObject = convertToRaw(editorContent)
      const content = draftToMarkdown(rawObject)
      this.setState({ content })

      if (this.props.updateFunc) {
        this.props.updateFunc(content)
      }
    })
  }

  _handlePastedText(text) {
    // Remove all unicode characters, bullets, and similar
    const cleanText = text.replace(/[\u200B-\u200D\uFEFF]/g, '');

    // Convert all content to escaped characters
    const escapedText = cleanText.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });

    // Remove any & entities like #10625;
    // If there is a space that was following, remove it too
    const cleanEscapedText = escapedText.replace(/&#[0-9]+; /g, '');
    const cleanEscapedText2 = cleanEscapedText.replace(/&#[0-9]+;/g, '');

    // Convert back
    const unescapedText = cleanEscapedText2.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });

    const newEditorState = EditorState.push(
      this.props.editorState,
      ContentState.createFromText(unescapedText),
      'insert-fragment'
    );

    this.props.onChangeDescription(newEditorState);
    return 'handled';
  }

  render() {
    const { rich, name, singleLine } = this.props
    const { editorState, content } = this.state
    let className =
      "RichEditor-editor form-control settings-text-block px-1 py-1"
    const contentState = editorState.getCurrentContent()
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        // className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div>
        <div>
          {rich && (
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
          )}
          {rich && (
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
          )}
        </div>
        <div className={className} onClick={this.focus}>
          <Editor
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this._onChangeDescription}
            placeholder="Tell a story..."
            ref="editor"
            spellCheck={true}
            className="profile-exp-editor"
            handlePastedText={this.handlePastedText}
          />
          <input type="hidden" name={name} value={content} />
        </div>
      </div>
    )
  }
}

// Other Stuff

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    paddingY: 2,
    paddingX: 5,
  },
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote"
    default:
      return null
  }
}

class StyleButton extends React.Component {
  constructor() {
    super()
    this.onToggle = (e) => {
      e.preventDefault()
      this.props.onToggle(this.props.style)
    }
  }

  render() {
    let className = "RichEditor-styleButton btn text-block-style mx-1 my-1"
    if (this.props.active) {
      className += " RichEditor-activeButton btn text-block-style mx-1 my-1"
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    )
  }
}

const BLOCK_TYPES = [
  // { label: 'H1', style: 'header-one' },
  // { label: 'H2', style: 'header-two' },
  // { label: 'Header 1', style: 'header-three' },
  // { label: 'Header 1', style: 'header-four' },
  // { label: 'Header 2', style: 'header-five' },
  // { label: 'Header 3', style: 'header-six' },
  // { label: 'Quote', style: 'blockquote' },
  { label: "Bullet List", style: "unordered-list-item" },
  { label: "Numbered List", style: "ordered-list-item" },
  // { label: 'Code Block', style: 'code-block' },
]

const BlockStyleControls = (props) => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
]

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

export default DraftTextArea
