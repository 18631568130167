import * as React from 'react';

// Experience Select Component
export default function ExperienceSelect({ years_experience, setField }) {
  return (
    <select
      className="form-select"
      id="years_experience"
      name="years_experience"
      onChange={setField}
      value={years_experience}
    >
      <option value="">Select Years of Experience</option>
      {experience_required_opts.map((s) => (
        <option key={s.id} value={s.id} selected={s.id == years_experience}>{s.name}</option>
      ))}
    </select>
  )
}


{/* Years Experience Options */ }
const experience_required_opts = [
  {
    id: '0-2',
    name: '0-2 Years'
  },
  {
    id: '2-5',
    name: '2-5 Years'
  },
  {
    id: '5+',
    name: '5+ Years'
  }
]