import * as React from 'react'
import Cookies from 'universal-cookie';
import CardBox from '../../Interface/CardBox'
import RecruiterServiceCartReducer from './RecruiterServiceCartReducer'
import RecruiterServiceCartItems from './RecruiterServiceCartItems'
import RecruiterServiceCartProducts from './RecruiterServiceCartProducts'

const initialState = { items: [] }

const cookies = new Cookies();

export default function RecruiterServiceCart({
  products,
  job_types,
  create_quote_path,
  form_authenticity_token,
  return_path,
  cancel_url
}) {
  // Get contents of cookies and if there is no cart, set it initialState
  const cart = cookies.get('cart') || initialState

  const [state, dispatch] = React.useReducer(RecruiterServiceCartReducer, cart)

  const [jobType, setJobType] = React.useState(job_types[0])

  const addItem = (product, jobType) =>
    dispatch({ type: 'ADD_ITEM', payload: { product, jobType } })

  const removeItem = (item) =>
    dispatch({ type: 'REMOVE_ITEM', payload: item })

  const updateQuantity = (id, quantity) =>
    dispatch({ type: 'UPDATE_QUANTITY', payload: { id, quantity } })

  const updateJobType = (id, jobType) =>
    dispatch({ type: 'UPDATE_JOB_TYPE', payload: { id, jobType } })

  const updateProduct = (id, product) =>
    dispatch({ type: 'UPDATE_PRODUCT', payload: { id, product } })

  // Method to return item premium_price if premium = true and item price if premium = false or undefined
  const getItemPrice = (item) => item.jobType.premium
    ? item.product.premium_price
    : item.product.price

  // getItemPlan
  // If the item is premium, return the premium_id of the product
  // If the item is not premium, return the stripe_plan of the product
  const getItemPlan = (item) => item.jobType.premium
    ? item.product.premium_id
    : item.product.stripe_plan

  // Create an array of items for stripe checkout
  const stripeItems = state.items.map(item => ({
    price: getItemPlan(item),
    quantity: item.quantity
  }))

  // Subtotal
  const subtotal = state.items.reduce((total, item) =>
    total + getItemPrice(item) * item.quantity, 0)

  // Button function that takes you back to cancel_url
  const goBack = (e) => {
    e.preventDefault()
    window.location.href = cancel_url
  }

  // Form a request for create_quote_path
  const request = {
    authenticity_token: form_authenticity_token,
    return_path: return_path,
    purchase_name: 'Recruiter Service',
    purchase_amount: subtotal,
    prices: stripeItems
  }

  // Save contents of cart into cookies
  React.useEffect(() => {
    cookies.set('recruiter_service_cart', state, { path: '/' })
  }, [state])

  return (
    <div>
      <div className="d-flex flex-column flex-md-row gap-3">
        <CardBox title="Recruiter Services" grow={true} alignLastChildBottom={true}>
          {/* Line Items */}
          <RecruiterServiceCartItems
            state={state}
            removeItem={removeItem}
            updateJobType={updateJobType}
            updateProduct={updateProduct}
            job_types={job_types}
            products={products}
            getItemPrice={getItemPrice}
          />

          {/* Subtotal Lines */}
          <div className="text-end bt-1 px-2">
            <span className="fw-bold ddblue">
              Subtotal: ${subtotal}
            </span>
          </div>
          <div className="text-end px-2 py-2">
            {/* Checkout Button */}
            <form action={create_quote_path} method="post">
              <input type="hidden" name="authenticity_token" value={request.authenticity_token} />
              <input type="hidden" name="return_path" value={request.return_path} />
              <input type="hidden" name="purchase_name" value={request.purchase_name} />
              <input type="hidden" name="purchase_amount" value={request.purchase_amount} />
              {request.prices.map((price, index) => (
                <input key={index} type="hidden" name="prices[]" value={price.price} />
              ))}
              <button className="btn btn-light btn-lg btn-block pull-left" onClick={goBack}>Back</button>
              <button type="submit" className="btn btn-primary btn-lg btn-block">Checkout</button>
            </form>

          </div>
        </CardBox>
        <div className="col-12 col-md-3">
          <CardBox title="Add Services">
            <RecruiterServiceCartProducts
              products={products}
              job_types={job_types}
              jobType={jobType}
              setJobType={setJobType}
              addItem={addItem}
            />
          </CardBox>
          {/* <p>Need more than what's in your cart? You can add additional services here.</p> */}
        </div>


      </div>

    </div>
  )
}