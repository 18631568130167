import * as React from 'react'
import { Modal } from 'react-bootstrap'
import ProfilePhoto from './ProfilePhoto'

import { ProfileFormContext } from '../ProfileEditForm'
import { ACTIONS } from './ProfileFormReducer'
import PinturaPopup from './PinturaPopup'


const submitProfileAvatarDeleteField = (profile, dispatch, authenticity_token, setFiles) => {
  const profileData = {
    delete_avatar: '1',
    profile: {
      delete_avatar: '1',
    }
  }

  const url = `/profiles/${profile.id}.json`
  const method = "PATCH"
  const headers = {
    "X-CSRF-Token": authenticity_token,
    "Content-Type": "application/json",
  }

  fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(profileData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      // throw new Error("Network response was not ok.")
    })
    .then((response) => {
      console.log("Success:", response)
      // Dispatch SET_FIELD avatar to null
      dispatch({
        type: ACTIONS.SET_FIELD,
        payload: { avatar_url: "https://directdental-production.s3.us-west-2.amazonaws.com/missing/missing.png" },
      })
      setFiles([1])
    })
    .catch((error) => console.error("Error:", error))
}

export default function PhotoPopup() {
  // Show Popup State
  const [modalOpen, setModalOpen] = React.useState(false)

  // Image Error State
  const [imageError, setImageError] = React.useState(false)

  // Context
  const [state, dispatch] = React.useContext(ProfileFormContext)

  // Get Profile Avatar
  const avatar = state.profile.avatar_url

  console.log('Avatar', avatar)

  // closeModal
  const closeModal = () => setModalOpen(false)
  const openModal = () => setModalOpen(true)

  // Set Avatar URL
  const setAvatarURL = (url) => {
    dispatch({
      type: ACTIONS.SET_FIELD,
      payload: { avatar_url: url }
    })

    closeModal()
  }

  // Check to see if avatar resolves to 404
  const checkAvatar = () => {
    var img = new Image()

    img.onload = function () {
      console.log('Image Loaded')
      setImageError(false)
    }

    img.onerror = function () {
      console.log('Image Failed to Load')
      setImageError(true)
    }

    img.src = avatar
  }

  // Run checkAvatar once on mount
  React.useEffect(() => checkAvatar(), [])

  return (
    <>
      <div className="row pt-5">
        {/* Upload a Photo label */}
        <div className="col-12 mb-3">
          <label htmlFor="avatar_url" className="form-label">
            Upload a Profile Photo
          </label>
        </div>

        {/* Show Avatar if it exists */}
        {avatar && (<img src={avatar} className="profile-photo rounded-circle img-fluid m-auto col-6" onClick={openModal} />)}

        {/* Click to Edit */}
        <div className="text-center pt-3 pb-5">
          <div className="profile-photo btn btn-primary mx-1" onClick={openModal}>
            <i className="fas fa-camera" /> Upload a Photo
          </div>
          {avatar && (
            <div className="profile-photo btn btn-danger mx-1" onClick={() => submitProfileAvatarDeleteField(state.profile, dispatch, state.authenticity_token, undefined)}>
              <i className="fas fa-trash" /> Delete Photo
            </div>
          )}
        </div>

        {/* Delete Button */}

      </div>

      {/* Bootstrap Modal */}
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-book"></i> Profile Photo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfilePhoto profile={state.profile} authenticity_token={state.authenticity_token} callback={setAvatarURL} />
        </Modal.Body>
      </Modal>
    </>
  )
}