import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Applicants from './Applicants';
import ApplicantInvitations from './ApplicantInvitations';
import HelpIconWithTooltip from '../HelpIconWithTooltip'

// React.useContext
export const ApplicantsContext = React.createContext()

const get_notes = ({ callback }) => {
    fetch('/profile_notes.json')
        .then(res => res.json())
        .then(res => callback(res))
}

const ManageApplicants = (props) => {
    const {
        less_qualified_applicants,
        applicants,
        invited,
        favorites,
        authenticity_token,
        job_type_title,
        job_link,
        position_type_name,
        zip,
        timezone,
        js_applicants,
        profile_notes,
        essential,
        hidden_applicants
    } = props;

    const [state, setState] = React.useState({
        less_qualified_applicants,
        applicants,
        invited,
        hidden_applicants,
        notes: profile_notes,
        loaded: true,
    });

    const index = () => {
        // Switch statement version
        switch (window.location.hash.substr(1)) {
            case 'invited':
                return essential ? 2 : 1;
            case 'applicants':
                return 0;
            case 'less_qualified':
                return 1;
            case 'deleted':
                return essential ? 3 : 2;
            default:
                return 0;
        }
    }

    const [selectedTab, setSelectedTab] = React.useState(index || 0);

    const getNotes = () => get_notes({
        callback: notes => setState({ ...state, notes, loaded: true })
    });

    React.useEffect(() => getNotes(), []);

    const updateStatus = (applicant, status) => {
        // Update applicant.status in state.applicants
        const applicants = state.applicants.map(a => {
            if (a.id === applicant.id) a.status = status;
            return a;
        });
        setState({ ...state, applicants });

        // do the same for state.less_qualified_applicants
        const less_qualified_applicants = state.less_qualified_applicants.map(a => {
            if (a.id === applicant.id) a.status = status;
            return a;
        });
        setState({ ...state, less_qualified_applicants });
    };

    // Reject any hidden_applicants from applicants
    const filtered_applicants = state.applicants.filter(applicant => !state.hidden_applicants.includes(applicant))

    // Reject any hidden_applicants from less_qualified_applicants
    const filtered_less_qualified_applicants = state.less_qualified_applicants.filter(applicant => !state.hidden_applicants.includes(applicant))

    const hideProfile = (applicant) => {
        // Alert confirm yes or no
        if (!confirm(`Are you sure you want to hide this applicant?`)) return;

        fetch(`/job_applications/${applicant.id}/hide.json`, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': authenticity_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ hidden: !applicant.hidden })
        }).then(response => {
            setState(state => ({
                ...state,
                hidden_applicants: [
                    ...state.hidden_applicants,
                    applicant
                ]
            }))
            return response;
        })
    }

    React.useEffect(() => {
        console.log(selectedTab)
        // window.location.hash = (selectedTab === 1) ? '#invited' : '#applicants';
        switch (selectedTab) {
            case 0:
                window.location.hash = '#applicants';
                break;
            case 1:
                window.location.hash = '#less_qualified';
                break;
            case 2:
                window.location.hash = '#invited';
                break;
            case 3:
                window.location.hash = '#deleted';
                break;
            default:
                window.location.hash = '#applicants';
        }
    }, [selectedTab]);

    return (
        <ApplicantsContext.Provider value={[hideProfile, updateStatus]}>
            <Tabs defaultIndex={selectedTab} selectedIndex={selectedTab}>
                <h2 className="ddblue" style={{ marginBottom: 20 }}>
                    <a href={job_link} className="ddblue">{job_type_title} - {position_type_name} {zip}</a>
                </h2>
                {/* Flex grow child elements */}
                <TabList>
                    {/* <TabList className="d-flex flex-column"> */}

                    <Tab onClick={() => setSelectedTab(0)}>
                        My Applicants
                        {/* Check if any less_qualified_applicants have seen: true */}
                        {filtered_applicants.filter(applicant => !applicant.seen).length > 0 && (
                            <span style={{ backgroundColor: 'red', color: 'white', fontSize: 10, marginLeft: 4, borderRadius: 10, padding: '2px 4px', marginTop: -2 }}>New</span>
                        )}
                    </Tab>
                    <Tab onClick={() => setSelectedTab(1)}>
                        Dental Newbies <HelpIconWithTooltip message="newbie" style={{ display: 'inline', lineHeight: '4px', margin: 0, padding: 0, fontSize: 14 }} />
                        {/* Check if any less_qualified_applicants have seen: true */}
                        {filtered_less_qualified_applicants.filter(applicant => !applicant.seen).length > 0 && (
                            <span style={{ backgroundColor: 'red', color: 'white', fontSize: 10, marginLeft: 4, borderRadius: 10, padding: '2px 4px', marginTop: -2 }}>New</span>
                        )}
                    </Tab>
                    {essential &&
                        <Tab style={{ color: 'black' }} onClick={() => setSelectedTab(2)}>
                            Invitations Sent
                        </Tab>
                    }
                    <Tab onClick={() => setSelectedTab(essential ? 3 : 2)}>
                        Deleted Applications
                    </Tab>
                </TabList>
                <br />
                <div className="">
                    <TabPanel>
                        <p className="margin-bottom-25">
                            This is where we keep your job applications.
                        </p>
                        {filtered_applicants.length < 1 && (js_applicants < 1) && (
                            <div>
                                <h4 className="text-center">You currently have no candidates.</h4><br />
                            </div>
                        )}
                        {filtered_applicants.length < 1 && (js_applicants < 1) && essential === true && (
                            <div>
                                <h4 className="text-center">Search candidates to invite to apply and send messages.</h4><br />
                                <p className="text-center">
                                    <a href="/profiles" className="button">Search Candidates</a>
                                </p>
                            </div>
                        )}

                        <Applicants
                            favorites={favorites}
                            applicants={filtered_applicants}
                            authenticity_token={authenticity_token}
                            notes={state.notes}
                            load_notes={getNotes}
                            loaded={state.loaded}
                            timezone={timezone}
                        />

                        {/* If filtered_less_qualified_applicants > 0, show a button to view less qualified applicants, switch to tab 1 */}
                        {filtered_less_qualified_applicants.length > 0 && (
                            <div className="text-center">
                                <a className="btn btn-primary mt-5" onClick={() => setSelectedTab(1)}>
                                    View {filtered_less_qualified_applicants.length} Less Qualified Applicants
                                </a>
                            </div>
                        )}

                    </TabPanel>
                    <TabPanel>
                        <p className="margin-bottom-25">
                            This is where we keep your less qualified job applicants. These applicants answered "no" to our experience-related screening questions.
                        </p>
                        {filtered_less_qualified_applicants.length < 1 && (js_applicants < 1) && (
                            <div>
                                <h4 className="text-center">You currently have no candidates.</h4><br />
                            </div>
                        )}
                        {filtered_less_qualified_applicants.length < 1 && (js_applicants < 1) && essential === true && (
                            <div>
                                <h4 className="text-center">Search candidates to invite to apply and send messages.</h4><br />
                                <p className="text-center">
                                    <a href="/profiles" className="button">Search Candidates</a>
                                </p>
                            </div>
                        )}

                        <Applicants
                            favorites={favorites}
                            applicants={filtered_less_qualified_applicants}
                            authenticity_token={authenticity_token}
                            notes={state.notes}
                            load_notes={getNotes}
                            loaded={state.loaded}
                            timezone={timezone}
                        />

                    </TabPanel>
                    <TabPanel>
                        <p className="margin-bottom-25">
                            This is where you can see all the professionals you invited to apply.
                        </p>
                        {state.invited.length < 1 && essential === true && (
                            <div>
                                <h4 className="text-center">
                                    You have not invited any candidates to apply. <br />
                                </h4>
                                <p className="text-center">
                                    Find candidates faster with Matched Candidates and Invite to Apply!<br /><br />
                                    <a className="button" href={`${job_link}`}>View Matched Candidates</a>
                                </p>
                            </div>
                        )}
                        <ApplicantInvitations
                            favorites={favorites}
                            applicants={state.invited}
                            authenticity_token={authenticity_token}
                            notes={state.notes}
                            load_notes={getNotes}
                            loaded={state.loaded}
                            timezone={timezone}
                        />
                    </TabPanel>
                    <TabPanel>
                        <p className="margin-bottom-25">
                            Applicants that have been blocked or deleted will appear here.
                        </p>
                        <Applicants
                            favorites={favorites}
                            applicants={state.hidden_applicants}
                            authenticity_token={authenticity_token}
                            notes={state.notes}
                            load_notes={getNotes}
                            loaded={state.loaded}
                            timezone={timezone}
                        />
                    </TabPanel>
                </div>
            </Tabs>
        </ApplicantsContext.Provider>
    )
}

export default ManageApplicants;