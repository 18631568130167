import * as React from "react"
export default function GetStarted({
  trial,
  form_authenticity_token,
  plans,
  user_signed_in,
}) {
  // State to store quantity
  const [quantity, setQuantity] = React.useState(1)
  const [plan, setPlan] = React.useState(plans[0])

  const plan_features: Array<string> = plan.features.split("|")

  console.log(plans, plan)

  // Button Text "Start Trial"
  const buttonText = trial ? "Start Trial" : "Continue"

  const selectPlan = (e) => {
    setPlan(plans.find((plan) => plan.stripe_plan === e.target.value))
  }

  return (
    <div className="col-12 mt-2">
      <div className="d-flex align-items-center justify-content-center flex-column flex-md-row pt-5">
        <div className="pb-2">
          <div
            className="card text-start mb-2 pb-5"
            id="blueCard"
            style={{ height: "unset" }}
          >
            <div className="ps-5 pt-5" id="essCardText">
              <h2>
                <strong>{plan.name}</strong>
              </h2>
              {plan_features.map((feature, index) => (
                <p key={index}>&#8226; {feature}</p>
              ))}
            </div>
            <div className="text-center mx-auto" style={{ width: "80%" }}>
              <form action="/create_quote" method="post">
                {/* Auth Token */}
                <input
                  name="authenticity_token"
                  value={form_authenticity_token}
                  type="hidden"
                />
                {/* Hidden Field Purchase Name */}
                {/* Subscription - Free Trial or Subscription - Restart */}
                <input
                  type="hidden"
                  name="purchase_name"
                  value={`Subscription - ${trial ? "Free Trial" : "Restart"}`}
                />

                {/* Hidden Field Purchase Amount */}
                <input type="hidden" name="purchase_amount" value={plan.price} />


                <input name="price" value={plan.stripe_plan} type="hidden" />
                <input name="quantity" value={quantity} type="hidden" />

                {/* Boostrap Select Box */}
                <div className="form-group mt-2">
                  <h3>Select Plan</h3>
                  <select
                    className=""
                    name="plan"
                    onChange={selectPlan}
                    style={{
                      height: "30px",
                      width: "100%",
                    }}
                  >
                    {plans.map((plan, index) => (
                      <option key={index} value={plan.stripe_plan}>
                        {plan.name}
                        {plan.monthly_price &&
                          ` - $${String(plan.monthly_price)}/Month`}
                      </option>
                    ))}
                  </select>
                  {/* Yellow Paragraph */}
                  {plan.savings > 0 && (
                    <p
                      className="mt-2 text-center text-start"
                      style={{ color: "yellow" }}
                    >
                      <strong>{plan.savings}% Savings</strong>
                    </p>
                  )}
                </div>

                {/* Output Price * Quantity */}
                <div className="form-group my-3">
                  <h6>
                    <strong>
                      {trial && <>7 Days Free and then </>}$
                      {plan.price * quantity} / {plan.term}
                    </strong>
                  </h6>
                </div>

                {/* Button to go to checkout */}
                <button className="btn text-white btn-orange" id="essBtnGreen">
                  {buttonText}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
