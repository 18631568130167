import * as React from "react"
import ApplicationStatus from "../ApplicationStatus"
import NumberFormat from "react-number-format"
import moment from "moment-timezone"
import AddFavoriteCandidate from "../Favorites/AddFavoriteCandidate"
import { ApplicantsNotes } from "../Notes/ProfileNotes"
import { ApplicantsContext } from "./ManageApplicants"
import SendMessage from "../SendMessage"

const Applicant = ({
  applicant,
  favorites,
  authenticity_token,
  notes,
  load_notes,
  loaded,
  timezone,
}) => {
  const { profile } = applicant
  const application_date = moment(applicant.created_at)
    .tz(timezone)
    .format("llll")
  const updated_date = moment(applicant.updated_at).tz(timezone).format("llll")
  const phone_mask = profile.phone && profile.phone.length > 10 ? "+# (###) ###-####" : "(###) ###-####"
  const seen = profile.seen ? "seen" : "unseen"
  const withdrawn = applicant.withdrawn ? "withdrawn" : ""
  const profile_url = `/profiles/${profile.id}`

  const [hideProfile, updateStatus] = React.useContext(ApplicantsContext)

  return (
    <div className={`row gx-lg-5 ManageApplicantContainer`}>
      <div className={`col-12 col-lg-8 application left ${withdrawn} border rounded-dd p-0 shadow-sm mb-2`}>
        <div className="row p-3">
          <div className="col-3 col-sm-2 p-2">
            <a href={profile_url} target="_blank">
              <img src={profile.avatar_url} className="img-fluid rounded-circle" />
            </a>
          </div>
          <div className="col-8 col-md-6 d-flex flex-column justify-content-center">
            <span className="d-flex flex-row">
              <h2>
                <a href={`/profiles/${profile.id}`} target="_blank">
                  {profile.fullname}
                </a>
                <AddFavoriteCandidate
                  profile={profile}
                  favorited_init={favorites.includes(profile.id)}
                  authenticity_token={authenticity_token}
                />
              </h2>
            </span>
            <ul className="d-flex flex-column flex-sm-row list-unstyled">
              <li className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1">
                <i className="fa fa-map-marker"></i> {profile.city},{" "}
                {profile.state}
              </li>
              {profile.hourly_rate && (
                <li className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1">
                  <i className="fa fa-money"></i> ${profile.hourly_rate} / hour
                </li>
              )}
            </ul>
          </div>

          <div className="col-12 col-md-4 application-status d-flex flex-row justify-content-between align-items-start mt-3 mt-lg-0">
            <ApplicationStatus application={applicant} updateStatusFunc={updateStatus} />
            {/* Trash Icon */}
            <i className="fa fa-trash-o p-2 mx-2 mt-1th mb-3"
              style={{ cursor: "pointer" }}
              onClick={() => hideProfile(applicant)}></i>
          </div>
        </div>
        {/* Bottom Row */}
        <LeftSideBottomRow
          profile={profile}
          authenticity_token={authenticity_token}
          phone_mask={phone_mask}
          applicant={applicant}
          application_date={application_date}
          updated_date={updated_date}
        />
      </div>
      {/* Notes Sidebar */}
      <div className="col-12 col-lg-4 d-flex flex-column justify-content-between mb-2">
        <div className="row p-2 col-12 border rounded-dd flex-grow-1 shadow-sm m-0">
          <ApplicantsNotes
            profile_id={profile.id}
            notes={notes}
            authenticity_token={authenticity_token}
            load_notes={load_notes}
            loaded={loaded}
          />
        </div>
      </div>
    </div>
  )
}

function LeftSideBottomRow({
  profile,
  authenticity_token,
  phone_mask,
  applicant,
  application_date,
  updated_date
}) {

  const applied_string = applicant.withdrawn
    ? `Withdrawn ${updated_date}`
    : `Applied ${application_date}`

  return (
    <div className="col-12 app-footer bg-light px-4 pt-4 pb-2 rounded-bottom-dd row m-0">
      <ul className="col-12 col-xl-6 app-contacts d-flex flex-column flex-sm-row list-unstyled justify-content-center justify-content-xl-start">
        <li className="d-flex flex-column justify-content-center mb-2 mb-sm-0">
          {/* Outline */}
          {profile.phone && <a href={`tel:${profile.phone}`} className="btn btn-outline-dark
           rounded-pill">
            <strong>
              <i className="fa fa-phone pe-2"></i>
              <NumberFormat
                value={profile.phone}
                displayType="text"
                type="text"
                format={phone_mask}
                mask="_"
              />
            </strong>
          </a>}
        </li>
        <li className="ms-0 ms-sm-3">
          <SendMessage
            profile_id={profile.id}
            authenticity_token={authenticity_token}
          />
        </li>
      </ul>
      {/* Align at end */}
      <ul className="col-12 col-xl-6 app-contacts d-flex flex-row list-unstyled justify-content-center justify-content-xl-end">
        <li className="d-flex flex-column justify-content-center">
          <strong>
            <i className="fa fa-calendar pe-2"></i> {applied_string}
          </strong>
        </li>
      </ul>
      <div className="clearfix"></div>
    </div>
  )
}

const Applicants = ({
  applicants,
  favorites,
  authenticity_token,
  notes,
  load_notes,
  loaded,
  timezone,
}) => {
  return applicants
    .sort((a, b) => (!a.withdrawn ? 1 : -1))
    .map(applicant => {
      return Applicant({
        applicant,
        favorites,
        authenticity_token,
        load_notes,
        loaded,
        timezone,
        notes: notes.filter(note => applicant.profile.id === note.profile_id),
      })
    })
}

export default Applicants
