import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function StaffingProduct({ public_key, sessionId }) {
  // let stripePromise;
  const [state, setState] = React.useState({ stripePromise: undefined });

  const handleClick = async (event) => {
    // Get Stripe.js instance
    const stripe = await state.stripePromise;

    // Call your backend to create the Checkout Session
    // const response = await fetch("/create-checkout-session", {
    //   method: "POST",
    // });

    // const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    // sessionId: session.id,
    const result = await stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  React.useEffect(() => {
    setState({ stripePromise: loadStripe(public_key) });
  }, []);

  React.useEffect(() => {
    handleClick(undefined);
  }, [state.stripePromise]);

  // <button role="link" onClick={handleClick}>
  //   Checkout
  // </button>
  return (
    <h2>Please wait a moment while we process your request. You will be redirect to our payment portal in a few seconds.</h2>
  );
}
