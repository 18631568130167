import React from 'react'

const selections = [
    'Contacted',
    'Interview Scheduled',
    'Interview Completed',
    'Hired',
    'Passed',
    'Not Interested',
    'Candidate Cancelled',
    'Candidate No-Show'
]

const initialState = (application) => ({ application, selection: application.status })

const sendApiRequest = (application, state) => {
    const url = `/jobs/update_app/${application.id}?value=${state.selection}`
    fetch(url).then(response => response.json())
}

const ApplicationStatus = ({ application, updateStatusFunc }) => {
    const [state, setState] = React.useState(initialState(application))
    const updateStatus = (e) => {
        setState({ selection: e.target.value })
        updateStatusFunc(application, e.target.value)
    }
    React.useEffect(() => sendApiRequest(application, state), [state.selection])

    return (
        // Outline BLue
        <select onChange={updateStatus} className="form-select form-select-sm" aria-label=".form-select-sm">
            <option>Select Status</option>
            {selections.map(selection =>
                <option value={selection} selected={state.selection === selection}>{selection}</option>
            )}
        </select>
    )
}

export default ApplicationStatus