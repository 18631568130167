import * as React from 'react';
import NumberFormat from 'react-number-format';

interface ProductProps {
  id: number;
  name: string;
  price: string;
  savings_price?: string;
  features?: string;
  trial?: boolean;
  product_name: string;
  tagline: string;
  savings: string;
  product_type?: string;
}

function BillingTermly({ name }) {
  return <React.Fragment>
    , Billed {name.includes('ly') ? name : `${name}ly`}
    <span>&nbsp;</span>
  </React.Fragment>
}

export default function Plan({ name, price, id, savings_price, features, trial, product_name, tagline, savings, hide_features = false }) {
  const plan_features = features.split('|')
  return (
    <div className="plan-container">
      <div className="plan color-1 dropshadow">
        <div className="plan-price" style={styles.planPriceStyle}>
          <h3 className="comoTitle"><strong style={styles.whiteText}>{name}</strong></h3>
          {savings_price !== price && (
            <small className="billing-savings">Save {savings}%</small>
          )}
          <span className="plan-currency nunito" style={styles.planCurrency}>$ </span>
          <span className="value nunito" style={styles.value}>
            {savings_price.toString().includes('.') && (
              <NumberFormat value={savings_price} displayType={'text'} prefix={''} fixedDecimalScale={true} decimalScale={2} />
            )}
            {!savings_price.toString().includes('.') && (
              savings_price
            )}
          </span>
          <p style={styles.productName}><small>{product_name}</small></p>
          <small className="billing-term" style={styles.blackText}>Per Month
            {savings_price !== price && (<BillingTermly name={name} />)}
          </small>
        </div>
        <div className="plan-features landing">
          {!hide_features && (
            <ul>
              {tagline && (
                <li className="centered"><strong>{tagline}</strong></li>
              )}
              {plan_features.map(ft => <li><i className="fa fa-check"></i> {ft}</li>)}
            </ul>
          )}
          <a className="stripe-button-el" href={`/plans/${id}`}>
            <span style={styles.pinkBG}>{trial ? "Try for Free" : "Get Started"}</span>
          </a>
        </div>
      </div>
    </div >
  )
}

const styles = {
  planPriceStyle: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'rgb(240, 240, 240)'
  },
  whiteText: {
    color: 'white'
  },
  blackText: {
    color: 'black'
  },
  planCurrency: {
    verticalAlign: 'top',
    marginLeft: -20,
    color: 'grey'
  },
  value: {
    fontSize: 64,
    color: 'black'
  },
  productName: {
    color: 'grey',
    paddingTop: 10
  },
  pinkBG: {
    backgroundColor: '#cc0099 !important'
  }
}