import * as React from "react";
import ExperienceSelect from "./FormComponents/ExperienceSelect";
import ExperienceHistory from "./FormComponents/ExperienceHistory";
import Skills from "./FormComponents/Skills";

// Context
import { ProfileFormContext } from "../ProfileEditForm";
import { ACTIONS } from "./ProfileFormReducer";
import EducationHistory from "./FormComponents/EducationHistory";
export default function Experience() {
  const [state, dispatch] = React.useContext(ProfileFormContext);

  // Set Field
  const setField = (e) =>
    dispatch({
      type: ACTIONS.SET_FIELD,
      payload: { [`${e.target.name}`]: e.target.value },
    });

  // Toggle Checkbox using setField
  const toggleCheckbox = (e) => {
    const value = e.target.checked ? 1 : 0;
    setField({ target: { name: e.target.name, value: value } });
  };

  return (
    <div>
      <div className="py-3">
        <Skills />
      </div>

      {/* Years Experience */}
      <div className="form-group">
        <label htmlFor="years_experience" className="form-label">
          Years of Experience
        </label>
        <ExperienceSelect
          years_experience={state.profile.years_experience}
          setField={setField}
        />
      </div>

      <div className="py-3">
        <label htmlFor="experience" className="form-label">
          Experience
        </label>
        <ExperienceHistory />
      </div>

      <div className="py-3">
        <label htmlFor="education" className="form-label">
          Education
        </label>
        <EducationHistory />
      </div>
    </div>
  );
}
