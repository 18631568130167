import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function QuickApply({
  apply_path,
  signin_redirect_path,
  user_signed_in,
  authenticity_token
}) {
  const [show, setShow] = React.useState(false);

  return (
    <div>
      <QuickApplyModal
        signin_redirect_path={signin_redirect_path}
        show={show}
        setShow={setShow}
        authenticity_token={authenticity_token}
        apply_path={apply_path}
      />
      {user_signed_in ? (
        <a
          href={apply_path}
          className="button quick-apply-widget text-decoration-none"
          role="button"
        >
          Quick Apply
        </a>
      ) : (
        <a
          onClick={() => setShow(true)}
          className="button quick-apply-widget text-decoration-none"
          role="button"
        >
          Quick Apply
        </a>
      )}
    </div>
  )
}

function QuickApplyModal({ authenticity_token, signin_redirect_path, show, setShow, apply_path }) {
  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Quick Apply</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Form to signin_redirect_pathsignin_redirect_path */}
        <form action={signin_redirect_path} method="post">
          <input type="hidden" name="authenticity_token" value={authenticity_token} />
          {/* hidden field: redirect_to apply_path */}
          <input type="hidden" name="redirect_to" value={apply_path} />
          {/* email */}
          <div className="form-group mt-3">
            <label htmlFor="email"><h4>Email Address</h4></label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              required
            />
          </div>
          {/* Continue Button */}
          <div className="form-group text-center m-3">
            <button type="submit" className="btn btn-dd btn-block">
              Continue
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}