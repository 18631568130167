import "./Common-poly";
import "whatwg-fetch";
import * as React from "react";
import SBSendMessage from "./SBChat/SBSendMessage";

import { Modal } from "react-bootstrap";

export default function SendMessage({ authenticity_token, profile_id }) {
  const [state, setState] = React.useState({
    modalOpen: false,
  });

  // Error State
  const [errorState, setErrorState] = React.useState({
    error: false,
    message: "",
  });

  // Success State
  const [successState, setSuccessState] = React.useState({
    success: false,
    message: "",
  });

  const [message, setMessage] = React.useState("");

  // Close Modal
  const closeModal = () => setState({ ...state, modalOpen: false });

  // Open Modal
  const openModal = () => setState({ ...state, modalOpen: true });

  // handle setMessageHandler
  const setMessageHandler = (e) => {
    setMessage(e.target.value);
  };

  // Handle Send Message
  const sendMessageHandler = () => {
    sendMessage(
      authenticity_token,
      profile_id,
      message,
      setErrorState,
      setSuccessState,
    );
  };

  // Get URL parameters
  const params = new URLSearchParams(window.location.search);
  const isAdmin = params.get("admin") === "true";

  return (
    <React.Fragment>
      {/* Button to open modal */}
      {isAdmin ? (
        <SBSendMessage profileId={profile_id} />
      ) : (
        <a
          className="popup-with-zoom-anim button orange btn btn-primary btn-rounded col-12"
          onClick={openModal}
        >
          <i className="fa fa-envelope"></i> Send Message
        </a>
      )}
      <Modal show={state.modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* If not error or success */}
          {!errorState.error && !successState.success && (
            <>
              <p>
                Contact this Professional directly to let them know you're
                interested. You can include potential interview dates or simply
                ask them to call you to discuss your open job.
              </p>
              {/* Message Field */}
              <div className="behind">
                <textarea
                  className="form-control"
                  rows="5"
                  value={message}
                  onChange={setMessageHandler}
                  placeholder="Type Your Message..."
                />
              </div>
              {/* Send Message Button */}
              <div className="text-center">
                <a
                  className="popup-with-zoom-anim button orange btn btn-primary btn-rounded m-auto"
                  onClick={sendMessageHandler}
                >
                  <i className="fa fa-envelope"></i> Send Message
                </a>
              </div>
            </>
          )}
          {/* If Error */}
          {errorState.error && (
            <>
              <div className="alert alert-danger" role="alert">
                {errorState.message}
              </div>
              <div className="text-center">
                <a
                  className="popup-with-zoom-anim button orange btn btn-primary btn-rounded m-auto"
                  onClick={closeModal}
                >
                  <i className="fa fa-times"></i> Close
                </a>
              </div>
            </>
          )}

          {/* Success */}
          {successState.success && (
            <>
              <div className="alert alert-success" role="alert">
                {successState.message}
              </div>
              <div className="text-center">
                <a
                  className="popup-with-zoom-anim button orange btn btn-primary btn-rounded m-auto"
                  onClick={closeModal}
                >
                  <i className="fa fa-times"></i> Close
                </a>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

// API Call to Send Message
const sendMessage = (
  authenticity_token,
  profile_id,
  message,
  setErrorState,
  setSuccessState,
) => {
  const url = `/direct_messages.json`;
  const body = {
    receiver_user_id: profile_id,
    message,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify({ direct_message: body }),
  };

  fetch(url, options)
    .then((response) => {
      console.log(response);
      if (response.status === 201) {
        setSuccessState({
          success: true,
          message: "Message Sent Successfully",
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setErrorState({ error: true, message: error.message });
    });
};
