import React from "react"
import JobSkills from "./JobSkills"

export default function JobTags(props) {
  const { job } = props
  return (
    <div className="d-flex my-1 flex-wrap">
      {job.skills !== undefined && (
        <JobSkills
          job={job}
          position_type={job.position_type}
          skills={job.skills}
        />
      )}
    </div>
  )
}
