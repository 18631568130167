import "../Common-poly";
import * as React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import * as moment from "moment";
import Cookies from "universal-cookie";

interface Job {
  id: number;
  position_type: string;
  temp_filled: boolean;
  closed: boolean;
  published: boolean;
  job_type_id: number;
  practice_id: number;
  position_type_id: number;
  temp_date?: string;
  created_at: string;
  published_at?: string;
  job_applications: JobApplication[];
}

interface JobApplication {
  hidden: boolean;
  seen: boolean;
}

interface PositionType {
  shortname: string;
  name: string;
}

interface JobType {
  id: number;
  title: string;
}

interface Practice {
  id: number;
  name: string;
}

const positionType = ({
  row,
  position_types,
}: {
  row: Job;
  position_types: PositionType[];
}): string => {
  const pt = position_types.find((jt) => jt.shortname === row.position_type);
  return pt ? pt.name : "";
};

const tempType = (row: Job): boolean =>
  row.position_type === "temp" || row.position_type === "longtermtemp";

const accentColor = (temp: boolean): string => {
  return temp ? "#f1630d" : "#176fc9";
};

const jobStatus = (row: Job): string => {
  if (row.temp_filled) return "Accepted";
  if (row.closed) return "Closed";
  if (tempType(row) && row.published) return "Open";
  if (row.published) return "Published";
  return "Draft";
};

const jobActions = (row: Job, temp: boolean) => {
  const buttonStyle = { color: `${accentColor(temp)}`, margin: "0px 5px" };

  const url = `/jobs/${row.id}`;
  const clone_url = `/jobs/${row.id}/clone`;
  const edit_url = `/jobs/${row.id}/edit`;

  return (
    <div className="content">
      {!row.published && !row.closed && (
        <a href={url} style={buttonStyle} className="text-decoration-none">
          <i className="fa fa-book"></i> Publish
        </a>
      )}
      <a href={clone_url} style={buttonStyle} className="text-decoration-none">
        <i className="fa fa-book"></i> Clone
      </a>
      {!row.closed && (
        <React.Fragment>
          <a
            href={edit_url}
            style={buttonStyle}
            target="_blank"
            className="text-decoration-none"
          >
            <i className="fa fa-pencil"></i> Edit
          </a>
          <a
            data-confirm="Are you sure?"
            rel="nofollow"
            data-method="delete"
            href={url}
            style={buttonStyle}
            className="text-decoration-none"
          >
            <i className="fa fa-remove"></i> Close
          </a>
        </React.Fragment>
      )}
    </div>
  );
};

function titleField({
  row,
  job_types,
  temp,
}: {
  row: Job;
  job_types: JobType[];
  temp: boolean;
}): React.ReactNode {
  const styles: React.CSSProperties = {
    color: accentColor(temp),
    fontWeight: "bold",
    border: "1px solid #D3D3D3",
    padding: "6px",
    borderRadius: "3px",
  };
  const jobType = job_types.find((jt) => jt.id === row.job_type_id);
  const url = `/jobs/${row.id}`;
  return (
    <a href={url} style={styles} className="text-decoration-none">
      {jobType ? jobType.title : ""}
    </a>
  );
}

const dataColumns = ({
  position_types,
  job_types,
  practices,
  temp,
}: {
  position_types: PositionType[];
  job_types: JobType[];
  practices: Practice[];
  temp: boolean;
}): TableColumn<Job>[] => [
  {
    name: "Job Title",
    selector: (row) => row.job_type_id,
    sortable: true,
    cell: (row) => titleField({ row, job_types, temp }),
    maxWidth: "400px",
    minWidth: "200px",
  },
  {
    name: "Practice",
    selector: (row) => row.practice_id,
    sortable: true,
    cell: (row) => {
      const practice = practices.find((p) => p.id === row.practice_id);
      return (
        <a
          href={`/practices/${row.practice_id}`}
          style={{ color: `${accentColor(temp)}`, fontWeight: "bold" }}
          className="text-decoration-none"
        >
          {practice ? practice.name : ""}
        </a>
      );
    },
    maxWidth: "150px",
    minWidth: "150px",
  },
  {
    name: "Position",
    selector: (row) => row.position_type_id,
    sortable: true,
    cell: (row) => positionType({ row, position_types }),
    maxWidth: "130px",
    minWidth: "130px",
    center: true,
  },
  {
    name: temp ? "Job Date" : "Created",
    selector: (row) => (temp ? row.temp_date : row.created_at),
    sortable: true,
    cell: (row) => {
      const t = tempType(row)
        ? moment(row.temp_date).format("ll")
        : moment(row.created_at).format("ll");
      if (t === "Invalid date") {
        return moment(row.published_at || row.created_at).format("ll");
      } else {
        return t;
      }
    },
    maxWidth: "120px",
    minWidth: "120px",
  },
  {
    name: "Status",
    selector: (row) => {
      if (row.temp_filled) return 3;
      if (row.closed) return 2;
      if (row.published) return 0;
      return 1;
    },
    sortable: true,
    cell: (row) => jobStatus(row),
    right: true,
    maxWidth: "100px",
  },
  {
    name: "Applicants",
    sortable: true,
    selector: (row) => {
      const apps = row.job_applications.filter((ja) => !ja.hidden);
      const unseen = apps.filter((ja) => !ja.seen).length;
      return unseen > 0 ? -1000000 + unseen : -unseen; // Show New App(s) first, then sort by number of new applicants
    },
    cell: (row) => {
      const apps = row.job_applications.filter((ja) => !ja.hidden);
      const seen = apps.filter((ja) => ja.seen).length;
      const unseen = apps.filter((ja) => !ja.seen).length;
      if (unseen === 0) {
        return (
          <div
            className={
              temp
                ? "content applicant-route-btn-temp"
                : "content applicant-route-btn"
            }
          >
            <span className={temp ? "temp" : "fulltime"}>
              <strong>
                <a
                  href={
                    row.position_type === "temp"
                      ? `/jobs/${row.id}`
                      : `/jobs/${row.id}/applicants`
                  }
                  style={{ color: "#fff" }}
                  className="text-decoration-none"
                >
                  <span className="seen-apps text-decoration-none">{`${seen}`}</span>{" "}
                  Applicants
                </a>
              </strong>
            </span>
          </div>
        );
      }

      return (
        <div className="content applicant-route-btn">
          <span className={temp ? "temp" : "fulltime"}>
            <a
              href={
                row.position_type === "temp"
                  ? `/jobs/${row.id}`
                  : `/jobs/${row.id}/applicants`
              }
              style={{ color: "#fff" }}
              className="text-decoration-none"
            >
              <strong>
                <span className="new-apps text-decoration-none">{`${unseen}`}</span>{" "}
                New App(s)
              </strong>
            </a>
          </span>
        </div>
      );
    },
    right: true,
    minWidth: "180px",
    maxWidth: "180px",
    center: true,
  },
  {
    name: "Actions",
    sortable: false,
    cell: (row) => jobActions(row, temp),
    left: true,
    minWidth: "280px",
  },
];

interface ManageJobListState {
  jobs: Job[];
  table: any;
  showClosed: boolean;
}

interface ManageJobListProps {
  jobs: Job[];
  position_types: PositionType[];
  job_types: JobType[];
  temp: boolean;
  practices: Practice[];
}

export default function ManageJobList({
  jobs,
  position_types,
  job_types,
  temp,
  practices,
}: ManageJobListProps) {
  const cookies = new Cookies();
  const showClosedJobsCookie = cookies.get("showClosedJobs");
  const showClosedJobsCookieValue: boolean =
    showClosedJobsCookie === undefined ? true : showClosedJobsCookie === "true";

  const [state, setState] = React.useState<ManageJobListState>({
    jobs,
    table: {},
    showClosed: showClosedJobsCookieValue,
  });

  const updateTableState = (stateUpdate: object) => {
    setState((prevState) => ({ ...prevState, table: stateUpdate }));
  };

  const toggleShowClosed = (): void => {
    const showClosedValue = !state.showClosed;
    setState((prevState) => ({ ...prevState, showClosed: showClosedValue }));
    cookies.set("showClosedJobs", showClosedValue, { path: "/" });
  };

  const columns = dataColumns({ position_types, job_types, practices, temp });

  const filteredItems: Job[] = jobs.filter((job) => {
    if (state.showClosed) return true;
    return !job.closed;
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: "0 0",
          justifyContent: "flex-end",
          marginRight: 0,
        }}
      >
        <div
          className="button"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <input
            style={{ margin: 6 }}
            type="checkbox"
            name="showClosed"
            id="showClosed"
            checked={state.showClosed}
            onChange={toggleShowClosed}
          />
          <label htmlFor="showClosed" style={{ fontSize: 16, margin: 0 }}>
            Show Closed Jobs
          </label>
        </div>
      </div>
      <DataTable
        data={filteredItems}
        columns={columns}
        onSelectedRowsChange={updateTableState}
        defaultSortField="created_at"
        defaultSortAsc={false}
        responsive
        // pagination
      />
      <div className="py-5 " style={{ width: "100%", display: "block" }}>
        <center>
          <a className="btn manage-job-btn" href="#" onClick={toggleShowClosed}>
            {state.showClosed ? "Hide" : "Show"} Closed Jobs
          </a>
        </center>
      </div>
    </div>
  );
}
