import React from 'react';
import moment from 'moment';

function removeDuplicates(arr, prop) {
    var obj = {};
    for (var i = 0, len = arr.length; i < len; i++) {
        if (!obj[arr[i][prop]]) obj[arr[i][prop]] = arr[i];
    }
    var newArr = [];
    for (var key in obj) newArr.push(obj[key]);
    return newArr;
}

export default function JobSkills(props) {
    const { skills, job } = props;

    // Get Unique Categories
    let categories = [...new Set(skills.map(skill => ({
        name: skill.category, color: skill.color
    })))];

    // Count Category Occurences
    categories = categories.map(c => ({
        ...c, count: skills.filter(s => s.category == c.name).length
    }));

    categories = removeDuplicates(categories, 'name');

    return (
        <div style={{ display: 'inline-block' }}>

            {/* Job Position Type */}
            {job.position_type === "fulltime" && (<span className="full-time">Full Time</span>)}
            {job.position_type === "parttime" && (<span className="part-time">Part Time</span>)}
            {job.position_type === "longtermtemp" && (<span className="temp">Long Term Temp</span>)}
            {job.position_type === "temp" && (<span className="temp">Temp {moment(job.temp_date).format("MMMM Do")} </span>)}

            {/* Benefits */}
            {job.benefit_medical && (<span className="benefit">Medical</span>)}
            {job.benefit_dental && (<span className="benefit">Dental</span>)}
            {job.benefit_vision && (<span className="benefit">Vision</span>)}
            {job.benefit_401k && (<span className="benefit">401k</span>)}
            {job.benefit_pto && (<span className="benefit">PTO</span>)}
            {job.benefit_bonuses && (<span className="benefit">Bonuses</span>)}

            {categories.map(cat => {
                if (cat.count === 1) {
                    const skill = skills.filter(s => s.category == cat.name)[0];
                    return (
                        <span className="full-time" style={{ backgroundColor: cat.color }}>
                            {skill.name}
                        </span>
                    )
                }
                return (
                    <span className="full-time" style={{ backgroundColor: cat.color }}>
                        ({cat.count}) {cat.name}
                    </span>
                )
            })}
        </div>
    );
}