import * as React from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete"

const states = [
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["DC", "District of Columbia"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["PR", "Puerto Rico"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"],
]

const separateComponents = (components, setState) => {
    let address_components = {
        street_address: "",
        street_address_2: "",
        city: "",
        state: "",
        zip: "",
    }

    components.address_components.forEach((ac) => {
        //This stops other countries from entering

        if (ac.types.includes("street_number"))
            address_components["street_address"] = ac.long_name

        if (ac.types.includes("route"))
            address_components[
                "street_address"
            ] = `${address_components["street_address"]} ${ac.long_name}`

        if (ac.types.includes("locality")) address_components["city"] = ac.long_name

        if (ac.types.includes("administrative_area_level_1"))
            address_components["state"] = ac.long_name

        if (ac.types.includes("postal_code"))
            address_components["zip"] = ac.long_name
    })

    setState(address_components)
}

const FormContainer = (props) => {
    const { google, model } = props
    const [state, setState] = React.useState({
        street_address: props.street_address,
        street_address_2: props.street_address_2,
        city: props.city,
        state: props.state,
        //This is where the bug is coming from
        zip: props.zip,
    })

    const handleChange = (street_address) => setState({ ...state, street_address })

    const handleUpdate = (name, e) => {
        console.log(name, e.target.value);

        if (name === "zip") {
            //   const re = /^[0-9\b]+$/
            // if (e.target.value == "" || re.test(e.target.value)) {
            //   setState({ [name]: e.target.value })
            // }
            if (state.zip === undefined) {
                setState({ ...state, [name]: "" })
            }

            if (
                e.target.value === "" ||
                e.target.value === undefined ||
                (e.target.value.length <= 5 && Number(e.target.value))
            ) {
                console.log(Number(e.target.value))
                setState({ ...state, [name]: e.target.value })
            }
        } else {
            setState({ ...state, [name]: e.target.value })
        }
    }

    const handleError = () => {
        console.log(state.zip)

        if (!Number(state.zip)) {
            return <p>Must be a 5 Digit U.S Zip Code</p>
        }
    }

    const handleSelect = (street_address, placeId) => {
        setState({ ...state, street_address })

        var request = { placeId: placeId }
        var placeRequest = new Promise((resolve, reject) => {
            new google.maps.places.PlacesService(
                document.createElement("div")
            ).getDetails(request, (place, status) => {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place)
                } else {
                    reject()
                }
            })
        }).then((response) => separateComponents(response, (e) => setState(e)))
    }

    return (
        <PlacesAutocomplete
            value={state.street_address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={{
                componentRestrictions: { country: ["us"] },
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <div className="sixteen columns">
                        <h4>Street Address</h4>
                        <input
                            {...getInputProps({
                                placeholder: "Street Address",
                                className: "search-field",
                            })}
                            name={`${model}[street_address]`}
                            id={`${model}_street_address`}
                        />

                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"

                                const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" }

                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <br />

                        <input
                            id={`${model}_street_address_2`}
                            className="search-field"
                            placeholder="Ex: Suite #"
                            type="text"
                            value={state.street_address_2}
                            name={`${model}[street_address_2]`}
                            onChange={(e) => handleUpdate("street_address_2", e)}
                        />
                        <br />
                    </div>
                    <div className="one-third column">
                        <h4>City</h4>
                        <input
                            id={`${model}_city`}
                            className="search-field"
                            type="text"
                            value={state.city}
                            name={`${model}[city]`}
                            onChange={(e) => handleUpdate("city", e)}
                        />
                        <br />
                    </div>
                    <StateField selected={state.state} model={model} onChange={(e) => handleUpdate("state", e)} />
                    <div className="one-third column">
                        <h4>Zip Code</h4>
                        <input
                            id={`${model}_zip`}
                            className="search-field"
                            required="required"
                            type="text"
                            value={state.zip}
                            name={`${model}[zip]`}
                            onChange={(e) => handleUpdate("zip", e)}
                        />
                        {handleError()}

                        <br />
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

const StateField = ({ selected, model, onChange }) => {
    return (
        <div className="one-third column">
            <h4>State</h4>
            <select className="ddselect" name={`${model}[state]`} onChange={onChange}>
                <option value="" disabled selected>
                    Select State
                </option>

                {states.map((state) => {
                    if (state.includes(selected)) {
                        return (
                            <option selected="selected" value={state[0]}>
                                {state[1]}
                            </option>
                        )
                    }
                    return <option value={state[0]}>{state[1]}</option>
                })}
            </select>
        </div>
    )
}
export default GoogleApiWrapper({
    apiKey: "AIzaSyB2D_GsF52iHq1JEsTsbjI5YgS1WfCSAgo",
    libraries: ["places"],
})(FormContainer)