import React from "react"

function PracticeFavoriteButton(props) {
  const [state, setState] = React.useState({
    favorited: props.favorited,
  })

  const toggleFavorite = () => {
    let options = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.authenticity_token,
      },
    }

    fetch(props.path, options)
      .then((res) => res.json())
      .then(
        (result) => {
          const { favorited } = result.status
          setState({ ...state, favorited })
        },
        (error) => console.log(error)
      )
  }

  const favoriteCaption = () => {
    return state.favorited ? "Unfavorite" : "Favorite"
  }

  return (
    <button class="favorite-button ">
      <a
        href="#"
        className="text-white text-decoration-none"
        onClick={() => toggleFavorite()}
        style={{ color: 'white' }}
      >
        <i className="fa fa-heart text-white px-1"></i> {favoriteCaption()}
      </a>
    </button>
  )
}

export default PracticeFavoriteButton
