import * as React from 'react'
import { Modal } from 'react-bootstrap'

export default function JobFilled({
  name, practiceName, temp_start, temp_end, temp_date
}) {
  // Show Popup State
  const [modalOpen, setModalOpen] = React.useState(true)

  // Close Modal
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Temp Job
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>
            Hi {name}
          </h3>
          <p>
            {practiceName} has requested you for a temp job.
          </p>

          <h4>Time</h4>
          <p>{temp_start} to {temp_end}</p>

          <h4>Date</h4>
          <p>{temp_date}</p>

          <h4>Are you available?</h4>
          <div>
            <a href="?accept=1" className="btn btn-primary me-2 shadow">
              Accept
            </a>

            <a href="?decline=1" className="btn btn-light shadow">
              Decline
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}