import * as React from "react";

interface ResumeRequestProps {
  profile_id: number;
  requested: boolean;
  authenticity_token: string;
}

export default function ResumeRequest({
  profile_id,
  requested,
  authenticity_token,
}: ResumeRequestProps) {
  // var Spinner = require("react-spinkit");

  const [state, setState] = React.useState({ requested, loading: false });

  const sendButtonOnClick = () => {
    toggleLoading(true);
    sendRequest(profile_id, authenticity_token, () => toggleRequested(true));
  };

  const toggleRequested = (requested = !state.requested) =>
    setState({ ...state, requested });

  const toggleLoading = (loading = !state.loading) =>
    setState({ ...state, loading });

  React.useEffect(() => {
    if (state.requested) toggleLoading(false);
  }, [state.requested]);

  return (
    <div className="card shadow-sm profile-card-design mt-4">
      <div className="card-header profile-cards-head">
        <i className="fas fa-book"></i> Resume & Work Experience
      </div>
      <div className="padding-right mb-20">
        <div className="text-center">
          {!state.requested && !state.loading && (
            <RequestForm sendButtonOnClick={sendButtonOnClick} />
          )}
          {state.requested && <RequestedMessage />}
          {state.loading && (
            // <Spinner name="three-bounce" color="#186fc9" animationSpeed={5} />
            <>Loading...</>
          )}
        </div>
      </div>
    </div>
  );
}

function RequestedMessage() {
  return (
    <div className="pt-3 pb-4">
      <p>Thank you for your interest in this candidate. Your request has been
        sent.</p>
      <a href="/profiles" className="btn btn-primary btn-rounded">
        <i className="fas fa-search"></i> Search for More Candidates
      </a>
    </div>
  );
}

function RequestForm({ sendButtonOnClick }) {
  return (
    <div className="pt-3 pb-4">
      <p>This user hasn't uploaded their resume or experience.</p>
      <a href="#resume_request" onClick={sendButtonOnClick} className="btn btn-primary btn-rounded">
        <i className="fas fa-envelope"></i> Request Resume & Working Experience
      </a>
    </div>
  );
}

function sendRequest(
  profile_id: number,
  authenticity_token: string,
  callback: (value: boolean) => any
) {
  const url = `/resume_requests.json`;

  const options: object = {
    method: "POST",
    redirect: "manual",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify({ profile_id }),
  };

  fetch(url, options)
    .then((result) => result.json())
    .then(
      () => callback(true),
      (error) => console.log(error)
    );
}
