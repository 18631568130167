import * as React from 'react';
import Plan from './Plan';

export default function Plans({ plans, className, productClassName, hide_features = false, same_features = false, tagline = 'Essential Plan for Every Dental Office' }) {
  const firstPlan = plans[0];
  const { features } = firstPlan;
  const plan_features = features.split('|')

  return (
    <div className={className}>
      {plans.map(plan =>
        <div className={productClassName}>
          <Plan {...plan} hide_features={hide_features} />
        </div>
      )}
      {same_features && (
        <div className="sixteen columns">
          <div className="mt-60 mb-20" style={{ marginTop: 40 }}>
            <ul>
              <h2 className="text-center mt-60 mb-20">
                <li className="centered"><strong>{tagline}</strong></li>
              </h2>
              {plan_features.map(ft =>
                <li className="four columns text-center" style={{ fontSize: 20, padding: '20px 0' }}>
                  <i className="fa fa-check" style={{ color: '#6fc918' }}></i> {ft}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div >
  );
}