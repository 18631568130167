import * as React from 'react';

interface ProductProps {
  id: number;
  name: string;
  price: string;
  savings_price?: string;
  features?: string;
  trial?: boolean;
  product_name: string;
  tagline: string;
  savings: string;
  product_type?: string;
  stripe_plan: string;
  form_authenticity_token?: string;
  button?: boolean
  user_signed_in?: boolean
  auto_redirect?: boolean
}

export default function Product({
  name, price, id, savings_price, features, trial, product_name, tagline, savings, product_type = 'plan', stripe_plan, form_authenticity_token, button = true, user_signed_in = false, auto_redirect = false
}: ProductProps) {
  const formRef = React.useRef<HTMLFormElement>();
  const plan_features: Array<string> = features.split('|');

  React.useEffect(() => {
    if (auto_redirect && formRef.current) {
      formRef.current.submit()
      formRef.current.dispatchEvent(new Event("submit"))
    }
  }, [formRef]);

  return (
    <div className="plan-container">
      {auto_redirect && (
        <>
          <h2>Please wait while we process your request...</h2>
          <form action="/payment_method" method="post" ref={formRef}>
            <input name="authenticity_token" value={form_authenticity_token} type="hidden" />
            <input type="hidden" name="id" value={id} />
            <input type="hidden" name="plan_id" value={stripe_plan} />
          </form>
        </>
      )}
      {!auto_redirect && (
        <div className="plan color-1 dropshadow">
          <div className="plan-price">
            <h3 className="comoTitle"><strong style={{ color: 'white' }}>{name}</strong></h3>
            <span className="plan-currency">$ </span>
            <span className="value">{price}</span>
            <p className="promo-yellow">
              <small>{product_name}</small>
            </p>
          </div>
          <div className="plan-features landing">
            <ul>
              {plan_features.map(ft =>
                <li><i className="fa fa-check"></i> {ft}</li>)}
            </ul>
            {button && !user_signed_in && (
              <a className="stripe-button-el" href={`/plans/${id}`}>
                <span>{trial ? "Start 14 Day Free Trial" : "Get Started"}</span>
              </a>
            )}
            {button && user_signed_in && (
              <form action="/payment_method" method="post" ref={formRef}>
                <input name="authenticity_token" value={form_authenticity_token} type="hidden" />
                <input type="hidden" name="id" value={id} />
                <input type="hidden" name="plan_id" value={stripe_plan} />
                <input style={{ width: '100%', borderRadius: 0, fontSize: 24 }} className="stripe-button button" type="submit" value={trial ? 'Try for Free' : 'Pay with Card'} />
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}