import * as  React from "react";
class ConfidentialCheckBox extends React.Component {
    render() {
        let { confidential } = this.props;
        confidential = confidential == null ? false : confidential
        return (
            <div className="fullwidth">
                <h3>Confidential?</h3>
                <input name="job[confidential]"
                    type="hidden"
                    value={confidential} />
                <input type="checkbox"
                    id="job_confidential"
                    value={confidential}
                    checked={confidential}
                    onChange={(e) => this.props.handleToggle(e, 'confidential')} />
                <span>Keep Dental Practice Details Confidential</span>
            </div>
        );
    }
}
export default ConfidentialCheckBox;