import "./Common-poly"
import React from "react"
import Pagination from "./search/Pagination"
import JobsList from "./Job/JobList"
import JobsFilters from "./job_search/JobFilters"
import Popup from "reactjs-popup"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

const URI = "/jobsearch.json"

const zrUrl = ({
  search,
  location,
  radius_miles,
  days_ago,
  jobs_per_page,
  page,
}) =>
  `/ziprecruiter_api?search=${search}&location=${location}&radius_miles=${radius_miles}&days_ago=${days_ago}&jobs_per_page=${jobs_per_page}&page=${page}`

function changeDate({ date, fieldName = "date" }) {
  const dateObject = {
    [fieldName]: date.format("YYYY-MM-DD"),
    position_type_eq_any: ["temp"],
  }
  return dateObject
}

function initialState(props) {
  return {
    jobs: [],
    form_elements: {
      ...props.form_elements,
      position_type_eq_any: ["fulltime", "parttime", "longtermtemp", "temp"],
    },
    sort: "newest",
    location: props.location,
    page: 1,
    miles: 100,
    loaded: false,
    zip: {
      jobs: [],
    },
  }
}

export default class Jobs extends React.Component {
  state = initialState(this.props)

  componentDidMount() {
    this.sendQuery()
  }

  onChangeCheckbox = event => {
    const { name, value, checked } = event.target
    const {
      form_elements: { position_type_eq_any },
    } = this.state
    let new_position_type_eq_any = position_type_eq_any

    if (checked) {
      if (!new_position_type_eq_any.includes(value)) {
        new_position_type_eq_any.push(value)
      }
    } else {
      new_position_type_eq_any = position_type_eq_any.filter(v => v !== value)
    }

    // Resets Temp Date Filter
    const not_temp = ["fulltime", "parttime", "longtermtemp"].some(r =>
      new_position_type_eq_any.includes(r)
    )

    const reset_temp_date = not_temp ? { temp_date_eq: "" } : {}

    this.setState(
      state => ({
        ...state,
        form_elements: {
          ...state.form_elements,
          ...reset_temp_date,
          position_type_eq_any: new_position_type_eq_any,
        },
      }),
      () => this.sendQuery()
    )
  }

  onChangeLocation = event => {
    const { name, value } = event.target

    if (name == "miles") {
      this.setState(
        state => ({ ...state, [name]: value }),
        () => this.sendQuery()
      )
    }

    if (name == "location") {
      const re = /^[0-9\b]+$/
      const isZipCode = value.length <= 5 && (value === "" || re.test(value))
      if (isZipCode)
        this.setState(
          state => ({ ...state, [name]: value }),
          () => {
            if (value.length == 5) this.sendQuery()
          }
        )
    }
  }

  onChangeDate = props =>
    this.setState(
      state => ({
        ...state,
        form_elements: {
          ...state.form_elements,
          ...changeDate(props),
        },
      }),
      () => this.sendQuery()
    )

  onChangeSort = event => {
    const sort = event.target.value
    const stateObject = (sort, state) =>
      sort.includes("temp")
        ? {
          sort,
          form_elements: {
            ...state.form_elements,
            position_type_eq_any: ["temp"],
          },
        }
        : { sort }

    this.setState(
      state => stateObject(sort, state),
      () => this.sendQuery()
    )
  }

  setForm = event => {
    const { name, value } = event.target
    this.setState(
      state => ({
        form_elements: { ...state.form_elements, [name]: value },
        page: 1,
      }),
      () => {
        this.sendQuery()
      }
    )
  }

  navigateTo = page => {
    this.setState(
      state => ({ ...state, page }),
      () => {
        this.sendQuery()
        window.scrollTo(0, 100)
      }
    )
  }

  sendQuery = () => {
    const { page, form_elements, sort, location, miles } = this.state
    const body = JSON.stringify({
      page,
      sort,
      location,
      miles,
      search: { ...form_elements },
    })
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": this.props.authenticity_token,
    }

    fetch(URI, { method: "POST", headers, body })
      .then(res => res.json())
      .then(res => {
        const { jobs, total_pages, records } = res
        const current_page = res["current_page="]
        const prev_page = current_page === 1 ? current_page : current_page - 1
        const next_page =
          current_page === total_pages ? current_page : current_page + 1

        this.setState(
          state => ({
            ...state,
            jobs,
            current_page,
            total_pages,
            records,
            prev_page,
            next_page,
            loaded: true,
          }),
          () => {
            this.getSponsoredJobs()
          }
        )
      })
  }


  getSponsoredJobs = () => {
    const search =
      this.state.form_elements.job_type_group_eq.length > 0
        ? `${this.state.form_elements.job_type_group_eq.split("-")[1]}`
        : "Dental"

    const url = zrUrl({
      search: search,
      location: this.state.location ? this.state.location : "",
      radius_miles: 25,
      days_ago: "",
      jobs_per_page: 5,
      page: this.state.page,
    })

    console.log(url)

    // fetch(url, {})
    //   .then(res => res.json())
    //   .then(res => this.setState({ zip: { jobs: res.jobs } }))
  }

  render() {
    const {
      jobs,
      current_page,
      total_pages,
      records,
      prev_page,
      next_page,
      location,
      form_elements,
      miles,
      loaded,
      zip,
      sort,
    } = this.state

    const { job_types } = this.props

    return (
      <div className="container">
        <div className="row browsing-font mt-5 px-4">
          {isMobile && (
            <div className="col-12 my-4 text-center">
              <button
                type="button"
                class="btn browsing-btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Search Filters
              </button>

              <div
                class="modal fade px-0"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <JobsFilters
                        form_elements={form_elements}
                        job_types={job_types}
                        location={location}
                        miles={miles}
                        onChangeSort={e => this.onChangeSort(e)}
                        onChangeLocation={e => this.onChangeLocation(e)}
                        onChangeCheckbox={e => this.onChangeCheckbox(e)}
                        onChangeDate={e => this.onChangeDate(e)}
                        onChange={e => this.setForm(e)}
                      />
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn browsing-btn"
                        data-bs-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="col-12 col-lg-8 card shadow mb-4 px-0 browsing-card-design"
            id="job-list"
          >
            <div className="card-header browsing-cards-head">
              <i className="fas fa-newspaper"></i> Jobs
            </div>
            {/* {jobs.length > 0 && ( */}
            <JobsList jobs={jobs} zipjobs={zip.jobs} sort={sort} />
            {/* )} */}
            {/* {(jobs.length < 1 && loaded) && (<NoResults />)} */}
            {jobs.length < 1 && !loaded && <LoadingResults />}

          </div>
          <div className="col-12 col-lg-4 " id="job-filters">
            {!isMobile && (
              <JobsFilters
                form_elements={form_elements}
                job_types={job_types}
                location={location}
                miles={miles}
                onChangeSort={e => this.onChangeSort(e)}
                onChangeLocation={e => this.onChangeLocation(e)}
                onChangeCheckbox={e => this.onChangeCheckbox(e)}
                onChangeDate={e => this.onChangeDate(e)}
                onChange={e => this.setForm(e)}
              />
            )}
          </div>
          {jobs.length > 0 && (
            <Pagination
              current_page={current_page}
              total_pages={total_pages}
              records={records}
              prev_page={prev_page}
              next_page={next_page}
              navigateTo={this.navigateTo}
            />
          )}
        </div>

      </div>
    )
  }
}

function LoadingResults() {
  return (
    <div className="padding-right p-2">
      <ul className="job-list full list-unstyled">
        <div className="field">
          <li className="text-center">
            <img
              src="https://directdental-production.s3.us-west-2.amazonaws.com/missing/missing.png"
              alt=""
              height={100}
            />
            <div className="job-list-content text-center">
              <h4 className="fullname">Loading...</h4>
              <p>Please wait a moment!</p>
            </div>
          </li>
          <div className="clearfix"></div>
        </div>
      </ul>
    </div>
  )
}

function NoResults() {
  return (
    <div className="padding-right p-2">
      <ul className="job-list full">
        <div className="field">
          <li>
            <img
              src="https://directdental-production.s3.us-west-2.amazonaws.com/missing/missing.png"
              alt=""
              height={100}
            />
            <div className="job-list-content">
              <h4 className="fullname">No Results Found</h4>
              <p>
                We couldn't find any jobs with your specified search parameters.
                Try reducing your search criteria.
              </p>
            </div>
          </li>
          <div className="clearfix"></div>
        </div>
      </ul>
    </div>
  )
}
