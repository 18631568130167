import * as React from 'react'
import moment from 'moment-timezone'

const Invite = ({ invite, authenticity_token }) => {
    // Ignored State
    const [ignored, setIgnored] = React.useState(invite.ignored)

    const { job } = invite
    const application_date = moment(invite.created_at)
        .tz(job.practice.timezone)
        .format('llll')

    // REST API Update Request to mark invite as ignored
    const ignoreInvite = () => {
        const url = `/application_invites/${invite.id}.json`
        const method = 'PATCH'
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticity_token,
        }
        const body = {
            ignore: !ignored,
        }
        fetch(url, { method, headers, body: JSON.stringify(body) })
            .then(response => response.json())
            .then(data => {
                setIgnored(!ignored)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="ManageApplicantContainer">
            <div className={`application`} style={{ flex: 1 }}>
                <div className="app-content">
                    <div className="info">
                        <a href={`/jobs/${job.id}`}>
                            <img src={job.practice.avatar_url} alt="" />
                        </a>
                        <span>
                            <a href={`/jobs/${job.id}`}>{job.job_type.title}</a>
                        </span>
                        <ul>
                            <li>
                                <i className="fa fa-briefcase"></i> {job.practice.name}
                            </li>
                            <li>
                                <i className="fa fa-map-marker"></i> {job.practice.city}, {job.practice.state}
                            </li>
                            {job.compensation_full && (
                                <li>
                                    <i className="fa fa-money"></i> {job.compensation_full}
                                </li>
                            )}
                            <li>
                                <i className="fa fa-calendar"></i> Invited {application_date}
                            </li>
                        </ul>
                        <a className="send button" target="_blank" style={{ float: 'right' }} rel="nofollow" data-method="get" href={`/jobs/${job.id}/apply`}>
                            Quick Apply
                        </a>
                        {/* Ignore Button */}
                        <a className="send button" rel="nofollow" onClick={ignoreInvite} style={{ backgroundColor: 'grey', float: 'right', marginRight: 10 }}>
                            {ignored && 'Ignored'}
                            {!ignored && 'Ignore'}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ApplicationInvitations = ({ invites, authenticity_token, timezone }) => {
    return invites.map(invite => {
        return Invite({
            invite,
            authenticity_token,
            timezone
        })
    })
}

export default ApplicationInvitations