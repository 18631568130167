import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModifyMembershipButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  return (
    <>
      <a href="#modify" onClick={handleOpenModal} className="btn text-white cardButton mx-1 my-1 btn-lg col-12">
        Modify Membership
      </a>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modify Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are on a Henry Schein promotional plan. Please call us to make plan adjustments.

          {/* Call Us link to 1-800-799-2644 */}
          <a href="tel:18007992644" className="btn text-white cardButton mx-1 d-block mt-3">
            Call Us at 1-800-799-2644
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModifyMembershipButton;