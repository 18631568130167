import React from "react";
import DraftTextArea from "./DraftTextArea";
import TextField from "./TextField";

const ProfileExperience = (props) => {
  const [state, setState] = React.useState({ experience: props.experience });
  const { update } = props;

  const addExperience = () => {
    setState({
      experience: [
        ...state.experience,
        {
          start_date: "",
          end_date: "",
          description: "",
          title: "",
          employer: "",
          current: false,
        },
      ],
    });
  };

  const moveUpFun = (i) => {
    const experience = move(state.experience, i, i - 1);
    setState({ experience });
  };

  const moveDownFun = (i) => {
    if (i === state.experience.length - 1) {
      return false;
    }
    const experience = move(state.experience, i, i + 1);
    setState({ experience });
  };

  const deleteItem = (index) => {
    const temp_experience = [...state.experience];
    const experienceSpliced = temp_experience.splice(index, 1);
    setState({ experience: temp_experience });
  };

  const updateItem = (key, value, index) => {
    const experiences = [...state.experience];
    const item = experiences[index];
    item[key] = value;
    experiences[index] = item;
    setState({ experience: experiences });
  };

  React.useEffect(() => {
    if (update) update(state.experience);
  }, [state]);

  return (
    <div className="form-inside">
      {state.experience.map((exp, index) => (
        <Experience
          index={`${index}-${exp.title}`}
          {...exp}
          deleteItemFun={() => deleteItem(index)}
          moveUpFun={() => moveUpFun(index)}
          moveDownFun={() => moveDownFun(index)}
          updateEmployer={(e) => updateItem("employer", e, index)}
          updateTitle={(e) => updateItem("title", e, index)}
          updateStartDate={(e) => updateItem("start_date", e, index)}
          updateEndDate={(e) => updateItem("end_date", e, index)}
          updateDescription={(e) => updateItem("description", e, index)}
          updateCurrent={(e) => updateItem("current", e.target.checked, index)}
        />
      ))}
      <a onClick={() => addExperience()} className="btn button my-2 add-box">
        <i className="fa fa-plus-circle"></i> Add Experience
      </a>
    </div>
  );
};

const Experience = ({
  start_date,
  end_date,
  description,
  title,
  employer,
  current,
  index,
  deleteItemFun,
  moveUpFun,
  moveDownFun,
  updateEmployer,
  updateTitle,
  updateStartDate,
  updateEndDate,
  updateDescription,
  updateCurrent,
}) => {
  return (
    <div className="form boxed experience-box experience-background px-3 py-3 my-3">
      <div className="d-flex justify-content-end align-items-end mb-2">
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => moveDownFun()}
        >
          <i className="fa fa-arrow-down"></i>
        </a>
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => moveUpFun()}
        >
          <i className="fa fa-arrow-up"></i>
        </a>
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => deleteItemFun()}
        >
          <i className="fa fa-close"></i>
        </a>
      </div>

      <label className="text-left" htmlFor="profile_experience_employer">
        Employer
      </label>
      <TextField
        name="profile[experiences_attributes][employer]"
        id="profile_experience_employer"
        value={employer}
        updateFunc={(e) => updateEmployer(e)}
        placeholder="Employer"
      />

      <label className="text-left" htmlFor="profile_experience_title">
        Job Title
      </label>
      <TextField
        name="profile[experiences_attributes][title]"
        id="profile_experience_title"
        value={title}
        updateFunc={(e) => updateTitle(e)}
        placeholder="Job Title"
      />

      <label className="text-left" htmlFor="profile_experience_start_date">
        Start Date
      </label>
      <input
        type="date"
        className="form-control"
        name="profile[experiences_attributes][start_date]"
        id="profile_experience_start_date"
        value={start_date}
        onChange={(e) => updateStartDate(e.target.value)}
        placeholder="Start Date"
      />

      <label className="text-left" htmlFor="profile_experience_end_date">
        End Date
      </label>
      <input
        type="date"
        className="form-control"
        name="profile[experiences_attributes][end_date]"
        id="profile_experience_end_date"
        value={end_date}
        onChange={(e) => updateEndDate(e.target.value)}
        placeholder="End Date"
      />

      <div className="checkbox-wrapper">
        <label>
          <input type="checkbox" checked={current} onChange={updateCurrent} />
          Currently working here
        </label>
      </div>

      <label
        className="text-left mt-3"
        htmlFor="profile_experience_description"
      >
        Description
      </label>
      <DraftTextArea
        key={`${index}-desc`}
        updateFunc={(e) => updateDescription(e)}
        containerclassName={""}
        name="profile[experiences_attributes][description]"
        id="profile_experience_description"
        rich={true}
        value={description}
      />
    </div>
  );
};

function move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export default ProfileExperience;
