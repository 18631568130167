import * as React from "react"
import "./Common-poly"
import "whatwg-fetch"

import moment from "moment"
import * as Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { CSSTransitionGroup } from "react-transition-group" // ES6
import InputMask from "react-input-mask"
import RichTextEditor from "react-rte"

// Description Dependencies
import { EditorState, ContentState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"

// Form Components
import {
  JobFormTag,
  PositionHourRowTags,
  JobFormTabButtons,
} from "./job_form/Common"
import ConfidentialCheckBox from "./job_form/ConfidentialCheckBox"
import CompensationType from "./job_form/CompensationType"
import HiringType from "./job_form/HiringType"
import PracticeSelect from "./job_form/PracticeSelect"
import PositionTypeSelect from "./job_form/PositionTypeSelect"
import DescriptionTemplate from "./job_form/DescriptionTemplate"
import DescriptionEditor from "./job_form/DescriptionEditor"

const ALLDAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

function initialState({ job, compact = false }) {
  let jobHours = {}

  for (var day in ALLDAYS) {
    let open = `${ALLDAYS[day]}_open`
    let start_hr = `${ALLDAYS[day]}_start`
    let end_hr = `${ALLDAYS[day]}_end`

    // Sets the day_open, default: false
    if (job[open] == null) jobHours[open] = false

    // Sets the start and end times to the day
    if (job[end_hr] == null)
      jobHours[end_hr] = moment.utc().set({ hour: 17, minute: 0 })
    if (job[start_hr] == null)
      jobHours[start_hr] = moment.utc().set({ hour: 8, minute: 0 })
  }

  const state = {
    job: {
      ...job,
      ...jobHours,
      compensation_type:
        job.compensation_type == null ? "hourly" : job.compensation_type,
      years_experience:
        job.years_experience == null ? "0-2" : job.years_experience,
      position_type: job.position_type == null ? "fulltime" : job.position_type,
    },
    job_id: undefined,
    error: null,
    isLoaded: false,
    processingComplete: false,
    practices: [],
    job_types: [],
    skills: [],
    skill_categories: [],
    errors: {},
    tabIndex: 0,
    description: RichTextEditor.createValueFromString(job.description, "html"),
    description_template: "",
    compensation_range: job.compensation || !job.id ? true : false,
    colSize: compact == true ? "sixteen" : "eight",
    editorState: EditorState.createEmpty(),
  }
  return state
}

class JobForm extends React.Component {
  state = {
    ...initialState({
      job: this.props.job,
      compact: this.props.compact,
    }),
  }

  // Open/Close Modal (via user profiles)
  openModal = () => this.setState({ modalIsOpen: true })
  afterOpenModal = () => (this.subtitle.style.color = "#f00")
  closeModal = () => this.setState({ modalIsOpen: false })

  componentDidMount() {
    let job = this.state.job

    // Get practices, job types, skills via API requests.
    this.getPractices()
    this.getJobTypes()
    this.getSkills()

    // Ensure daily times are set up
    this.prepareTimes()

    // Enforce the position_type as temp if the form is used in a temp_only context
    if (this.props.temp_only == true) job.position_type = "temp"

    // Ensure that job skills starts with an empty set
    if (job.skills == null) job.skills = {}

    // Set any pre-existing props from the form
    for (var skill in this.props.skills) {
      let id = this.props.skills[skill].id
      job.skills[id] = true
    }

    this.setState({ job: job })

    // Enabling this will allow the template to show up by default
    this.setDescriptionTemplate(job.description)
  }

  // Get Practices via Practices API
  getPractices = () => {
    fetch("/practices.json")
      .then((res) => res.json())
      .then(
        (result) => this.processPracticeResult(result),
        (error) => this.setState((state, props) => ({ isLoaded: true, error }))
      )
  }

  // Process Practices
  processPracticeResult = (result) => {
    // Populate practices
    this.setState((state, props) => ({
      isLoaded: true,
      practices: result.practices,
    }))

    if (this.props.practice_id) {
      let practice = result.practices.find(
        (practice) => practice.id == this.props.practice_id
      )
      if (practice != null) {
        console.log("Setting practice to: " + this.props.practice_id)
        let job = this.state.job
        job.practice_id = this.props.practice_id
        this.setState({ job: job })
      }
    }

    // Set an initial value for practice selection
    if (this.state.job.practice_id == 0 || this.state.job.practice_id == null) {
      let job = this.state.job
      job.practice_id = result.practices[0].id
      if (this.props.job.sms_phone == null) {
        job.sms_phone = result.practices[0].last_sms
      }
      this.setState({ job: job })
    }
  }

  // Get Job Types via API
  getJobTypes = () => {
    fetch("/job_types.json")
      .then((res) => res.json())
      .then(
        (result) => this.processJobTypesResult(result),
        (error) => this.setState((state, props) => ({ isLoaded: true, error }))
      )
  }

  // Process job types
  processJobTypesResult = (result) => {
    let job = this.state.job
    let job_types = []

    // TODO: Abstract this to the back end.  This is a temporary fix.
    if (this.props.filter_by_job_type_id == true) {
      for (let i = 0; i < result.job_types.length; i++) {
        let jt = result.job_types[i]
        if (this.props.job_type_group.includes(jt.group)) job_types.push(jt)
      }
    } else {
      // TODO: After abstracting above, make this the default behavior
      job_types = result.job_types
    }

    this.setState((state, props) => ({ isLoaded: true, job_types: job_types }))

    if (this.state.job.job_type_id == 0 || this.state.job.job_type_id == null) {
      job.job_type_id = this.state.job_types[0].id

      this.setState(
        (state, props) => ({ job: job }),
        () => {
          var jt = this.state.job_types.filter(
            (obj) => obj.id == result.job_types[0].id
          )

          if (jt.length == 0) jt = this.state.job_types

          const desc_template =
            this.state.job.position_type != "temp" &&
              this.state.job.position_type != "longtermtemp"
              ? jt[0].description_template
              : jt[0].description_template_temp

          // this.setState({ description_template: desc_template });
        }
      )
    }
  }

  resetJobTemplate = () => {
    var jt = this.state.job_types.filter(
      (obj) => obj.id == this.state.job.job_type_id
    )

    if (jt.length == 0) jt = this.state.job_types

    const desc_template =
      this.state.job.position_type != "temp" &&
        this.state.job.position_type != "longtermtemp"
        ? jt[0].description_template
        : jt[0].description_template_temp

    this.setState({ description_template: desc_template })
  }

  // Get Skills from API
  getSkills = () => {
    fetch("/skills.json")
      .then((res) => res.json())
      .then(
        (result) => this.processSkillsResult(result),
        (error) => this.setState((state, props) => ({ isLoaded: true, error }))
      )
  }

  // Process Skills Result
  processSkillsResult = (result) => {
    this.setState({
      isLoaded: true,
      skills: result.skills,
    })

    let skill_categories = this.state.skill_categories
    let skills = this.state.skills

    for (let i = 0; i < skills.length; i++) {
      if (!skill_categories.includes(skills[i].category)) {
        skill_categories.push(skills[i].category)
      }
    }

    this.setState({
      skill_categories: skill_categories.sort().reverse(),
    })
  }

  sendForm = (e) => {
    this.setState({ processing: true })
  }

  removeMaskCharacters = (maskedString) => {
    return maskedString == null ? "" : maskedString.replace(/\D+/g, "")
  }

  handleSubmit = (event) => {
    event.preventDefault()

    var shouldSubmit = true

    this.checkValidations(() => {
      let { errors } = this.state
      if (Object.keys(errors).some((k) => errors[k])) {
        if (errors.sms_phone || errors.temp_date_error) {
          this.setTab(0)
        } else if (errors.description) {
          this.setTab(3)
        } else {
          this.setTab(2)
        }
        shouldSubmit = false
      } else {
        this.submitFormData()
      }
    })
  }

  submitFormData = () => {
    this.setState({ processing: true })

    let job = this.state.job
    let skills = [""]
    for (var skill in job.skills) {
      if (job.skills[skill] == true) skills.push(`${skill}`)
    }

    let jobFormData = {
      request_profile: this.props.profile_id !== undefined,
      profile_id: this.props.profile_id,
      job: {
        job_type_id: job.job_type_id,
        practice_id: job.practice_id,
        confidential:
          job.position_type == "temp" ? false : job.confidential == true,
        position_type: job.position_type,
        compensation_type: job.compensation_type,
        sunday_open: job.sunday_open == true,
        "sunday_start(5i)": job.sunday_start,
        "sunday_end(5i)": job.sunday_end,
        monday_open: job.monday_open == true,
        "monday_start(5i)": job.monday_start,
        "monday_end(5i)": job.monday_end,
        tuesday_open: job.tuesday_open == true,
        "tuesday_start(5i)": job.tuesday_start,
        "tuesday_end(5i)": job.tuesday_end,
        wednesday_open: job.wednesday_open == true,
        "wednesday_start(5i)": job.wednesday_start,
        "wednesday_end(5i)": job.wednesday_end,
        thursday_open: job.thursday_open == true,
        "thursday_start(5i)": job.thursday_start,
        "thursday_end(5i)": job.thursday_end,
        friday_open: job.friday_open == true,
        "friday_start(5i)": job.friday_start,
        "friday_end(5i)": job.friday_end,
        saturday_open: job.saturday_open == true,
        "saturday_start(5i)": job.saturday_start,
        "saturday_end(5i)": job.saturday_end,
        min_compensation:
          job.min_compensation == null ? 0 : job.min_compensation,
        compensation: job.compensation == null ? 0 : job.compensation,
        doe: job.doe == true,
        years_experience: job.years_experience,
        job_title: job.job_title == null ? "" : job.job_title,
        description: job.description,
        temp_date: job.temp_date,
        temp_start: job.temp_start,
        temp_end: job.temp_end,
        benefit_medical: job.benefit_medical == true,
        benefit_dental: job.benefit_dental == true,
        benefit_vision: job.benefit_vision == true,
        benefit_401k: job.benefit_401k == true,
        benefit_pto: job.benefit_pto == true,
        benefit_bonuses: job.benefit_bonuses == true,
        skill_ids: skills,
        sms_phone: this.removeMaskCharacters(job.sms_phone),
      },
    }
    // FIXME: Redundant
    if (this.state.compensation_range) {
      jobFormData.job.compensation =
        job.compensation == null ? 0 : job.compensation
    } else {
      jobFormData.job.compensation = ""
    }

    jobFormData.job.doe = job.compensation == 0 && job.min_compensation == 0
    let jobs_options
    let url
    let redirectAfter = this.props.job.id != null || this.props.action == "edit"
    if (this.props.job.id == null) {
      url = "/jobs.json"
      jobs_options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.props.authenticity_token,
        },
        body: JSON.stringify(jobFormData),
      }
    } else {
      url = `/jobs/${this.props.job.id}.json`
      jobs_options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.props.authenticity_token,
        },
        body: JSON.stringify(jobFormData),
      }
    }
    let redirected = false
    fetch(url, jobs_options)
      .then((response) => {
        if (response.redirected == true) redirected = true
        return response.json()
      })
      .then(
        (result) => {
          // Auto request after submit?
          if (this.props.profile_id !== undefined) {
            // Temp Request
            if (this.state.job.position_type == "temp") {
              this.setState((state, props) => ({ processing: false }))
              this.setProcessingComplete()
              window.scrollTo(0, 100)
            } else {
              // TODO: Should show an error or something
              this.setState((state, props) => ({ processing: false }))
              this.setProcessingComplete()
              window.scrollTo(0, 100)
            }
          } else {
            window.location.href = result.url.replace(".json", "")
            this.setState({ processing: false })
            this.setProcessingComplete()
            window.scrollTo(0, 100)
          }

          this.setState((state, props) => ({
            redirectUrl: result.url.replace(".json", ""),
            job_id: result.id,
          }))
        },
        (error) => {
          console.log("ERROR", error)
        }
      )
  }

  requestTemp = (result) => {
    let url = `${result.url}/request.json`
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.authenticity_token,
      },
      body: JSON.stringify({
        temp_requests: [`${this.props.profile_id}`],
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          return true
        },
        (error) => {
          console.log(error)
          return false
        }
      )
  }

  setProcessingComplete = () => {
    this.setState((state, props) => ({ processingComplete: true }))
  }

  handleDate = (date, key) => {
    let job = this.state.job
    job[`${key}`] = date
    this.setState({ job: job })
  }

  checkValidations = (callback) => {
    let { min_compensation, compensation, sms_phone, description, temp_date } =
      this.state.job

    // Check for presence of Min Compensation
    // const min_compensation_error = this.state.job.doe ? false : !(parseInt(min_compensation) > 0);
    const min_compensation_error =
      !min_compensation && !compensation
        ? false
        : !(parseInt(min_compensation) > 0)

    // Check for presence of Compensation
    // const compensation_error = this.state.job.doe ? false :
    const compensation_error =
      !min_compensation && !compensation
        ? false
        : !(
          parseInt(compensation) > parseInt(min_compensation) &&
          parseInt(compensation) > 0
        ) && this.state.compensation_range

    // Check for presence of SMS Phone Number
    var sms_phone_error = false

    if (this.state.job.position_type != "temp") {
      sms_phone_error = false
    } else {
      try {
        sms_phone_error = !(sms_phone.replace(/\D/g, "").length > 9)
      } catch (err) {
        if (sms_phone === null) sms_phone_error = true
      }
    }

    const description_error = description.replace(/\W/g, "").length < 25

    var oneDate = moment.utc(new Date())
    var twoDate = moment.utc(temp_date, "YYYY-MM-DD")

    var dDiff = moment
      .duration(oneDate.startOf("day").diff(twoDate.startOf("day")))
      .asDays()
    const temp_date_error = parseInt(dDiff) <= 1
    if (temp_date_error) {
      console.log(
        "Date is future date",
        dDiff,
        parseInt(dDiff),
        oneDate,
        twoDate
      )
    } else {
      console.log(
        "Date is not future date",
        dDiff,
        parseInt(dDiff),
        oneDate,
        twoDate
      )
    }

    let errors = {
      min_compensation: min_compensation_error,
      compensation: compensation_error,
      sms_phone: sms_phone_error,
      description: description_error,
      temp_date_error:
        this.state.job.position_type != "temp" ? false : !temp_date_error,
    }

    if (callback) {
      this.setState({ errors: errors }, () => callback())
    } else {
      this.setState({ errors: errors })
    }
  }

  handleChange = (event, key) => {
    let { job } = this.state

    if (
      event.target.name == "job[min_compensation]" ||
      event.target.name == "job[compensation]"
    ) {
      const re = /^[0-9\b]+$/
      if (event.target.value === "" || re.test(event.target.value)) {
        job[`${key}`] = event.target.value
        this.setState({ job: job })
      }
    } else {
      job[`${key}`] = event.target.value
      this.setState({ job: job })
    }

    if (event.target.name == "job[position_type]") {
      var jt = this.state.job_types.filter((obj) => obj.id == job.job_type_id)

      const desc_template =
        this.state.job.position_type != "temp" &&
          this.state.job.position_type != "longtermtemp"
          ? jt[0].description_template
          : jt[0].description_template_temp

      if (this.state.description_template != "") {
        this.setState({ description_template: desc_template })
      }
    }

    if (event.target.name == "job[job_type_id]") {
      var jt = this.state.job_types.filter(
        (obj) => obj.id == event.target.value
      )

      const desc_template =
        this.state.job.position_type != "temp" &&
          this.state.job.position_type != "longtermtemp"
          ? jt[0].description_template
          : jt[0].description_template_temp

      if (this.state.description_template != "") {
        this.setState({ description_template: desc_template })
      }
    }

    const validatedFields = [
      "job[min_compensation]",
      "job[compensation]",
      "job[sms_phone]",
      "job[description]",
    ]

    if (validatedFields.includes(event.target.name)) this.checkValidations()
  }

  setDescriptionTemplate = (html) => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    const editorState = EditorState.createWithContent(contentState)

    this.onChangeDescription(editorState)
  }

  onChangeDescription = (editorState) => {
    let { job } = this.state
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)
    job[`description`] = markup
    this.setState({ job, editorState }, () => this.checkValidations())
  }

  onChange = (value) => {
    let { job } = this.state
    job[`description`] = value.toString("html")
    this.setState({ job: job, description: value })
  }

  handlePracticeChange = (event, key) => {
    this.handleChange(event, key)
    let { job } = this.state
    let practice = this.state.practices.find((x) => x.id == event.target.value)
    job.sms_phone = practice.last_sms
    this.setState({ job })
  }

  handleToggle = (event, key) => {
    let job = this.state.job
    job[`${key}`] = !job[`${key}`]
    this.setState({ job: job })
  }

  handleToggleSkill = (event, key) => {
    let job = this.state.job
    job.skills[key] = !job.skills[key]
    this.setState({ job: job })
  }

  prepareTimes = () => {
    let days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ]
    let { job } = this.state
    let { clickedDay } = this.props

    days.forEach((day) => {
      if (job[`${day}_start`] == null) {
        job[`${day}_start`] = moment.utc().set({ hour: 8, minute: 0 })
      }
      if (job[`${day}_end`] == null) {
        job[`${day}_end`] = moment.utc().set({ hour: 17, minute: 0 })
      }
    })

    if (job.temp_date == null || job.temp_date == false)
      job.temp_date = moment.utc()
    if (!job.temp_start)
      job.temp_start = moment.utc().set({ hour: 8, minute: 0 })
    if (!job.temp_end) job.temp_end = moment.utc().set({ hour: 17, minute: 0 })
    if (clickedDay != null) job.temp_date = clickedDay
    this.setState({ job: job })
  }

  positionTypeSelectTags = () => {
    if (this.state.job.job_type_id) {
      return (
        <PositionTypeSelect
          job_type_id={this.state.job.job_type_id}
          job_types={this.state.job_types}
          handleChange={this.handleChange}
        />
      )
    } else {
      return <div>Loading...</div>
    }
  }

  practiceSelectTags = () => {
    if (this.state.job.practice_id) {
      return (
        <>
          <PracticeSelect
            practice_id={this.state.job.practice_id}
            handlePracticeChange={this.handlePracticeChange}
            practices={this.state.practices}
          />
          <a href="/practices" className="btn btn-primary btn-rounded mb-3">
            Add Another Location
          </a>
        </>
      )
    } else {
      return <div>Loading...</div>
    }
  }

  confidentialCheckboxTags = () => {
    return (
      <ConfidentialCheckBox
        confidential={this.state.job.confidential}
        handleToggle={this.handleToggle}
      />
    )
  }

  hiringTypeTags = () => {
    return (
      <HiringType
        essential={this.props.essential}
        temp_only={this.props.temp_only}
        position_type={this.state.job.position_type}
        handleChange={this.handleChange}
      />
    )
  }

  compensationTypeTags = () => {
    return (
      <CompensationType
        compensation_type={this.state.job.compensation_type}
        handleChange={this.handleChange}
      />
    )
  }

  positionHoursTags = () => {
    let days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ]

    let dayHours = days.map((day, index) => (
      <PositionHourRowTags
        key={index}
        day={day}
        job={this.state.job}
        handleToggle={this.handleToggle}
        handleDate={this.handleDate}
      />
    ))

    return (
      <div className={`perm-schedule-form`} style={{ display: "none" }}>
        <h3>Position Hours</h3>
        <table className="manage-table large-only availability-table">
          <thead>
            <tr key="-1">
              <th>Day</th>
              <th>Start Time</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>{dayHours}</tbody>
        </table>
      </div>
    )
  }

  compensationRateTags = () => {
    const { min_compensation, compensation } = this.state.errors
    const { compensation_range } = this.state
    const validation_error = compensation_range
      ? min_compensation == true || compensation == true
      : min_compensation == true

    return (
      <div className="fullwidth">
        {/* <h3>Compensation Options</h3> */}
        {/* <span>
                    <input name="job[doe]" type="hidden" value={this.state.job.doe} />
                    <input id="job_doe" type="checkbox" checked={this.state.job.doe} onChange={(e) => this.handleToggle(e, 'doe')}></input>
                    <span>Depends on Experience (DOE)</span>
                    <br />
                </span> */}
        <React.Fragment>
          <h3>Compensation Rate</h3>
          <span
            className="dollarsign"
            style={{ marginRight: -20, position: "relative", marginLeft: 10 }}
          >
            $
          </span>
          <input
            id="job_min_compensation"
            style={{ padding: "3px 15px 3px 20px" }}
            className={`compensation ${validation_error && "validation_error"}`}
            type="text"
            value={this.state.job.min_compensation}
            onChange={(e) => this.handleChange(e, "min_compensation")}
            name="job[min_compensation]"
          ></input>
          {compensation_range && <span>&nbsp;to&nbsp;</span>}
          {compensation_range && (
            <React.Fragment>
              <span
                className="dollarsign"
                style={{
                  marginRight: -20,
                  position: "relative",
                  marginLeft: 20,
                }}
              >
                $
              </span>
              <input
                id="job_compensation"
                style={{ padding: "3px 15px 3px 20px" }}
                className={`compensation ${validation_error && "validation_error"
                  }`}
                type="text"
                value={this.state.job.compensation}
                onChange={(e) => this.handleChange(e, "compensation")}
                name="job[compensation]"
              ></input>
            </React.Fragment>
          )}
          <span>
            <input
              type="checkbox"
              name="compensation_range"
              checked={!compensation_range}
              value={!compensation_range}
              onChange={() =>
                this.setState((state, props) => ({
                  compensation_range: !state.compensation_range,
                }))
              }
            />
            Switch to Exact Pay
          </span>
          <br />
          {!this.state.job.doe && validation_error && (
            <span className="validation_message">
              Please enter a valid compensation.
            </span>
          )}
        </React.Fragment>
      </div>
    )
  }

  yearsExperienceTags = () => {
    let yExperience
    if (this.state.job.position_type != "temp") {
      yExperience = (
        <div className="fullwidth">
          <h3>Years Experience</h3>
          <select
            name="job[years_experience]"
            class="ddselect"
            value={this.state.job.years_experience}
            onChange={(e) => this.handleChange(e, "years_experience")}
          >
            <option value="0-2">0-2 Year</option>
            <option value="2-5">2-5 Years</option>
            <option value="5+">5+ Years</option>
          </select>
        </div>
      )
    }
    return <div>{yExperience}</div>
  }

  jobTitleTags = () => {
    return (
      <div className="fullwidth">
        <h3>Job Headline (optional)</h3>
        <input
          type="text"
          name="job[job_title]"
          onChange={(e) => this.handleChange(e, "job_title")}
          value={this.state.job.job_title}
          placeholder="(Ex. Lead Back Office Assistant, Experienced Treatment Coordinator, Insurance Biller)"
        />
      </div>
    )
  }

  jobDescriptionTags = () => {
    var editor = RichTextEditor.createValueFromString(
      this.state.description_template,
      "markdown"
    )

    const validation_error = this.state.errors.description === true

    return (
      <div className="fullwidth">
        {this.state.description_template.length > 0 && (
          <DescriptionTemplate
            editor={editor}
            setDescriptionTemplate={(e) => this.setDescriptionTemplate(e)}
            closeDescriptionTemplate={() =>
              this.setState({ description_template: "" })
            }
          />
        )}
        <h3>Job Description</h3>
        {!(this.state.description_template.length > 0) && (
          <a
            className="button"
            style={{ marginBottom: 10 }}
            onClick={() => this.resetJobTemplate()}
          >
            Start with a Template
          </a>
        )}
        <DescriptionEditor
          editorState={this.state.editorState}
          onChangeDescription={(e) => this.onChangeDescription(e)}
        />
        {validation_error && (
          <span className="validation_message">
            Please enter a job description of at least 25 characters.
          </span>
        )}
      </div>
    )
  }

  tempFieldsTags = () => {
    const { temp_date_error } = this.state.errors
    let dateField = (
      <div className="form-group">
        {/* <h3>Temp Schedule</h3> */}
        <h4>Temp Date</h4>
        <Datetime
          value={moment.utc(this.state.job.temp_date)}
          inputProps={{ name: `job[temp_date]`, readOnly: true }}
          onChange={(date) => this.handleDate(date, "temp_date")}
          dateFormat={true}
          timeFormat={false}
          displayMode="days"
          dateFormat="YYYY-MM-DD"
        />
        {temp_date_error && (
          <span className="validation_message">Please enter a date.</span>
        )}
      </div>
    )

    let startField = (
      <div className="form-group">
        <h4>Start Time</h4>
        <Datetime
          value={moment.utc(this.state.job.temp_start)}
          inputProps={{ name: `job[temp_start]`, readOnly: true }}
          onChange={(date) => this.handleDate(date, "temp_start")}
          dateFormat={false}
          timeFormat={true}
          displayMode="days"
        />
      </div>
    )

    let endField = (
      <div className="form-group">
        <h4>End Time</h4>
        <Datetime
          value={moment.utc(this.state.job.temp_end)}
          inputProps={{ name: `job[temp_end]`, readOnly: true }}
          onChange={(date) => this.handleDate(date, "temp_end")}
          dateFormat={false}
          timeFormat={true}
          displayMode="days"
        />
      </div>
    )

    if (this.state.job.position_type == "temp" && !this.props.hide) {
      return (
        <div>
          {dateField}
          <div className="row">
            <div className="col-6">
              {startField}
            </div>
            <div className="col-6">
              {endField}
            </div>
          </div>
        </div>
      )
    } else if (this.props.hide) {
      return (
        <div>
          {dateField}
          {startField}
          {endField}
        </div>
      )
    } else {
      return (
        <div>
          <input
            type="hidden"
            name="job[temp_date]"
            value={this.state.job.temp_date}
          />
          <input
            type="hidden"
            name="job[temp_start]"
            value={this.state.job.temp_start}
          />
          <input
            type="hidden"
            name="job[temp_end]"
            value={this.state.job.temp_end}
          />
        </div>
      )
    }
  }

  benefitsTags = () => {
    let benefitsList = ["medical", "dental", "vision", "401k", "pto", "bonuses"]
    let benefitsCheckBoxes = []
    for (let i = 0; i < benefitsList.length; i++) {
      let benefit = benefitsList[i]
      benefitsCheckBoxes.push(
        <li className="tags-job" key={i}>
          <label className="checkbox-container job">
            <input
              name={`job[benefit_${benefit}]`}
              type="hidden"
              value={this.state.job[`benefit_${benefit}`]}
            />
            <input
              type="checkbox"
              id={`job_benefit_${benefit}`}
              checked={this.state.job[`benefit_${benefit}`]}
            ></input>
            <span
              className="checkmark benefit"
              onClick={(e) => this.handleToggle(e, `benefit_${benefit}`)}
            >
              {benefit}
            </span>
          </label>
        </li>
      )
    }

    // Show Benefits If Not temp Position
    if (this.state.job.position_type != "temp") {
      return (
        <div>
          <h3>Benefits</h3>
          <div className="">
            <ul>{benefitsCheckBoxes}</ul>
          </div>
        </div>
      )
    }
  }

  skillTags = () => {
    let skillFields = []

    // Loop all categories
    for (let i = 0; i < this.state.skill_categories.length; i++) {
      // Current category
      let category = this.state.skill_categories[i]

      // Get the list of all skills
      let allSkills = this.state.skills

      // Array to hold current category skills
      let skills = []

      // Skip if category is language proficiency and bilingual not selected
      if (
        category == "Language Proficiency (other than English)" &&
        !this.state.job.skills[55]
      )
        continue

      // Loop all skills
      for (let ii = 0; ii < allSkills.length; ii++) {
        // Get current skill
        let skill = allSkills[ii]

        // Add to skills if matching category
        if (skill.category == category) {
          skills.push(
            <li className="skills-list" key={ii}>
              <input
                type="checkbox"
                id={`job_skill_ids_${skill.id}`}
                checked={this.state.job.skills[skill.id]}
                value={skill.id}
                name="job[skill_ids][]"
                className="btn-check"
              />
              <label className="btn text-light rounded px-2 py-1 me-1 mb-1 ms-2" style={{ backgroundColor: skill.color }}>
                <span className={`${this.escapeFormatSkillCategory(skill.category)}`} onClick={(e) => this.handleToggleSkill(e, skill.id)}>
                  {skill.name}
                </span>
              </label>
            </li>
          )
        }
      }

      // Final Markup for current category
      skillFields.push(
        <div className="skills-section" key={i}>
          <div className="">
            <h3>{category}</h3>
            <input name="job[skill_ids][]" type="hidden" value="" />
            <ul className=" checkboxes list-unstyled ">{skills}</ul>
          </div>
          <div className="divider"></div>
        </div>
      )
    }
    return skillFields
  }
  escapeFormatSkillCategory = (str) => {
    return str.replace(/\s/g, "").replace("(", "").replace(")", "")
  }

  preferredSMSField = () => {
    const { sms_phone } = this.state.errors
    const validation_error = sms_phone === true

    if (this.state.job.position_type != "temp") {
      return <div></div>
    } else {
      return (
        <div className="form-group">
          <h3>Mobile Number</h3>
          <small>
            You will receive text message updates for your temp job request.
          </small>
          <InputMask
            name="job[sms_phone]"
            className={`form-control ${validation_error === true && "validation_error"}`}
            type="text"
            mask="(999) 999-9999"
            maskChar=" "
            value={this.state.job.sms_phone}
            onChange={(e) => this.handleChange(e, "sms_phone")}
          />
          {validation_error && (
            <span className="validation_message">
              Please enter a valid SMS phone number.
            </span>
          )}
        </div>
      )
    }
  }

  setTab = (index) => {
    this.setState((state, props) => ({ tabIndex: index }))
    window.scrollTo(0, 170)
  }

  firstSectionTags = () => {
    // Fields
    let positionTypeSelect = this.positionTypeSelectTags()
    let practiceSelect = this.practiceSelectTags()
    let confidentialCheckbox = this.confidentialCheckboxTags()
    let hiringType = this.hiringTypeTags()
    let yearsExperience = this.yearsExperienceTags()

    let preferredSMS = this.preferredSMSField()

    let positionHours = this.positionHoursTags()
    let tempFields = this.tempFieldsTags()
    let hours
    if (this.state.job.position_type != "temp") {
      hours = (
        <div className="">
          {positionHours}
          {tempFields}
        </div>
      )
    } else {
      hours = <div className="">{tempFields}</div>
    }

    return (
      <div className="">
        {/* First Section */}
        {positionTypeSelect}
        {practiceSelect}
        {preferredSMS}
        {this.state.job.position_type != "temp" && confidentialCheckbox}
        {hiringType}
        {yearsExperience}
        {hours}
      </div>
    )
  }

  thirdSectionTags = () => {
    let jobTitle = this.jobTitleTags()
    let jobDescription = this.jobDescriptionTags()
    return (
      <div>
        {jobTitle}
        {jobDescription}
      </div>
    )
  }

  secondSectionTags = () => {
    let compensationType = this.compensationTypeTags()
    let compensationRate = this.compensationRateTags()
    return (
      <div className="">
        {compensationType}
        {compensationRate}
      </div>
    )
  }

  benefitsSectionTags = () => {
    let benefits = this.benefitsTags()
    return <div className="benefits">{benefits}</div>
  }

  skillsSectionTags = () => {
    let skillFields = this.skillTags()
    return (
      <div className="benefits">
        <h3>Skills</h3>
        {skillFields}
      </div>
    )
  }

  render() {
    // Sections
    let firstSection = this.firstSectionTags()
    let secondSection = this.secondSectionTags()
    let thirdSection = this.thirdSectionTags()
    let benefitsSection = this.benefitsSectionTags()
    let skillsSection = this.skillsSectionTags()
    const { temp_only } = this.props
    let layout
    
    let processingModal = (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        <center>
          
          <span>Processing</span>
        </center>
      </CSSTransitionGroup>
    )

    let form
    if (this.props.compact == true) {
      let button_options = { max: 3, setTab: this.setTab }
      form = (
        <Tabs
          selectedIndex={this.state.tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList>
            <Tab>General Info</Tab>
            <Tab>Skills</Tab>
            <Tab>Compensation</Tab>
            <Tab>Description</Tab>
          </TabList>
          <TabPanel>
            {firstSection}
            <JobFormTabButtons
              index={0}
              {...button_options}
              onCancel={() => this.props.cancelButton()}
            />
          </TabPanel>
          <TabPanel>
            {skillsSection}
            <JobFormTabButtons index={1} {...button_options} />
          </TabPanel>
          <TabPanel>
            {secondSection}
            {benefitsSection}
            <JobFormTabButtons index={2} {...button_options} />
          </TabPanel>
          <TabPanel>
            {thirdSection}
            <JobFormTabButtons
              index={3}
              {...button_options}
              errors={Object.keys(this.state.errors).some(
                (k) => this.state.errors[k] == true
              )}
            />
          </TabPanel>
        </Tabs>
      )
    } else {
      form = (
        <div>
          {firstSection}
          {secondSection}
          {thirdSection}
          {benefitsSection}
          {skillsSection}
          {submitButton}
        </div>
      )
    }

    if (this.state.processing == true) {
      layout = (
        <CSSTransitionGroup
          transitionName="example"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {processingModal}
        </CSSTransitionGroup>
      )
    } else if (this.state.processingComplete == true) {
      layout = (
        <center>
          {!temp_only && !this.props.profile_id && (
            <React.Fragment>
              <h3 style={{ fontSize: 20 }}>Complete!</h3>
              <br />
            </React.Fragment>
          )}
          {!temp_only && this.props.profile_id && (
            <React.Fragment>
              <h3 style={{ fontSize: 20 }}>
                Invite Sent! Want to invite similar candidates to apply?
              </h3>
              <br />
            </React.Fragment>
          )}
          {temp_only && (
            <React.Fragment>
              <h2>Complete!</h2>
              <br />
              <a
                className="button"
                onClick={() => {
                  this.setState((state, props) => ({
                    processingComplete: false,
                  }))
                  this.props.cancelButton()
                }}
              >
                {temp_only
                  ? "Request Temp for Another Job"
                  : "Invite to Another Job"}
              </a>
            </React.Fragment>
          )}
          &nbsp;
          <br />
          {temp_only && (
            <a className="button" href={this.state.redirectUrl}>
              Find More Compatible Temps
            </a>
          )}
          {!temp_only && (
            <a className="button" href={`/jobs/${this.state.job_id}`}>
              View Matched Candidates
            </a>
          )}
        </center>
      )
    } else {
      layout = (
        <CSSTransitionGroup
          transitionName="example"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {form}
        </CSSTransitionGroup>
      )
    }

    if (this.props.form_tags == true) {
      layout = (
        <JobFormTag
          onSubmit={this.handleSubmit}
          authenticity_token={this.props.authenticity_token}
          layout={layout}
        />
      )
    }

    return <div className={containerClasses}>{layout}</div>
  }
}

// const containerClasses = `ten columns offset-by-three`;
const containerClasses = ``

export default JobForm
