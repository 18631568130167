import * as  React from "react";
import ReactTooltip from "react-tooltip";
import "react-app-polyfill/ie9";

class HelpIconWithTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: {
        ["newbie"]: (
          <div>
            <h5>What is a Dental Newbie?</h5>
            <hr />
            <p>
              Candidates that applied to your job but may not have previous dental experience.
            </p>
          </div>
        ),
        ["network_applicant"]: (
          <div>
            <h5>Candidate Applied via Partner Job Site</h5>
            <hr />
            <p>
              For partner applicants you can view and download the resume.
            </p>
          </div>
        ),
        ["post_a_job"]: (
          <div>
            <h5>Post Unlimited Jobs</h5>
            <hr />
            <p>
              Create new job postings quickly. Each job posting will be live for
              30-days. Notifications will be sent to dental professionals
              matching your job requirements. You will receive email alerts when
              applicants apply.
            </p>
          </div>
        ),
        ["view_applicants"]: (
          <div>
            <h5>Manage Jobs and View Applicants</h5>
            <hr />
            <p>
              View all your perm and temp jobs in the same place. You can edit
              and close jobs. View all your job applicants and requested temp
              details
            </p>
          </div>
        ),
        ["manage_practices"]: (
          <div>
            <h5>Manage Dental Practices</h5>
            <hr />
            <p>
              Update your dental practice details here. Add your practice logo
              or photo to highlight your office. Include a link to your website
              to promote your office in the About Your Practice section.
            </p>
          </div>
        ),
        ["browse_candidates"]: (
          <div>
            <h5>Find a Candidate</h5>
            <hr />
            <p>
              Browse candidate profiles and search for the professionals that
              meet your requirements. You can filter your search by specialty,
              skills, software experience, location and more.
            </p>
          </div>
        ),
        ["schedule_temps"]: (
          <div>
            <h5>View Your Future Temp Requests</h5>
            <hr />
            <p>
              Need a temp? Submit a temp job request and view compatible temps
              instantly. You can select the temps you want to request and
              DirectDental.com will send a direct message with your temp job
              details. You will then be notified in real-time when a temp
              accepts or declines your job. Schedule as many temp days as you
              need using your staffing calendar.
            </p>
          </div>
        ),
        ["faq"]: (
          <div>
            <h5>Frequently Asked Questions</h5>
            <hr />
            <p>
              View answers to the most commonly asked questions. Access our
              support team and contact us.
            </p>
          </div>
        ),
        ["edit_profile"]: (
          <div>
            <h5>My Profile</h5>
            <hr />
            <p>
              Edit and complete your profile. When you apply to jobs your
              profile gets submitted so make sure it looks good!
            </p>
          </div>
        ),
        ["temp_availabilities"]: (
          <div>
            <h5>My Calendar</h5>
            <hr />
            <p>
              Add days you’re available to work as a temp. Keep track of offices
              you worked at using your calendar!
            </p>
          </div>
        ),
        ["my_temp_jobs"]: (
          <div>
            <h5>My Temp Jobs & Requests</h5>
            <hr />
            <p>
              All the details for the temp jobs you’ve ACCEPTED to work are
              right here. You can also review any pending temp requests you’ve
              received and Accept or Decline them here.
            </p>
          </div>
        ),
        ["my_applications"]: (
          <div>
            <h5>My Applications</h5>
            <hr />
            <p>
              View all your job applications. Remember when you apply to a job
              your Profile is submitted for dental offices to review. So it’s
              important that you make sure your Profile is complete for success!
            </p>
          </div>
        ),
        ["searching_jobs"]: (
          <div>
            <h5>Searching Jobs</h5>
            <hr />
            <p>
              You can browse jobs by location, position type, specialty
              experience and more! Filter jobs and view Full-time, Part-time and
              Temporary jobs too! Apply to jobs with 1-tap or click!
            </p>
          </div>
        ),
        ["my_favorites"]: (
          <div>
            <h5>My Favorites</h5>
            <hr />
            <p>
              Follow your favorite practices and view them here. You can see
              open jobs and details about each practice.
            </p>
          </div>
        )
      }
    };
  }
  render() {
    return (
      <React.Fragment>
        <h4 className="top-right" style={{ ...this.props.style }}>
          <i
            className="far fa-question-circle"
            data-tip
            data-for={`help-${this.props.message}`}
          />
        </h4>
        <ReactTooltip
          id={`help-${this.props.message}`}
          type="info"
          effect="solid"
          place="bottom"
          className="dashTooltip"
        >
          {this.state.messages[`${this.props.message}`]}
        </ReactTooltip>
      </React.Fragment>
    );
  }
}

export default HelpIconWithTooltip;
